import React, { Component } from "react";
import InputForm from "../inputForm/input-form";
import TextAreaForm from "../textAreaForm/text-area-form";
import ButtonAction from "../buttonAction/button_action";
import ReactLoading from "react-loading";

import "./form-contact.css";

export default class FormContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form_contact: { name: "", email: "", phone: "", message: "" },
      loading: false,
      actived: "",
    };

    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }

  onHandleSubmit(event, idEngage) {
    this.sendMsgm(idEngage);
    event.preventDefault();
  }

  sendMsgm(id) {
    const data = this.state.form_contact;
    data["coach_id"] = id;
    this.setState({ loading: true });
    this.service(
      "POST",
      `${process.env.REACT_APP_URL_BASE}contacts`,
      data
    ).then((res) => {
      if (res.status == 200) {
        alert("Contato enviado com sucesso");
        this.setState({
          form_contact: {
            name: "",
            email: "",
            phone: "",
            message: "",
          },
        });
      }
      this.setState({
        loading: false,
        actived: "",
      });
    });
  }

  service(method, url, data) {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body:
        method === "POST"
          ? data != null
            ? this.parsePayload(data)
            : []
          : null,
    });
  }

  parsePayload(_payload) {
    return Object.keys(_payload)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          _payload[key]
        )}`;
      })
      .join("&");
  }

  formatFieldPhone(value) {
    let valueFormated = value.replace(/\D/g, "");
    valueFormated = valueFormated.replace(/^0/, "");
    switch (valueFormated.length) {
      case 11:
        return valueFormated.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      case 10:
        return valueFormated.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
      default:
        return valueFormated.replace(/^(\d*)/, "$1");
    }
  }

  removeFormatPhone(value) {
    return value.replace(/\D/g, "");
  }

  updateFieldForm(value, field) {
    this.setState((prev) => ({
      ...prev,
      form_contact: {
        ...prev.form_contact,
        [`${field}`]: value,
      },
    }));
  }

  render() {
    const { idEngage } = this.props;
    return (
      <form
        onSubmit={(event) => this.onHandleSubmit(event, idEngage)}
        className="form-contact-container"
      >
        <div className="div-contact-firstLine">
          <InputForm
            id="name"
            required
            value={this.state.form_contact.name}
            placeholder="Digite o nome"
            labelDescription="Nome"
            type="text"
            onChange={(event) => {
              this.updateFieldForm(event.target.value, "name");
            }}
          />
          <InputForm
            id="email"
            required
            value={this.state.form_contact.email}
            placeholder="Digite o e-mail"
            labelDescription="E-mail"
            type="email"
            onChange={(event) => {
              this.updateFieldForm(event.target.value, "email");
            }}
          />
          <InputForm
            id="phone"
            value={this.state.form_contact.phone}
            placeholder="Digite o telefone"
            labelDescription="Telefone"
            type="text"
            onChange={(event) => {
              this.updateFieldForm(event.target.value, "phone");
            }}
            onBlur={() =>
              this.updateFieldForm(
                this.formatFieldPhone(this.state.form_contact.phone),
                "phone"
              )
            }
            onFocus={() =>
              this.updateFieldForm(
                this.removeFormatPhone(this.state.form_contact.phone),
                "phone"
              )
            }
          />
        </div>
        <div className="div-contact-secondLine">
          <TextAreaForm
            id="message"
            value={this.state.form_contact.message}
            placeholder="Deixe sua mensagem"
            labelDescription="Mensagem"
            onChange={(event) => {
              this.updateFieldForm(event.target.value, "message");
            }}
          />

          {this.state.loading ? (
            <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
              <ReactLoading type="bubbles" color="#B1CD49" />{" "}
            </div>
          ) : (
            <ButtonAction type="submit"> Enviar </ButtonAction>
          )}
        </div>
      </form>
    );
  }
}
