import React, { Component } from "react";
import FormContact from "../../../formContact/form-contact";
import "./footer-engage.css";

export default class FooterEngage extends Component {
  render() {
    const {
      idEngage = null,
      isActive,
      isContact = false,
      description,
      hasFormation = false,
      formation = [],
    } = this.props;

    return (
      <React.Fragment>
        {isActive && (
          <div className="footer-engage-container">
            {!isContact && (
              <div
                className={
                  hasFormation
                    ? "footer-engage-description"
                    : "footer-engage-description footer-engage-description-full"
                }
              >
                {description}
              </div>
            )}

            {!isContact && hasFormation && formation.length > 0 && (
              <div className="footer-engage-formation">
                <strong> Formações: </strong>
                {formation.map((item, idx) => (
                  <p key={idx}>{item.text}</p>
                ))}
              </div>
            )}

            {isContact && <FormContact idEngage={idEngage} />}
          </div>
        )}
      </React.Fragment>
    );
  }
}
