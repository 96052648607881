import React, { Component } from "react";

import "./button_action.css";

export default class ButtonAction extends Component {
  render() {
    return (
      <button
        {...this.props}
        className={
          this.props.isActive
            ? "button-action-engage button-action-engage-active"
            : "button-action-engage"
        }
      >
        {this.props.children}
      </button>
    );
  }
}
