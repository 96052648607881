import React, { Component } from "react";
import "./input-form.css";

export default class InputForm extends Component {
  render() {
    const { labelDescription } = this.props;
    return (
      <div className="input-form-container">
        <label>{labelDescription}</label>
        <input {...this.props} className="input-form-engage" />
      </div>
    );
  }
}
