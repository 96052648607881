import React from 'react';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';

export default class Step8 extends React.Component {

  valid = false;

  constructor(props) {
    super(props);
    this.state = {
      application_resolution: props.getStore().application_resolution
    };
  }

  saveInput(value) {
    this.valid = true;
    this.setState({ application_resolution: value });
    this.props.updateStore({
      application_resolution: value
    });  // Up
  }


  render() {
    return (
      <div id="step8" className="step-container">
        <h2 className="text-center">Você sabe se esta pessoa aplicou uma única solução ou uma série de ações para resolver o tema?</h2>
        <form className="text-center">
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="Uma única ação" defaultChecked={this.state.application_resolution === 'Uma única ação'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Uma única ação</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="Uma série de ações" defaultChecked={this.state.application_resolution === 'Uma série de ações'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Uma série de ações</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(6)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.state.application_resolution} onClick={() => this.props.jumpToStep(8)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}