import React, { Component } from "react";
import PropTypes from "prop-types";
import { Doughnut, Radar } from "react-chartjs-2";
import { Link } from "react-router-dom";

import { Container, Button, Row, Col } from "reactstrap";
import StepZilla from "react-stepzilla";

// Layouts
import Menu from "../../templates/menu/menu";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";
import Step7 from "./steps/Step7";
import Step8 from "./steps/Step8";
import Step9 from "./steps/Step9";
import Step10 from "./steps/Step10";
import Step11 from "./steps/Step11";

// Image
import GraphicImage from "../../images/graphic.jpg";
import Heart from "../../images/icons-images/svg/heart.svg";
import Handbag from "../../images/icons-images/svg/handbag.svg";
import Result from "../../images/ilustracaoclimby.png";

const result_ilustrations = {
  "": require("../../images/ilustracaoclimby.png"),
  "1": require("../../images/ilustracaoclimby-simples.png"),
  "2": require("../../images/ilustracaoclimby-complicado.png"),
  "3": require("../../images/ilustracaoclimby-complexo.png"),
  "4": require("../../images/ilustracaoclimby-caotico.png"),
};

const result_texts = {
  "": "",
  "1":
    "De acordo com o modelo CYNEFIN, o seu problema é categorizado como Simples e neste caso algumas propostas podem ser avaliadas como por exemplo: Categorize o problema (em que momento ocorreu, quando, onde, grupo responsável, etc), Reveja o processo que originou o problema (não menospreze o processo perguntando sempre), Você conhece algum lugar em que esta situação nunca acontece? Onde? Como é o processo?, Busque as melhores práticas existentes. Caso você seja um líder de equipe, engaje sua equipe na busca da solução com propostas.",
  "2":
    "De acordo com o modelo CYNEFIN, o seu problema é categorizado como Complicado e neste caso algumas propostas podem ser avaliadas como por exemplo: Reúna sua equipe ou especialistas, Avalie e analise a situação toda novamente, Ouça atentamente todos os conselhos e idéias, Desafie a equipe na busca da solução e eleja a melhor solução para solução do problema. Lembre-se que existem muitas alternativas online de especialistas e não somente com a equipe de trabalho.Caso você seja um líder de equipe, lembre-se de ouvir os especialistas ou seja, quem realmente já viveu esta situação.",
  "3":
    "De acordo com o modelo CYNEFIN, o seu problema é categorizado como Complexo onde está muito baseado em imprevisibilidade, não há resposta corretas e neste caso algumas propostas podem ser avaliadas como por exemplo: Reúna a equipe com diversas competências e experiências,  ouça as várias ideias que podem surgir para resolução do problema com o objetivo de buscar um padrão. Crie um ambiente para experimentos mantendo paciência e tempo para reflexão nos resultados. Caso você seja um líder de equipe, incentive a comunicação da equipe para sempre promover inovação e ideias a serem testadas até a solução do problema.",
  "4":
    "De acordo com o modelo CYNEFIN, o seu problema é categorizado como Caótico onde não existe uma relação clara entre causa e efeito e neste caso algumas propostas podem ser avaliadas como por exemplo: Ação é a palavra neste ambiente e por isso uma alternativa pode ser criar equipes paralelas para medir e analisar o resultado das ações e com isso buscar oportunidade para que o problema passe de Caótico para Complexo onde a busca para um padrão para ser o objetivo. Caso você seja um líder de equipe, devido a possibilidade de tempo reduzido, é momento de agir e buscar o que funciona para a solução que algumas vezes pode não ser a melhor solução mas um início.",
};

const result_titles = {
  "": "",
  "1": "Simples",
  "2": "Complicado",
  "3": "Complexo",
  "4": "Caótico",
};

class ResolutionProblem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finish: false,
      graphic: false,
      text: "",
    };

    this.formWheelOfLife = {
      name: "",
      email: "",
      process_coaching: false,
      newsletter: false,
      situation: "",
      problem: "",
      origin_problem: "",
      processess_problem: false,
      historic_problem: "",
      cicle_problem: "",
      application_resolution: false,
      urgency_problem: "",
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;
    window.scrollTo(0, 0);
    // fetchData();
  }

  getStore() {
    return this.formWheelOfLife;
  }

  updateStore(update) {
    this.formWheelOfLife = {
      ...this.formWheelOfLife,
      ...update,
    };
  }

  async finish(e) {
    if (e === 11) {
      await this.setState({ finish: true });
      if (
        this.getStore().origin_problem === "sim" &&
        this.getStore().processess_problem === "sim"
      ) {
        await this.setState({ text: 1 });
      } else if (
        this.getStore().processess_problem === "não" &&
        this.getStore().historic_problem === "sim" &&
        this.getStore().cicle_problem === "sim"
      ) {
        await this.setState({ text: 2 });
      } else if (
        this.getStore().origin_problem === "não" &&
        this.getStore().historic_problem === "não" &&
        this.getStore().cicle_problem === "sim"
      ) {
        await this.setState({ text: 3 });
      } else if (
        this.getStore().origin_problem === "não" &&
        this.getStore().historic_problem === "não" &&
        this.getStore().cicle_problem === "não"
      ) {
        await this.setState({ text: 4 });
      } else if (
        this.getStore().origin_problem === "sim" &&
        this.getStore().processess_problem === "não" &&
        this.getStore().historic_problem === "não"
      ) {
        await this.setState({ text: 2 });
      }

      this.saveResponses();
    }
  }

  async saveResponses() {
    let _payload = {
      name: this.getStore().name,
      email: this.getStore().email,
      problem: this.getStore().situation,
      real_problem: this.getStore().problem,
      step4: this.getStore().origin_problem,
      step5: this.getStore().processess_problem,
      step6: this.getStore().historic_problem,
      step7: this.getStore().cicle_problem,
      step8: this.getStore().application_resolution,
      step9: this.getStore().urgency_problem,
      step11: this.getStore().process_coaching,
      want_newsletter: this.getStore().newsletter,
      result: result_titles[this.state.text],
      text: this.text2Save(),
    };

    this.service(
      "POST",
      `${process.env.REACT_APP_URL_BASE}problem-resolution`,
      _payload
    );
  }

  text2Save() {
    let _text = `<p>Este Self Assessment tem como base a teoria do Modelo CYNEFIN e tem como objetivo contribuir na avaliação e busca de uma solução para o problema apresentado pelo ${
      this.getStore().name
    }.  Em nenhum momento será fornecida a resposta de como resolver o cliente pois o que se busca é que o ${
      this.getStore().name
    } encontre as possíveis soluções em qualquer situação futura. Este é o grande objetivo do processo de Coaching</p>`;
    _text += `<p>Em nenhum momento este teste sofreu qualquer avaliação profissional</p>`;
    _text += `<p>Descricao do problema: <strong>${this.getStore().situation}. ${
      this.getStore().problem
    }</strong></p>`;
    _text += `<div><p>${result_texts[this.state.text]}</p></div>`;
    _text += `<p>O Coach irá te apoiar na sua jornada e definição dos próximos passos utilizando técnicas cientificamente comprovadas. Na <strong className="text-underline">climby</strong> você encontra os melhores Coaches e que estarão a sua disposição para contribuir seja qual for o momento da sua vida e seu objetivo a alcançar. Aproveite para visitar o nosso <span className="text-underline">Blog</span> e busque o seu desenvolvimento contínuo. Desejamos muito sucesso na sua jornada!</p>`;
    return _text;
  }

  translate(number) {
    switch (number) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      case 5:
        return "five";
      case 6:
        return "six";
      case 7:
        return "serven";
      case 8:
        return "eight";
      case 9:
        return "nine";
      case 10:
        return "ten";
      default:
        return "";
    }
  }

  service(method, url, data) {
    return fetch(url, {
      method: method,
      headers: {
        // 'Accept': 'application/json',
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body:
        method === "POST"
          ? data != null
            ? this.parsePayload(data)
            : []
          : null,
    });
  }

  parsePayload(_payload) {
    return Object.keys(_payload)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          _payload[key]
        )}`;
      })
      .join("&");
  }

  render() {
    const steps = [
      {
        name: "",
        component: (
          <Step1
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step2
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step3
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step4
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step5
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step6
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step7
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step8
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step9
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step10
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step11
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
    ];

    return (
      <>
        <div
          id="home"
          className="home-container boxShadowSearche"
          data-test-id="home-container"
        >
          <header id="header" className="headerSearche">
            <Menu objetivos={true} />
          </header>
        </div>
        <div id="Assessment" className="assessment-container">
          <div id="body-main">
            <h2 className="title">Técnica para Resolução de Problemas</h2>
            <Container>
              {!this.state.finish ? (
                <div className="step-progress">
                  <StepZilla
                    steps={steps}
                    showNavigation={false}
                    preventEnterSubmission={false}
                    onStepChange={(step) => this.finish(step)}
                  />
                </div>
              ) : !this.state.graphic ? (
                <div id="preResponse" className="text-center">
                  <p>
                    Obrigado por participar! O resultado da Técnica para
                    Resolução de Problemas foi enviado para seu e-mail.
                    Aproveite a oportunidade e encontre um Coach que o apoiará
                    com ferramentas e metodologias durante a sua jornada de
                    desenvolvimento.
                  </p>
                  <img src={GraphicImage} />
                  <p>
                    Muito bem! <br /> Você tem grandes chances de atingir o seu
                    objetivo.
                  </p>
                  <Button
                    color="default"
                    onClick={() => this.setState({ graphic: true })}
                  >
                    VER O RESULTADO DE MINHA AVALIAÇÃO
                  </Button>
                </div>
              ) : (
                <div id="response">
                  <Row>
                    <Col lg={{ size: 5 }} md={{ size: 12 }} xs={{ size: 12 }}>
                      <h3>Técnica para Resolução de Problemas</h3>
                      <img
                        className="mg-50"
                        src={result_ilustrations[this.state.text]}
                      />
                      <h3>Pontos de Impacto</h3>
                      <div className="buttons-groups">
                        <span>
                          <img src={Handbag} /> Profissional
                        </span>
                        <a
                          href={`${
                            process.env.REACT_APP_URL_ENGAGE
                          }?area=profissional`}
                          className="btn btn-default text-uppercase"
                        >
                          encontre um coach
                        </a>
                      </div>
                      <div className="buttons-groups">
                        <span>
                          <img src={Heart} /> Vida
                        </span>
                        <a
                          href={`${process.env.REACT_APP_URL_ENGAGE}?area=vida`}
                          className="btn btn-default text-uppercase"
                        >
                          encontre um coach
                        </a>
                      </div>
                    </Col>
                    <Col lg={{ size: 7 }} md={{ size: 6 }} xs={{ size: 12 }}>
                      <h2 className="text-uppercase">Resultado</h2>
                      <p>
                        Este Self Assessment tem como base a teoria do Modelo
                        CYNEFIN e tem como objetivo contribuir na avaliação e
                        busca de uma solução para o problema apresentado pelo{" "}
                        {this.getStore().name}. Em nenhum momento será fornecida
                        a resposta de como resolver o cliente pois o que se
                        busca é que o {this.getStore().name} encontre as
                        possíveis soluções em qualquer situação futura. Este é o
                        grande objetivo do processo de Coaching
                      </p>
                      <p>
                        Em nenhum momento este teste sofreu qualquer avaliação
                        profissional
                      </p>
                      <p>
                        Descrição do problema:{" "}
                        <strong>
                          {this.getStore().situation}. {this.getStore().problem}
                        </strong>
                      </p>
                      <div>
                        <p>{result_texts[this.state.text]}</p>
                      </div>
                      <p>
                        O Coach irá te apoiar na sua jornada e definição dos
                        próximos passos utilizando técnicas cientificamente
                        comprovadas. Na{" "}
                        <strong className="text-underline">climby</strong> você
                        encontra os melhores Coaches e que estarão a sua
                        disposição para contribuir seja qual for o momento da
                        sua vida e seu objetivo a alcançar. Aproveite para
                        visitar o nosso{" "}
                        <span className="text-underline">Blog</span> e busque o
                        seu desenvolvimento contínuo. Desejamos muito sucesso na
                        sua jornada!
                      </p>
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </div>
        </div>
      </>
    );
  }
}

ResolutionProblem.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func,
};

export default ResolutionProblem;
