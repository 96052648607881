import { Component } from "react";
import reactDom from "react-dom";

export default class ModalContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return reactDom.createPortal(
      this.props.children,
      document.getElementById("rootPortal")
    );
  }
}
