import React from 'react';

import {
  Button,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';


export default class Newsletter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="section" id="newsletter">
        <Container>
          <h2 className="title text-center text-uppercase">Assine nossa newsletter e acompanhe o universo coaching</h2>
          <Form className="form">
            <Row>
              <Col lg={{ size: 6, offset: 2 }} md={{ size: 7, offset: 1 }} sm="12">
                <FormGroup>
                  <Input type="email" name="email" id="exampleEmail" placeholder="Email" />
                </FormGroup>
              </Col>
              <Col lg="2" md="3" sm="12">
                <Button className="full text-uppercase" color="info">Assinar</Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}