import React, { Component } from "react";
import "./text-area-form.css";

export default class TextAreaForm extends Component {
  render() {
    const { labelDescription } = this.props;
    return (
      <div className="text-area-form-container">
        <label>{labelDescription}</label>
        <textarea {...this.props} className="text-area-form-engage" />
      </div>
    );
  }
}
