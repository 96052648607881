import React from 'react';
import InputGroup from 'reactstrap/lib/InputGroup';
import Input from 'reactstrap/lib/Input';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';

// Images
import Brain from '../../../images/icons-images/svg/brain.svg';
import Cross from '../../../images/icons-images/svg/cross.svg';
import Currency from '../../../images/icons-images/svg/currency-wizard.svg';
import Handbag from '../../../images/icons-images/svg/handbag.svg';
import Heart from '../../../images/icons-images/svg/heart.svg';
import Home from '../../../images/icons-images/svg/home-wizard.svg';
import UserGroup from '../../../images/icons-images/svg/user-group-wizard.svg';
import { Button } from 'reactstrap';


export default class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wheel_of_life: {
        professional: props.getStore().wheel_of_life.professional,
        emocional: props.getStore().wheel_of_life.emocional,
        cheers: props.getStore().wheel_of_life.cheers,
        relationships: props.getStore().wheel_of_life.relationships,
        family: props.getStore().wheel_of_life.family,
        happiness: props.getStore().wheel_of_life.happiness,
        financial: props.getStore().wheel_of_life.financial
      },
    };
  }


  saveInput(value, type) {
    const object = this.state.wheel_of_life;
    let update = { wheel_of_life: {} };
    for (var [key, val] of Object.entries(object)) {
      if (key === type) {
        object[type] = this.validation(value); 
        update.wheel_of_life = object;
      }
    }
    this.setState(update);
    this.props.updateStore(update);
  }

  validation(value) {
    if (value && value > 10) {
      return 10;
    } else if (!value) {
      return 0;
    } else {
      return Number(value);
    }
  }


  render() {
    return (
      <div id="step2" className="step-container">
        <h2 className="text-center">Determine uma nota de 0 a 10 para as seguintes áreas da sua vida.</h2>
        <h4 className="text-center">0 representa uma área de melhoria de acordo com a sua visão e 10 uma área em que você está excepcionalmente bem. <br /> Não existe certo ou errado. A sua resposta representa o seu sentimento atual.</h4>
        <div className="form-container">
          <form>

            <div className="form-group">
              <InputGroup>
                <InputGroupAddon addonType="prepend"><img src={Handbag} /> Realização Profissional</InputGroupAddon>
                <input type="number" value={this.validation(this.state.wheel_of_life.professional)} className="form-control" max="10" onChange={(value) => this.saveInput(value.target.value, 'professional')} placeholder="0" />
              </InputGroup>
            </div>

            <div className="form-group">
              <InputGroup>
                <InputGroupAddon addonType="prepend"><img src={Cross} /> Saúde</InputGroupAddon>
                <input type="number" value={this.validation(this.state.wheel_of_life.cheers)} className="form-control" max="10" onChange={(value) => this.saveInput(value.target.value, 'cheers')} placeholder="0" />
              </InputGroup>
            </div>

            <div className="form-group pr0">
              <InputGroup>
                <InputGroupAddon addonType="prepend"><img src={Heart} /> Felicidade</InputGroupAddon>
                <input type="number" value={this.validation(this.state.wheel_of_life.happiness)} className="form-control" max="10" onChange={(value) => this.saveInput(value.target.value, 'happiness')} placeholder="0" />
              </InputGroup>
            </div>

            <div className="form-group">
              <InputGroup>
                <InputGroupAddon addonType="prepend"><img src={Brain} /> Equilíbrio Emocional</InputGroupAddon>
                <input type="number" value={this.validation(this.state.wheel_of_life.emocional)} className="form-control" max="10" onChange={(value) => this.saveInput(value.target.value, 'emocional')} placeholder="0" />
              </InputGroup>
            </div>

            <div className="form-group">
              <InputGroup>
                <InputGroupAddon addonType="prepend"><img src={UserGroup} /> Relacionamento</InputGroupAddon>
                <input type="number" value={this.validation(this.state.wheel_of_life.relationships)} className="form-control" max="10" onChange={(value) => this.saveInput(value.target.value, 'relationships')} placeholder="0" />
              </InputGroup>
            </div>

            <div className="form-group pr0">
              <InputGroup>
                <InputGroupAddon addonType="prepend"><img src={Currency} className="last" /> Financeiro</InputGroupAddon>
                <input type="number" value={this.validation(this.state.wheel_of_life.financial)} className="form-control" max="10" onChange={(value) => this.saveInput(value.target.value, 'financial')} placeholder="0" />
              </InputGroup>
            </div>

            <div className="form-group pr0">
              <InputGroup>
                <InputGroupAddon addonType="prepend"><img src={Home} /> Família</InputGroupAddon>
                <input type="number" value={this.validation(this.state.wheel_of_life.family)} className="form-control" max="10" onChange={(value) => this.saveInput(value.target.value, 'family')} placeholder="0" />
              </InputGroup>
            </div>
          </form>

          <div className="footer-buttons text-center">
            <Button onClick={() => this.props.jumpToStep(0)} className="text-uppercase btn-next" color="primary">Voltar</Button>
            <Button onClick={() => this.props.jumpToStep(2)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
          </div>
        </div>
      </div>
    );
  }
}