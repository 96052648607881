import React from "react";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import logo from "../../images/logo-climby.png";
import "./styles.css";

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    let logoClimbySearch =
      this.props.searchMenu != undefined && this.props.searchMenu != null
        ? "logoClimbySearch"
        : "logoClimby";
    let homeMneu =
      this.props.objetivos != undefined && this.props.objetivos != null
        ? "homeObjetivo"
        : this.props.searchMenu == undefined || this.props.searchMenu == null
        ? "homeMneu"
        : "";
    let menuDefault =
      this.props.searchMenu != undefined || this.props.searchMenu != null
        ? "menu-default-searche"
        : "";

    return (
      <div className={`menu-default ${menuDefault}`}>
        <Navbar light expand="md">
          <div className="container">
            <NavbarBrand
              className="navbarBrand"
              href={process.env.REACT_APP_URL_WORDPRESS}
            >
              <img
                src={logo}
                title="climby"
                className={`${logoClimbySearch}`}
              />
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle} />
          <Collapse
            isOpen={this.state.isOpen}
            className="telaPrincipalMenu"
            navbar
          >
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  href={process.env.REACT_APP_URL_WORDPRESS}
                  className={homeMneu}
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={`${process.env.REACT_APP_URL_WORDPRESS}sou-coach/`}
                >
                  Sou Coach
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${process.env.REACT_APP_URL_WORDPRESS}planos/`}>
                  Planos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${process.env.REACT_APP_URL_WORDPRESS}blog/`}>
                  Blog
                </NavLink>
              </NavItem>
              <NavItem>
                <div className="login-btn">
                  <a
                    className="btn btn-default text-uppercase"
                    href={process.env.REACT_APP_URL_PLATFORM}
                  >
                    <span className="LocalLogin">Login</span>
                  </a>
                </div>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
