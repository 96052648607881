import { all } from 'redux-saga/effects';
import { watchHomeSagas } from './containers/home/sagas';
import { watchImcoachSagas } from './containers/imcoach/sagas';
import { watchSearchesSagas } from './containers/searches/sagas';
import { watchAssessmentSagas } from './containers/assessment/sagas';

export default function* rootSaga() {
  yield all([
    watchHomeSagas(),
    watchImcoachSagas(),
    watchSearchesSagas(),
    watchAssessmentSagas()
  ]);
}
