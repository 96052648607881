import React from 'react';
import InputGroup from 'reactstrap/lib/InputGroup';
import Input from 'reactstrap/lib/Input';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import { Button } from 'reactstrap';
// Images
import Brain from '../../../images/icons-images/svg/brain.svg';
import Cross from '../../../images/icons-images/svg/cross.svg';
import Currency from '../../../images/icons-images/svg/currency-wizard.svg';
import Handbag from '../../../images/icons-images/svg/handbag.svg';
import Heart from '../../../images/icons-images/svg/heart.svg';
import Home from '../../../images/icons-images/svg/home-wizard.svg';
import UserGroup from '../../../images/icons-images/svg/user-group-wizard.svg';


export default class Step3 extends React.Component {
  count = 0;

  constructor(props) {
    super(props);
    this.state = {
      high_point: {
        professional: props.getStore().high_point.professional,
        emocional: props.getStore().high_point.emocional,
        cheers: props.getStore().high_point.cheers,
        relationships: props.getStore().high_point.relationships,
        family: props.getStore().high_point.family,
        happiness: props.getStore().high_point.happiness,
        financial: props.getStore().high_point.financial
      }
    }
    for (var [key, val] of Object.entries(this.state.high_point)) {
      console.log(val);
      if(val) {
        this.count++;
      }
    }
  }




  groupCheckbox = [
    {
      image: Handbag,
      text: "Realização Profissional",
      type: "professional"
    },
    {
      image: Cross,
      text: "Saúde",
      type: "cheers"
    },
    {
      image: Heart,
      text: "Felicidade",
      type: "happiness"
    },
    {
      image: Brain,
      text: "Equilíbrio Emocional",
      type: "emocional"
    },
    {
      image: UserGroup,
      text: "Relacionamento",
      type: "relationships"
    },
    {
      image: Currency,
      text: "Financeiro",
      type: "financial"
    },
    {
      image: Home,
      text: "Família",
      type: "family"
    },
  ];


  createList = (array) => {
    let value = [];
    for (let i = 0; i < array.length; i++) {
      value.push(
        <div key={i} className={`form-group checkbox-form ${i === 2 || i === 5 || i === 6 ? 'pr0' : ''}`}>
          <InputGroup>
            <Input
              type={'checkbox'}
              id={array.type}
              onChange={(value) => this.saveInput(value.target, array[i].type)}
              checked={this.state.high_point[array[i].type]}
            />
            <InputGroupAddon addonType="prepend"><img className={array[i].image === Currency ? 'last' : ''} src={array[i].image} /> {array[i].text}</InputGroupAddon>
            <span className="checkmark"></span>
          </InputGroup>
        </div>
      );
    }
    return value;
  }

  saveInput(value, type) {
    const object = this.state.high_point;
    let update = { high_point: {} };
    for (var [key, val] of Object.entries(object)) {
      if (key === type) {
        object[type] = !value.checked ? value.checked : (this.count < 4 ? value.checked : false);
        update.high_point = object;
        if (value.checked) {
          this.count++;
        } else {
          this.count--;
        }
      }
    }

    if (this.count <= 3) {
      this.setState(update);
      this.props.updateStore(update);
    }
  }

  render() {
    return (
      <div id="step3" className="step-container">
        <h2 className="text-center">Quais destas áreas você acredita que poderia causar um impacto positivo Na sua vida caso fosse melhorada? (Escolha até 3 áreas)</h2>
        <h4 className="text-center">Não existe certo ou errado. A sua resposta representa o seu sentimento atual.</h4>
        <div className="form-container">
          <form>
            {this.createList(this.groupCheckbox)}
          </form>
          <div className="footer-buttons text-center">
            <Button onClick={() => this.props.jumpToStep(1)} className="text-uppercase btn-next" color="primary">Voltar</Button>
            <Button disabled={this.count < 3} onClick={() => this.props.jumpToStep(3)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
          </div>
        </div>
      </div>
    );
  }
}