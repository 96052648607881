import React, { Component } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import Select from "react-select";
import * as qs from "query-string";
import ReactLoading from "react-loading";
import ReadMoreReact from "read-more-react";
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fabStar } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Row,
  Col,
  Nav,
  Input,
  Label,
  Media,
  NavItem,
} from "reactstrap";

// Layouts
import Menu from "./menu_search";

// Images
import CertifiedProfile from "../../images/CertifiedProfile.png";
import StarImage from "../../images/star.png";
import PlayerImage from "../../images/icons-images/svg/player.svg";
import SlidesImage from "../../images/icons-images/svg/slides.svg";
import CertifiedImage from "../../images/icons-images/svg/certified.svg";
import ListImage from "../../images/icons-images/svg/list2.svg";
import PersonUpImage from "../../images/icon-5.svg";
import HeadsetImage from "../../images/icon-6.svg";
import PersonUpImageB from "../../images/icon-5b.svg";
import HeadsetImageB from "../../images/icon-6b.svg";
import PriceImage from "../../images/icons-images/svg/price.svg";
import Envelope from "../../images/icons-images/envelope.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ButtonGroup from "reactstrap/lib/ButtonGroup";
import Badge from "reactstrap/lib/Badge";

import SearchService from "./service/SearchService";
import ImageAvatar from "./../../components/imageAvatar";

import "./style.css";

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronDown,
  faChevronUp,
  faStar
);

let timer = 0;
const MAX_ITENS_PER_PAGE = 10;

class Searches extends Component {
  countries = [];
  sessions = [];
  lifeCoach = [];
  professionals = [];
  institutes = [];
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: false,
      isToggleOnExp: true,
      rating: 1,
      actived: "",
      cities: [],
      features: [],
      pages: [
        { value: 1, label: "1 por página" },
        { value: 5, label: "5 por página" },
        { value: 10, label: "10 por página" },
      ],
      classific: [
        { value: 0, label: "Classificar por" },
        { value: 1, label: "Menor preço" },
        { value: 2, label: "Maior Preço" },
        { value: 3, label: "Melhor avaliação" },
        { value: 4, label: "Ordem alfabética" },
      ],
      filter: {},
      coaches: [],
      coachesReal: [],
      number_page: 0,
      form_contact: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      more: "",
      modal_video: false,
      video_source: "",
      player: null,
      total_item: 0,
      item_page: 10,
      activePage: 1,
      typeClass: 0,
      coachesId: [],
      selectedOptoionIndex: 2,
      currentYear: new Date().getFullYear(),
      total_coaches: 0,
      states: [],
      statesCities: [],
    };

    const { fetchData } = this.props;
    fetchData();

    this.handleClick = this.handleClick.bind(this);
    this.handleClickExp = this.handleClickExp.bind(this);
    this.sendMsgm = this.sendMsgm.bind(this);
    this.openSlide = this.openSlide.bind(this);
    this.openVideo = this.openVideo.bind(this);
    this.searchService = new SearchService();
  }

  resolveURL(path, basePath) {
    return new URL(path, basePath).href;
  }

  async trackFilter(type, id) {
    let _payload = { type, id };
    this.service(
      "POST",
      `${process.env.REACT_APP_URL_BASE}engine/track-filter`,
      _payload
    );
  }

  service(method, url, data) {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body:
        method === "POST"
          ? data != null
            ? this.parsePayload(data)
            : []
          : null,
    });
  }

  parsePayload(_payload) {
    return Object.keys(_payload)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          _payload[key]
        )}`;
      })
      .join("&");
  }

  async componentDidMount() {
    this.initRender();

    window.scrollTo(0, 0);
    if (this.props.location.search) {
      setTimeout(() => {
        this.initRender();
        setTimeout(async () => {
          const filter = qs.parse(this.props.location.search);
          if (filter.area || filter.areas) {
            if (filter.area) {
              let infos = this.getInfos(filter.area);
              if (infos) {
                this.updateFilterChecekbox(true, infos.id, infos.type);
              }
            } else {
              if (filter.areas) {
                let _areas = filter.areas.split(",");
                for (let _a of _areas) {
                  let infos = this.getInfos(_a);
                  if (infos) {
                    await this.updateFilterChecekbox(
                      true,
                      infos.id,
                      infos.type
                    );
                  }
                }
              }
            }
          } else {
            this.updateFilterChecekbox(true, filter.id, filter.type);
            this.updateFilter(filter.state, "state");
          }
        }, 200);
      }, 200);
    }
    this.getCoaches();
  }

  handleClickBlock(index, el, idcoach) {
    const data = {
      click_at: index === "contact" ? "contact" : "profile",
      coach_id: idcoach,
    };
    this.service("POST", `${process.env.REACT_APP_URL_BASE}track`, data).then(
      (res) => {
        const name = index + el;
        if (name === this.state.actived) {
          this.setState({ actived: "" });
        } else {
          this.setState({ actived: index + el });
        }
      }
    );
  }

  getInfos(area_label) {
    for (let _i of this.lifeCoach) {
      if (`${_i.label}`.toLowerCase() == `${area_label}`.toLowerCase()) {
        return { id: _i.value, type: "life" };
      }
    }
    for (let _i of this.professionals) {
      if (`${_i.label}`.toLowerCase() == `${area_label}`.toLowerCase()) {
        return { id: _i.value, type: "professional" };
      }
    }
    return false;
  }

  getCoaches = async (body, page = 1) => {
    this.setState({ coaches: [] });

    if (body === "" || body === undefined) {
      body = this.state.filter;
    }

    body["page"] = page;
    body["page_size"] = this.state.item_page;
    body["typeClass"] = this.state.typeClass;
    body["coachesId"] = JSON.stringify(this.state.coachesId);

    if (body && body.areas && typeof body.areas !== "string") {
      body.areas = JSON.stringify(body.areas);
    }

    this.getTotalCoachesWithoutFilter();

    await this.service("POST", `${process.env.REACT_APP_URL_API}/search`, body)
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            coaches: res.coachs,
            number_page: res.pages,
            total_item: res.pages,
            coachesReal: res.resultGeral,
          });
        }
      });
  };

  getTotalCoachesWithoutFilter = async () => {
    await this.service("POST", `${process.env.REACT_APP_URL_API}/search`, {
      saveTracks: false,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            total_coaches: res.resultGeral.length,
          });
        }
      });
  };

  ordenacao = (data) => {
    if (
      this.state.typeClass !== 0 &&
      this.state.typeClass !== null &&
      this.state.typeClass !== undefined
    ) {
      if (this.state.typeClass === 3) {
        data.sort(function (a, b) {
          if (a.experience.rate > b.experience.rate) {
            return 1;
          }
          if (a.experience.rate < b.experience.rate) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        data.reverse();
      } else {
        data.sort(function (a, b) {
          if (a.classificar > b.classificar) {
            return 1;
          }
          if (a.classificar < b.classificar) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
      if (this.state.typeClass === 2) {
        data.reverse();
      }
    }

    return data;
  };

  ChangeClassificar = async (value) => {
    await this.setState({ typeClass: value });
    await this.updateFilter(MAX_ITENS_PER_PAGE, "page_size");
    this.getCoaches("", 1);
  };

  handlePageChange = (pageNumber) => {
    this.getCoaches("", pageNumber);
    this.setState({ activePage: pageNumber });
  };

  initRender() {
    const { data } = this.props;
    this.countries = [];
    this.lifeCoach = [];
    this.professionals = [];
    this.sessions = [];
    this.institutes = [];

    this.sessions = [
      {
        label: "Presencial",
        value: 2,
        icon: PersonUpImage,
        iconB: PersonUpImageB,
      },
      {
        label: "Online",
        value: 1,
        icon: HeadsetImage,
        iconB: HeadsetImageB,
      },
    ];

    if (data.areas.life && data.areas.life.length > 0) {
      data.areas.life.map((res) => {
        this.lifeCoach.push({
          views: res.views ? res.views : 0,
          label: res.option,
          value: res.id,
        });
      });
    }

    if (data.areas.professional && data.areas.professional.length > 0) {
      data.areas.professional.map((res) => {
        this.professionals.push({
          views: res.views ? res.views : 0,
          label: res.option,
          value: res.id,
        });
      });
    }

    if (data.areas.sessions && data.areas.sessions.length > 0) {
      data.areas.sessions.map((res) => {
        this.sessions.push({
          label: res.option,
          value: res.id,
          icon: res.option === "online" ? PersonUpImage : HeadsetImage,
        });
      });
    }

    if (data.institutes && data.institutes.length > 0) {
      this.institutes.push({
        label: "Todas Instituições",
        value: "",
        partner: false,
      });
      data.institutes.map((res) => {
        if (res.partner) {
          this.institutes.push({
            label: res.name,
            value: res.name,
            partner: res.partner,
          });
        }
      });
    }

    if (data.countries && data.countries.length > 0) {
      this.countries.push({
        label: "Todos países",
        value: "",
      });
      data.countries.forEach((res) => {
        this.countries.push({
          label: res.name,
          value: res.name,
        });
      });
    }
  }

  async searchName(value) {
    let coachesData = this.state.coachesReal;
    if (value && value.trim() !== "") {
      coachesData = coachesData.filter((item) => {
        const name = item.engage_name;
        return name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });

      await this.setState({ coachesId: [] });
      await coachesData.map((data) => {
        // eslint-disable-next-line no-unused-expressions
        !this.state.coachesId.includes(data._id)
          ? this.setState({ coachesId: [data._id, ...this.state.coachesId] })
          : "";
      });
      await this.setState({
        coaches: coachesData,
        total_item: coachesData.length,
      });
    } else {
      await this.setState({ coachesId: [] });
      this.updateFilter(MAX_ITENS_PER_PAGE, "page_size");
      await this.getCoaches();
    }
  }

  async changeCountry(value) {
    if (value && value.length > 0) {
      const states = [];
      this.setState({
        states: [],
        cities: [],
        city: "",
      });
      let filter = this.state.filter;
      filter["country"] = "";
      filter["state"] = "";
      filter["city"] = "";

      this.setState({ filter: filter });

      const resultCountryStates = await this.searchService.statesForeign(value);

      if (resultCountryStates.success) {
        this.setState({ statesCities: resultCountryStates.data[0] });
        const statesForeing = resultCountryStates.data[0].states.map((item) => {
          return { name_uf: item.name, sigla_uf: item.initials_name };
        });

        states.push({
          label: "Todos estados",
          value: "",
        });

        statesForeing.forEach((item) => {
          states.push({
            label: item.name_uf,
            value: value === "Brasil" ? item.sigla_uf : item.name_uf,
          });
        });
        this.setState({
          states,
        });
      }
    } else {
      this.setState({
        states: [],
        cities: [],
        city: "",
      });
      let filter = this.state.filter;
      filter["country"] = "";
      filter["state"] = "";
      filter["city"] = "";

      this.setState({ filter: filter });
    }
    this.updateFilter(value, "country");
  }

  async loadCities(state) {
    const citiesData = this.state.statesCities.states.filter(
      (item) => item.name === state
    );
    const cities = [];
    cities.push({
      label: "Todas cidades",
      value: "",
    });
    citiesData[0].cities.forEach((res) => {
      cities.push({
        label: res.name,
        value: res.name,
      });
    });
    this.setState({ cities });
  }

  async changeState(value) {
    if (value && value.length > 0) {
      await this.setState({
        cities: [],
        city: "",
      });

      let filter = this.state.filter;
      filter["city"] = "";

      this.setState({ filter: filter });

      if (this.state.filter.country === "Brasil") {
        this.service("GET", `${process.env.REACT_APP_URL_API}/${value}/cities`)
          .then((res) => res.json())
          .then((response) => {
            if (response.success) {
              let cities = [];
              cities.push({
                label: "Todas cidades",
                value: "",
              });
              response.cities.map((res) => {
                cities.push({
                  label: res.nome_municipio,
                  value: res.nome_municipio,
                });
              });
              this.setState({ cities });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        await this.loadCities(value);
      }
    } else {
      await this.setState({
        cities: [],
        city: "",
      });
      let filter = this.state.filter;
      filter["city"] = "";

      this.setState({ filter: filter });
    }
    this.updateFilter(value, "state");
  }

  ChangeSelect(value, type) {
    let __v = value ? value : "";
    this.updateFilter(__v, type);
    if (type == "institute" || type == "city") {
      this.setState({ [type]: __v });
    }
  }

  async updateFilter(value, type) {
    let filter = this.state.filter;
    filter[type] = value;

    if (value !== undefined && value !== "") {
      let pageSize = parseInt(value);
      if (type === "page_size") {
        this.state.pages.map((data, indice) => {
          if (data.value === pageSize) {
            this.setState({ selectedOptoionIndex: indice });
          }
        });
      }
      await this.setState({
        filter: filter,
        item_page: Number.isInteger(pageSize) ? pageSize : this.state.item_page,
        activePage: 1,
      });
    }

    setTimeout(() => {
      this.getCoaches(this.state.filter);
    }, 1000);
  }

  updateFilterChecekbox(checked, value, type) {
    return new Promise((resolve, reject) => {
      let filter = this.state.filter;
      let area = filter.areas ? { areas: filter.areas } : { areas: {} };

      area.areas =
        typeof area.areas == "string" ? JSON.parse(area.areas) : area.areas;

      setTimeout(async () => {
        if (typeof area.areas === "string") {
          area.areas = JSON.parse(area.areas);
        }
        if (type != "sessions") {
          if (!area.areas[type]) {
            area.areas[type] = [];
          }
        } else {
          if (!area.areas[type]) {
            area.areas[type] = {};
          }
        }

        if (checked) {
          if (type == "sessions") {
            area.areas[type][value == 1 ? "online" : "presential"] = true;
          } else {
            area.areas[type].push(Number(value));
            this.trackFilter(type, value);
          }
        } else {
          if (type == "sessions") {
            delete area.areas[type][value == 1 ? "online" : "presential"];
            if (!area.areas[type].online && !area.areas[type].presential) {
              delete area.areas[type];
            }
          } else {
            area.areas[type] = area.areas[type].filter((res) => res != value);
          }
        }


        filter["areas"] = area.areas;

        console.log("filter", filter);


        await this.setState({ filter: filter });

        setTimeout(() => {
          this.getCoaches(this.state.filter);
          resolve(true);
        }, 1000);
      }, 500);
    });
  }

  defaultCheck(type, id) {
    let response = false;
    if (this.state.filter.areas) {
      let areas =
        typeof this.state.filter.areas === "string"
          ? JSON.parse(this.state.filter.areas)
          : this.state.filter.areas;
      areas = typeof areas === "string" ? JSON.parse(areas) : areas;

      Object.keys(areas).map((res) => {
        if (res === type) {
          if (type == "sessions") {
            response = areas[type][id == 1 ? "online" : "presential"] || false;
          } else {
            areas[type].map((x) => {
              if (x === id) {
                response = true;
              }
            });
          }
        }
      });
    }
    return response;
  }

  sortCreateCheck(array1, array2) {
    let _na = [];
    for (let _it of array1) {
      _na.push({ ..._it, type: "professional" });
    }
    for (let _it of array2) {
      _na.push({ ..._it, type: "life" });
    }

    _na = _na.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    _na = _na.sort((a, b) => {
      if (a.views < b.views) {
        return 1;
      }
      if (a.views > b.views) {
        return -1;
      }
      return 0;
    });

    let array = _na;
    if (array && array.length > 0) {
      let value = [];
      for (let i = 0; i < array.length; i++) {
        const checkedDefault = this.defaultCheck(array[i].type, array[i].value);
        value.push(
          <FormGroup key={i} className="checkbox-default marginBReset">
            <Label>
              <Input
                onChange={(e) =>
                  this.updateFilterChecekbox(
                    e.target.checked,
                    e.target.value,
                    array[i].type
                  )
                }
                type="checkbox"
                checked={checkedDefault}
                value={array[i].value}
                name={array[i].value}
              />{" "}
              <span className="checkmark" />
              <span className="text">
                {array[i].label}{" "}
                {array[i].icon ? (
                  <img
                    alt=""
                    className="icons"
                    src={checkedDefault ? array[i].iconB : array[i].icon}
                  />
                ) : (
                  ""
                )}
              </span>
            </Label>
          </FormGroup>
        );
      }
      return value;
    }
  }

  createCheck = (array, type) => {
    if (array && array.length > 0) {
      let value = [];
      for (let i = 0; i < array.length; i++) {
        const offset = i === 0 ? 1 : 0;
        const checkedDefault = this.defaultCheck(type, array[i].value);
        value.push(
          <FormGroup key={i} className="checkbox-default marginBReset">
            <Label>
              <Input
                onChange={(value) =>
                  this.updateFilterChecekbox(
                    value.target.checked,
                    value.target.value,
                    type
                  )
                }
                type="checkbox"
                checked={checkedDefault}
                value={array[i].value}
                name={array[i].value}
              />{" "}
              <span className="checkmark" />
              <span className="text">
                {array[i].label}{" "}
                {array[i].icon ? (
                  <img
                    alt=""
                    className="icons"
                    src={checkedDefault ? array[i].iconB : array[i].icon}
                  />
                ) : (
                  ""
                )}
              </span>
            </Label>
          </FormGroup>
        );
      }
      return value;
    }
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  updateContact(value, type) {
    const form = this.state.form_contact;
    switch (type) {
      case "name":
        form.name = value;
        break;
      case "email":
        form.email = value;
        break;
      case "phone":
        form.phone = value;
        break;
      case "message":
        form.message = value;
        break;
      default:
    }
    this.setState({ form_contact: form });
  }

  sendMsgm(id) {
    const data = this.state.form_contact;
    data["coach_id"] = id;
    this.setState({ loading: true });
    this.service(
      "POST",
      `${process.env.REACT_APP_URL_BASE}contacts`,
      data
    ).then((res) => {
      if (res.status == 200) {
        alert("Contato enviado com sucesso");
        this.setState({
          form_contact: {
            name: "",
            email: "",
            phone: "",
            message: "",
          },
        });
      }
      this.setState({ loading: false, actived: "" });
    });
  }

  parseYoutubeID(value) {
    if (value.indexOf("?v=") !== -1) {
      let _begin = value.indexOf("?v=") + 3;
      let _end = value.indexOf("?v=") + 3 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf("youtu.be/") !== -1) {
      let _begin = value.indexOf("youtu.be/") + 9;
      let _end = value.indexOf("youtu.be/") + 9 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf("embed/") !== -1) {
      let _begin = value.indexOf("embed/") + 6;
      let _end = value.indexOf("embed/") + 6 + 11;
      return value.substring(_begin, _end);
    }
    return false;
  }

  openVideo(item) {
    this.openModalVideo(this.parseYoutubeID(item.video));
  }

  async openSlide(item) {
    try {
      if (item.slides) {
        const doc = JSON.parse(item.slides).doc_source;
        if (doc !== "") {
          try {
            const result = await this.professionalPresent(doc);
            if (result.status === 200) {
              window.open(this.resolveURL(doc, process.env.REACT_APP_URL_BASE));
            } else {
              alert("Apresentação indisponível no momento.");
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    } catch (eel) {
      console.error(eel);
    }
  }

  async professionalPresent(doc) {
    try {
      //ao tentar obter uma resposta da URL de um arquivo no S3, ele estava dando erro de CORS, por isso fiz desta maneira, por enquanto.
      const isS3File = doc.includes("s3.amazonaws");
      if (isS3File) {
        return { status: 200 };
      }
      const presentation = this.resolveURL(doc, process.env.REACT_APP_URL_BASE);
      let response = await fetch(presentation, {
        method: "GET",
      });
      return response;
    } catch (error) {
      console.error("professionalPresent", error);
    }
  }

  noHave(item) {
    try {
      if (item.slides) {
        if (
          JSON.parse(item.slides).doc_source !== "" &&
          JSON.parse(item.slides).doc_source !== undefined &&
          JSON.parse(item.slides).doc_source !== null
        ) {
          return false;
        }
      }
    } catch (eel) { }
    return true;
  }

  createList = (array) => {
    const { rating } = this.state;
    const handdle = false;
    let value = [];
    for (let i = 0; i < array.length; i++) {
      value.push(
        <div key={i} className="item">
          <Media
            className={`${array[i].premium ? "premium" : ""} ${i === array.length - 1 ? "last" : ""
              }`}
          >
            {/* <Media className={`${true ? 'premium' : ''} ${i === (array.length - 1) ? 'last' : ''}`}> */}
            <Media left className="coach">
              <figure>
                <ImageAvatar coach={array[i]} />
              </figure>
              <ButtonGroup>
                <Button
                  title={
                    !(
                      !array[i].video ||
                      array[i].video === "undefined" ||
                      !this.parseYoutubeID(array[i].video)
                    )
                      ? "Veja o vídeo"
                      : "Vídeo não disponível"
                  }
                  disabled={
                    !array[i].video ||
                    array[i].video === "undefined" ||
                    !this.parseYoutubeID(array[i].video)
                  }
                  className="btn-play"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.openVideo(array[i]);
                  }}
                >
                  <img alt="" src={PlayerImage} />
                </Button>

                <Button
                  title={
                    !(!array[i].slides || this.noHave(array[i]))
                      ? "Veja a apresentação"
                      : "Apresentação não disponível"
                  }
                  disabled={!array[i].slides || this.noHave(array[i])}
                  className="btn-slide"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.openSlide(array[i]);
                  }}
                >
                  <img alt="" src={SlidesImage} />
                </Button>
              </ButtonGroup>

              <div className="text-center sesaoBottom">
                {/* alteracao rfr Intera 7-11-19 */}
                <Button
                  disabled={
                    !array[i].master_free || array[i].master_free == "undefined"
                  }
                  onClick={() =>
                    this.handleClickBlock("contact", i, array[i]._id)
                  }
                  color="info"
                  className={
                    !array[i].master_free
                      ? "text-uppercase btn-info-disabled"
                      : "text-uppercase"
                  }
                >
                  Sessão gratuita
                </Button>
                {/*   <Button color="info" className="text-uppercase">Sessão gratuita</Button>  (botao original comentado em 7-11-19 Intera) */}
              </div>
            </Media>
            <Media body>
              <div className="content-block">
                <header>
                  <Media heading>{array[i].engage_name}</Media>
                  <div className="container-coach-location">
                    <h5 style={{ marginTop: "0px", marginRight: "5px" }}>
                      {array[i].city} - {array[i].state}
                    </h5>
                    <img
                      width="30"
                      src={array[i].country_flag}
                      alt={array[i].country}
                    />
                  </div>
                </header>
                <div className="infs">
                  <div className="offices">
                    <h6>Áreas:</h6>
                    <ul>
                      {array[i].areas.map((res, index) =>
                        index <= 2 ? (
                          <li className="text-bottom" key={index}>
                            <Badge color="default" className="text-uppercase">
                              {res}
                            </Badge>
                          </li>
                        ) : (
                          ""
                        )
                      )}

                      {this.state.more != array[i]._id &&
                        array[i].areas.length > 3 ? (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ more: array[i]._id });
                          }}
                        >
                          <Badge color="primary" className="text-uppercase">
                            Mais
                          </Badge>
                        </li>
                      ) : (
                        <li className="text-bottom"> </li>
                      )}
                    </ul>
                    <br />
                    {this.state.more == array[i]._id ? (
                      <ul>
                        {array[i].areas.map((res, index) =>
                          index > 2 ? (
                            <li key={index}>
                              <Badge color="default" className="text-uppercase">
                                {res}
                              </Badge>
                            </li>
                          ) : (
                            ""
                          )
                        )}
                      </ul>
                    ) : null}
                  </div>
                  <div className="sessions">
                    <h6>Sessões:</h6>
                    <ul>
                      <li>
                        <img
                          alt=""
                          src={
                            array[i].sessions.presential
                              ? PersonUpImageB
                              : PersonUpImage
                          }
                          width="15"
                        />
                      </li>
                      <li>
                        <img
                          alt=""
                          src={
                            array[i].sessions.online
                              ? HeadsetImageB
                              : HeadsetImage
                          }
                          width="35"
                          style={{ marginLeft: 10 }}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="flags">
                    {array[i].certified ? <img alt="" src={StarImage} /> : ""}
                    {array[i].curation &&
                      array[i].curation.status === "Aprovado" && (
                        <img
                          alt=""
                          className="img-certified-profile"
                          src={CertifiedProfile}
                        />
                      )}
                    {/* {true ? <img src={StarImage} /> : ''}
                    {true ? <img src={AuthImage} /> : ''}*/}
                  </div>
                </div>
                <ReadMoreReact
                  key={array[i].description}
                  className="text display-text-group"
                  text={
                    array[i].description
                      ? array[i].description
                      : "Sem Descrição"
                  }
                  min={210}
                  ideal={240}
                  max={300}
                  readMoreText="...continue lendo"
                />
                <ButtonGroup className="buttons-content show-sm show-xs hidden-sm">
                  <div className="item">
                    <Button
                      className={
                        this.state.actived === `formation${i}`
                          ? "active"
                          : "btn btn-primary"
                      }
                      onClick={() =>
                        this.handleClickBlock("formation", i, array[i]._id)
                      }
                      color="primary"
                    >
                      <img alt="" src={CertifiedImage} /> Experiência
                    </Button>
                  </div>
                  <div className="item">
                    <Button
                      className={
                        this.state.actived === `methodology${i}`
                          ? "active"
                          : "btn btn-primary"
                      }
                      onClick={() =>
                        this.handleClickBlock("methodology", i, array[i]._id)
                      }
                      color="primary"
                    >
                      <img alt="" src={ListImage} /> Metodologia
                    </Button>
                  </div>
                  <div className="item">
                    <Button
                      className={
                        this.state.actived === `values${i}`
                          ? "active"
                          : "btn btn-primary"
                      }
                      onClick={() =>
                        this.handleClickBlock("values", i, array[i]._id)
                      }
                      color="primary"
                    >
                      <img alt="" src={PriceImage} /> Valores
                    </Button>
                  </div>
                  <div className="item">
                    <Button
                      className={
                        this.state.actived === `contact${i}`
                          ? "active"
                          : "btn btn-primary"
                      }
                      onClick={() =>
                        this.handleClickBlock("contact", i, array[i]._id)
                      }
                      color="primary"
                    >
                      <img alt="" src={Envelope} /> Contatar
                    </Button>
                  </div>
                </ButtonGroup>
              </div>
            </Media>
          </Media>

          <div className="hiddenSessaoGratuita">
            <div
              className={
                this.state.actived === `formation${i}`
                  ? "block-content active"
                  : "block-content"
              }
            >
              <div className="block-body">
                <p className="mb">{array[i].experience.description}</p>
                <Row>
                  <Col md="8" sm="6" xs="12">
                    <p>Formações:</p>
                    {array[i].experience.formation.map((res, index) => (
                      <p key={index}>{res}</p>
                    ))}
                  </Col>

                  <Col md="4" sm="6" xs="12">
                    <div className="align-right">
                      {array[i].experience.showRate ? (
                        <div>
                          <h4>Avaliação:</h4>
                          {[1, 2, 3, 4, 5].map((res, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={
                                array[i].experience.rate < res
                                  ? faStar
                                  : fabStar
                              }
                            />
                          ))}
                        </div>
                      ) : null}

                      {/*{[...Array(array[i].experience.rate)].map((e, i) => <span key={i}>★</span>)}*/}

                      <p className="text-uppercase small">
                        climby desde {array[i].experience.climby_since}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              className={
                this.state.actived === `methodology${i}`
                  ? "block-content active"
                  : "block-content"
              }
            >
              <div className="block-body">{array[i].methodology}</div>
            </div>
            <div
              className={
                this.state.actived === `values${i}`
                  ? "block-content active"
                  : "block-content"
              }
            >
              <div className="block-body">
                {array[i].currency
                  ? `${array[i].currency} ${array[i].price}`
                  : array[i].price}
              </div>
            </div>
            <div
              className={
                this.state.actived === `contact${i}`
                  ? "block-content active"
                  : "block-content"
              }
            >
              <div className="block-body">
                <Form className="form">
                  <Row>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <Input
                          type="type"
                          value={this.state.form_contact.name}
                          onChange={(value) =>
                            this.updateContact(value.target.value, "name")
                          }
                          name="name"
                          id={`nameb${i}`}
                          placeholder="Nome"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <Input
                          type="email"
                          style={{ textTransform: "lowercase" }}
                          value={this.state.form_contact.email}
                          onChange={(value) =>
                            this.updateContact(value.target.value, "email")
                          }
                          name="email"
                          id={`emailb${i}`}
                          placeholder="E-mail"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <InputMask
                          value={this.state.form_contact.phone}
                          className="form-control"
                          onChange={(value) =>
                            this.updateContact(value.target.value, "phone")
                          }
                          mask="(99) 9 9999-9999"
                          maskChar={null}
                          required
                          placeholder="Telefone"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Input
                      placeholder="Deixe sua mensagem"
                      value={this.state.form_contact.message}
                      onChange={(value) =>
                        this.updateContact(value.target.value, "message")
                      }
                      rows="8"
                      required
                      type="textarea"
                    />
                  </FormGroup>
                  <div className="text-center">
                    {this.state.loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flex: 1,
                        }}
                      >
                        {" "}
                        <ReactLoading type="bubbles" color="#B1CD49" />{" "}
                      </div>
                    ) : (
                      <Button
                        color="default"
                        onClick={() => this.sendMsgm(array[i]._id)}
                      >
                        Enviar
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <div
            className="item hidden-nd"
            style={{ marginTop: -105, flexDirection: "row" }}
          >
            <div
              style={{ float: "left", width: 250, marginTop: -60 }}
              className="show-sm show-xs hidden-sm"
            >
              {/* <Media style={{ justifyContent: 'center' }} className={`${array[i].premium ? 'premium' : ''} ${i === (array.length - 1) ? 'last' : ''}`}> */}
              <Media
                style={{ justifyContent: "center" }}
                className="bordeSession"
              >
                <Media left className="coach">
                  <div className="text-center">
                    <Button
                      disabled={
                        !array[i].master_free ||
                        array[i].master_free == "undefined"
                      }
                      onClick={() =>
                        this.handleClickBlock("contact", i, array[i]._id)
                      }
                      color="info"
                      className={
                        !array[i].master_free
                          ? "text-uppercase btn-info-disabled"
                          : "text-uppercase"
                      }
                    >
                      Sessão gratuita
                    </Button>
                  </div>
                </Media>
              </Media>
            </div>

            <div
              style={{
                float: "left",
                width: 250,
                marginTop: -60,
                height: 100,
                marginBottom: 10,
              }}
            />

            <div>
              {/* <Media className={`${array[i].premium ? 'premium' : ''} ${i === (array.length - 1) ? 'last' : ''}`}> */}
              <Media className="bordeBtn">
                <Media body>
                  <div style={{ backgroundColor: "#fff" }}>
                    <ButtonGroup className="buttons-content">
                      <div className="item">
                        <Button
                          className={
                            this.state.actived === `formation${i}`
                              ? "active"
                              : "btn btn-primary"
                          }
                          onClick={() =>
                            this.handleClickBlock("formation", i, array[i]._id)
                          }
                          color="primary"
                        >
                          <img alt="" src={CertifiedImage} /> Experiência
                        </Button>
                      </div>
                      <div className="item">
                        <Button
                          className={
                            this.state.actived === `methodology${i}`
                              ? "active"
                              : "btn btn-primary"
                          }
                          onClick={() =>
                            this.handleClickBlock(
                              "methodology",
                              i,
                              array[i]._id
                            )
                          }
                          color="primary"
                        >
                          <img alt="" src={ListImage} /> Metodologia
                        </Button>
                      </div>
                      <div className="item">
                        <Button
                          className={
                            this.state.actived === `values${i}`
                              ? "active"
                              : "btn btn-primary"
                          }
                          onClick={() =>
                            this.handleClickBlock("values", i, array[i]._id)
                          }
                          color="primary"
                        >
                          <img alt="" src={PriceImage} /> Valores
                        </Button>
                      </div>
                      <div className="item">
                        <Button
                          className={
                            this.state.actived === `contact${i}`
                              ? "active"
                              : "btn btn-primary"
                          }
                          onClick={() =>
                            this.handleClickBlock("contact", i, array[i]._id)
                          }
                          color="primary"
                        >
                          <img alt="" src={Envelope} /> Contato
                        </Button>
                      </div>
                    </ButtonGroup>
                  </div>
                </Media>
              </Media>

              <div
                className={
                  this.state.actived === `formation${i}`
                    ? "block-content active"
                    : "block-content"
                }
              >
                <div className="block-body">
                  <p className="mb">{array[i].experience.description}</p>
                  <Row>
                    <Col md="8" sm="6" xs="12">
                      <p>Formações:</p>
                      {array[i].experience.formation.map((res, index) => (
                        <p key={index}>{res}</p>
                      ))}
                    </Col>

                    <Col md="4" sm="6" xs="12">
                      <div className="align-right">
                        {array[i].experience.showRate ? (
                          <div>
                            <h4>Avaliação:</h4>
                            {[1, 2, 3, 4, 5].map((res, index) => (
                              <FontAwesomeIcon
                                key={index}
                                icon={
                                  array[i].experience.rate < res
                                    ? faStar
                                    : fabStar
                                }
                              />
                            ))}
                          </div>
                        ) : null}

                        {/*{[...Array(array[i].experience.rate)].map((e, i) => <span key={i}>★</span>)}*/}

                        <p className="text-uppercase small">
                          climby desde {array[i].experience.climby_since}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div
                className={
                  this.state.actived === `methodology${i}`
                    ? "block-content active"
                    : "block-content"
                }
              >
                <div className="block-body">{array[i].methodology}</div>
              </div>
              <div
                className={
                  this.state.actived === `values${i}`
                    ? "block-content active"
                    : "block-content"
                }
              >
                <div className="block-body">
                  {array[i].currency
                    ? `${array[i].currency} ${array[i].price}`
                    : array[i].price}
                </div>
              </div>
              <div
                className={
                  this.state.actived === `contact${i}`
                    ? "block-content active"
                    : "block-content"
                }
              >
                <div className="block-body">
                  <Form className="form">
                    <Row>
                      <Col md="6" sm="12">
                        <FormGroup>
                          <Input
                            type="type"
                            value={this.state.form_contact.name}
                            onChange={(value) =>
                              this.updateContact(value.target.value, "name")
                            }
                            name="name"
                            id={`name${i}`}
                            placeholder="Nome"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="12">
                        <FormGroup>
                          <Input
                            type="email"
                            style={{ textTransform: "lowercase" }}
                            value={this.state.form_contact.email}
                            onChange={(value) =>
                              this.updateContact(value.target.value, "email")
                            }
                            name="email"
                            id={`email${i}`}
                            placeholder="E-mail"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="12">
                        <FormGroup>
                          <InputMask
                            value={this.state.form_contact.phone}
                            className="form-control"
                            onChange={(value) =>
                              this.updateContact(value.target.value, "phone")
                            }
                            mask="(99) 9 9999-9999"
                            maskChar={null}
                            required
                            placeholder="Telefone"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Input
                        placeholder="Deixe sua mensagem"
                        value={this.state.form_contact.message}
                        onChange={(value) =>
                          this.updateContact(value.target.value, "message")
                        }
                        rows="8"
                        required
                        type="textarea"
                      />
                    </FormGroup>
                    <div className="text-center">
                      {this.state.loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flex: 1,
                          }}
                        >
                          {" "}
                          <ReactLoading type="bubbles" color="#B1CD49" />{" "}
                        </div>
                      ) : (
                        <Button
                          color="default"
                          onClick={() => this.sendMsgm(array[i]._id)}
                        >
                          Enviar
                        </Button>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return value;
  };

  handleClick() {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }

  handleClickExp() {
    this.setState((state) => ({
      isToggleOnExp: !state.isToggleOnExp,
    }));
  }

  checkState(value, states) {
    return states.filter((res) => res.value === value);
  }

  tryClose(ev) {
    if (
      this.refs["modal-content"] &&
      !this.refs["modal-content"].contains(ev.target)
    ) {
      this.setState({ player: null });
      this.toggle("modal_video");
    }
  }

  toggle(item) {
    this.setState({ [item]: !this.state[item] });
  }

  async openModalVideo(video_source) {
    this.setState({ video_source });
    await this.toggle("modal_video");
    // window['onYouTubeIframeAPIReady'] = (e) => {
    let YT = window["YT"];
    let player = new YT.Player("ytplayer", {
      events: {
        onStateChange: this.ShowMe.bind(this),
      },
    });
    this.setState({ player });
  }

  ShowMe() {
    let sStatus = this.state.player.getPlayerState();
    if (sStatus == -1) {
      timer = setInterval(() => {
        this.setState({ player: null });
        this.toggle("modal_video");
        clearInterval(timer);
      }, 500);
    } else if (sStatus == 0) {
    } else if (sStatus == 1) {
      clearInterval(timer);
    } //Video is playing
    else if (sStatus == 2) {
    } //Video is paused
    else if (sStatus == 3) {
      clearInterval(timer);
    } //video is buffering
    else if (sStatus == 5) {
    } //Video is cued.
  }

  //**************************************************************************/
  //Scroll
  renderScroll = () => {
    return window.pageYOffset;
  };

  handleTextTotalCoaches = () => {
    return this.state.coaches.length === 0 ||
      this.state.total_item === this.state.total_coaches
      ? ""
      : this.state.total_item > 1
        ? `${this.state.total_item} coaches encontrados`
        : `${this.state.total_item} coach encontrado`;
  };

  render() {
    this.initRender();

    return (
      <>
        <div
          id="home"
          className="home-container boxShadowSearche"
          data-test-id="home-container"
        >
          <header id="header" className="headerSearche">
            <Menu searchMenu={true} />
            <div
              id="searches"
              className="searches-container header-climb-section2 menuClimby"
              data-test-id="searches-container"
            >
              <Container fluid={true}>
                <div className="header-list-search">
                  <Row>
                    <Col className="select-group-search">
                      <div>
                        <label
                          id="retratil"
                          for="check"
                          onClick={this.handleClickExp}
                          className="btn btn-default retratil-mg"
                        >
                          Filtro avançado
                          <img
                            src={
                              this.state.isToggleOnExp
                                ? require("./../../images/header-chevron-open.png")
                                : require("./../../images/header-chevron-close.png")
                            }
                            className="icon-search"
                            alt="Filtro"
                          />
                        </label>
                      </div>
                    </Col>
                    <Col className="select-group-search" xs="4">
                      {this.handleTextTotalCoaches()}
                    </Col>
                    <Col xs="4">
                      <div className="select-group-search">
                        <Select
                          placeholder="10 por página"
                          onChange={(value) =>
                            this.ChangeSelect(value.value, "page_size")
                          }
                          options={this.state.pages}
                          value={
                            this.state.pages[this.state.selectedOptoionIndex]
                          }
                        />
                        <Select
                          placeholder="Classificar por"
                          onChange={(value) =>
                            this.ChangeClassificar(value.value)
                          }
                          options={this.state.classific}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </header>
        </div>

        <div
          id="searches"
          className="searches-container"
          data-test-id="searches-container"
        >
          <div id="body-main">
            <Container fluid={true}>
              <Row className="newScroll-01">
                <Col
                  lg="2"
                  sm="12"
                  style={{ marginTop: "2%", fontSize: "80%" }}
                >
                  <input
                    type="checkbox"
                    id="check"
                    checked={this.state.isToggleOnExp}
                  />
                  <div
                    className={`${this.state.isToggleOnExp ? "active filter" : "filter"
                      }`}
                  >
                    <Form className="form-filter">
                      <Nav vertical>
                        <NavItem>
                          <FormGroup>
                            <Label className="float">
                              <FontAwesomeIcon icon="search" />
                            </Label>
                            <Input
                              type="text"
                              onChange={(value) =>
                                this.searchName(value.target.value)
                              }
                              placeholder="Nome"
                            />
                          </FormGroup>
                          <FormGroup>
                            {this.countries.length > 0 ? (
                              <Select
                                placeholder="País"
                                defaultValue={
                                  this.state.filter.country
                                    ? this.checkState(
                                      this.state.filter.country,
                                      this.countries
                                    )
                                    : ""
                                }
                                onChange={(value) =>
                                  this.changeCountry(value.value)
                                }
                                options={this.countries}
                              />
                            ) : (
                              ""
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Select
                              isDisabled={this.state.states.length === 0}
                              placeholder="Estado"
                              value={
                                this.state.filter.state
                                  ? this.checkState(
                                    this.state.filter.state,
                                    this.state.states
                                  )
                                  : ""
                              }
                              onChange={(value) =>
                                this.changeState(value.value)
                              }
                              options={this.state.states}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Select
                              isDisabled={this.state.cities.length === 0}
                              placeholder="Cidade"
                              value={this.state.cities.filter(
                                (option) => option.label === this.state.city
                              )}
                              onChange={(value) =>
                                this.ChangeSelect(value.value, "city")
                              }
                              options={this.state.cities}
                            />
                          </FormGroup>
                        </NavItem>
                      </Nav>

                      <Nav
                        vertical
                        style={{ marginTop: -30, marginBottom: -25 }}
                      >
                        <NavItem>
                          <FormGroup>
                            {this.institutes.length > 0 ? (
                              <Select
                                placeholder="Instituição de Formação"
                                style={{ fontSize: "40%" }}
                                value={this.institutes.filter(
                                  (option) =>
                                    option.label === this.state.institute
                                )}
                                onChange={(value) =>
                                  this.ChangeSelect(value.value, "institute")
                                }
                                options={this.institutes}
                              />
                            ) : (
                              ""
                            )}
                          </FormGroup>
                        </NavItem>
                        <NavItem>
                          {this.createCheck(this.state.features)}
                        </NavItem>
                      </Nav>

                      <p /*className="text-uppercase"*/
                        style={{ marginBottom: -20, marginTop: "30px" }}
                      >
                        Áreas de atuação
                      </p>
                      <Nav vertical>
                        <NavItem>
                          {/* {console.log(
                            "this.professionals",
                            this.professionals
                          )}
                          {console.log("this.lifeCoach", this.lifeCoach)}
                          {console.log(
                            "fn",
                            this.sortCreateCheck(
                              this.professionals,
                              this.lifeCoach
                            )
                          )} */}
                          {this.sortCreateCheck(
                            this.professionals,
                            this.lifeCoach
                          )}
                        </NavItem>
                      </Nav>
                      {this.sessions.length > 0 ? (
                        <p style={{ marginTop: -20, marginBottom: -20 }}>
                          Sessões
                        </p>
                      ) : (
                        ""
                      )}
                      <Nav vertical>
                        <NavItem>
                          {this.createCheck(this.sessions, "sessions")}
                        </NavItem>
                      </Nav>
                      <div className="espacoOverflow" />
                    </Form>
                  </div>
                  <div className="show-sm text-center">
                    <Button color="success" onClick={this.handleClickExp}>
                      <FontAwesomeIcon
                        icon={
                          this.state.isToggleOnExp ? faChevronUp : faChevronDown
                        }
                      />{" "}
                      Filtros
                    </Button>
                  </div>
                </Col>

                <Col lg={this.state.isToggleOnExp ? "10" : "12"} sm="12">
                  <div className="header-list-search response-search">
                    <Row style={{ marginTop: "5px" }}>
                      <Col className="text-italic" lg="5" xs="12">
                        {this.handleTextTotalCoaches()}
                      </Col>

                      <Col lg="7" xs="12">
                        <div className="select-group-search">
                          <Select
                            placeholder="10 por página"
                            onChange={(value) =>
                              this.ChangeSelect(value.value, "page_size")
                            }
                            options={this.state.pages}
                            value={
                              this.state.pages[this.state.selectedOptoionIndex]
                            }
                          />
                          <Select
                            placeholder="Classificar por"
                            onChange={(value) =>
                              this.ChangeClassificar(value.value)
                            }
                            options={this.state.classific}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {this.state.coaches.length > 0 ? (
                    this.createList(this.state.coaches)
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        flex: 1,
                      }}
                    >
                      <h3 className="text-center large-text">
                        Carregando coaches
                      </h3>
                      <ReactLoading type="bubbles" color="#B1CD49" />
                    </div>
                  )}
                  <div className="total text-italic">
                    {this.handleTextTotalCoaches()}
                  </div>

                  {this.state.total_item > 1 ? (
                    <div className="text-center">
                      <Pagination
                        hideFirstLastPages
                        prevPageText="<<"
                        nextPageText=">>"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.item_page}
                        totalItemsCount={this.state.total_item}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-center copyright">
                    climby {this.state.currentYear} - Todos os direitos
                    reservados
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {!this.state.modal_video ? null : (
            <div
              className="popup-container"
              onClick={(ev) => this.tryClose(ev)}
            >
              <div ref="modal-content" className="popup-content">
                <div className="video-container">
                  <iframe
                    title={this.state.video_source}
                    id="ytplayer"
                    width="720"
                    height="480"
                    src={`https://www.youtube.com/embed/${this.state.video_source
                      }?ref=0&showinfo=0&enablejsapi=1`}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

Searches.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func,
};

export default Searches;
