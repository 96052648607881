import React from 'react';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';

export default class Step6 extends React.Component {

  valid = false;

  constructor(props) {
    super(props);
    this.state = {
      process_coaching: props.getStore().process_coaching ? 'sim' : 'não'
    };
  }

  saveInput(value) {
    this.valid = true;
    this.setState({ process_coaching: value === 'sim' ? true : false });
    this.props.updateStore({
      process_coaching: value
    });  // Up
  }


  render() {
    return (
      <div className="step-container">
        <h2 className="text-center">Você já participou de um processo de Coaching?</h2>

        <form className="text-center">
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="sim" onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Sim</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="não" onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Não</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(4)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.valid} onClick={() => this.props.jumpToStep(6)} className="text-uppercase btn-prev" color="primary">Finalizar</Button>
        </div>
      </div>
    );
  }
}