import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import homeReducer from './containers/home/reducer';
import ImcoachReducer from './containers/imcoach/reducer';
import assessmentReducer from './containers/assessment/reducer';
import searchesReducer from './containers/searches/reducer';

export default function rootReducer() {
  return combineReducers({
    routing: routerReducer,
    home: homeReducer,
    imcoach: ImcoachReducer,
    searches: searchesReducer,
    assessment: assessmentReducer
  });
}
