import React, { Component } from "react";

import "./checkBox.css";

export default class Checkbox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <label className="container-checkbox">
        <input
          type="checkbox"
          checked={this.props.item.checked}
          onChange={() => {
            if (this.props.onChange) {
              this.props.onChange(this.props.item);
            }
          }}
        />
        <span className="checkbox-checkmark" />
        <div className="containerTextCheckbox">
          {/* <span>{this.props.item.icon} </span> */}

          <span className="textLabel">{this.props.item.label}</span>
        </div>
      </label>
    );
  }
}
