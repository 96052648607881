export const actionType = {
  FETCH_DATA_ASYNC: 'home/FETCH_DATA_ASYNC',
  FETCH_DATA_SUCCESS: 'home/FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'home/FETCH_DATA_FAILURE',
  FETCH_STATES_SUCCESS: 'home/FETCH_STATES_SUCCESS',
  FETCH_STATES_FAILURE: 'home/FETCH_STATES_FAILURE',
};

const initialState = {
  states: [],
  areas: {}
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_DATA_SUCCESS: {
      const { areas } = action.payload.results.data;
      if (areas) {
        return {
          ...state,
          areas,
        };
      }
      return false;
    }

    case actionType.FETCH_STATES_SUCCESS: {
      const { states } = action.payload.results.data;
      return {
        ...state,
        states,
      };
    }

    default:
      return state;
  }
};

export default homeReducer;
