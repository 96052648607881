import React from 'react';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';




export default class Step5 extends React.Component {

  array = [];
  wheel_of_life;

  constructor(props) {
    super(props);

    this.state = {
      msgm_wheel_of_life: props.getStore().msgm_wheel_of_life,
      value: props.getStore().msgm_wheel_of_life
    };

    this.wheel_of_life = this.props.getStore().wheel_of_life;

    for (var [key, val] of Object.entries(this.wheel_of_life)) {
      this.array = [...this.array, { text: key, value: val }]
    }

    this.array = this.array.sort((a, b) => {
      return b.value - a.value
    });

  }

  saveInput(value) {
    console.log(value);
    this.setState({ msgm_wheel_of_life: value, value: value });
    this.props.updateStore({
      msgm_wheel_of_life: value
    });
  }


  translate(text) {
    switch (text) {
      case 'professional':
        return 'realização profissional';
      case 'emocional':
        return 'equilíbrio emocional';
      case 'cheers':
        return 'saúde';
      case 'relationships':
        return 'relacionamentos';
      case 'family':
        return 'família';
      case 'happiness':
        return 'felicidade';
      case 'financial':
        return 'financeiro';
    }
  }

  render() {
    this.array = this.array.filter(res => res.value);
    return (
      <div className="step-container">
        <h2 className="text-center">Cite o porquê esta(s) área(s) {this.array[0] && this.translate(this.array[0].text)}{this.array[1] && `, ${this.translate(this.array[1].text)}`}{this.array[2] && ` e ${this.translate(this.array[2].text)} `} foram identificadas com maior valor. Liste os benefícios que elas trazem para o seu dia a dia.</h2>
        <h4 className="text-center">Não existe certo ou errado. A sua resposta representa o seu sentimento atual.</h4>
        <form>
          <FormGroup className="textarea-content">
            <Input
              placeholder="Digite sua resposta"
              maxLength={'1024'}
              rows="8"
              type="textarea"
              value={this.state.value}
              onChange={(value) => this.saveInput(value.target.value)}
            />
            <p className="text-right">{this.state.value.length}/1024</p>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(3)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button onClick={() => this.props.jumpToStep(5)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}