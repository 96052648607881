import React from "react";
import ButtonAction from "../buttonAction/button_action";
import Checkbox from "../checkBox/checkBox";

import "./selectOptions.css";

export default class SelectOptions extends React.Component {
  constructor(props) {
    super(props);

    this.container_select = React.createRef();
    this.tryClose = this.tryClose.bind(this);
    this.onHandleClose = this.onHandleClose.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSelectChange = this.onHandleSelectChange.bind(this);
    this.state = {
      isOpenOptions: false,
      dataSource: [],
      originalDataSource: [],
      valueInput: "",
    };
  }

  onHandleChange(event) {
    this.setState({
      valueInput: event.target.value,
    });
  }

  onHandleClose() {
    this.setState((state) => ({
      isOpenOptions: !state.isOpenOptions,
    }));
  }

  componentDidMount() {
    document.body.addEventListener("click", this.tryClose);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.tryClose);
  }

  objectsEqual(o1, o2) {
    return (
      Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p])
    );
  }

  arraysEqual(a1, a2) {
    return (
      a1.length === a2.length &&
      a1.every((o, idx) => this.objectsEqual(o, a2[idx]))
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.originalDataSource.length === 0 &&
      prevProps.dataSource.length > 0
    ) {
      this.setState({
        originalDataSource: prevProps.dataSource,
        dataSource: prevProps.dataSource,
      });
    }

    if (
      prevState.originalDataSource.length > 0 &&
      !this.arraysEqual(prevState.originalDataSource, this.props.dataSource)
    ) {
      this.setState({
        originalDataSource: this.props.dataSource,
        dataSource: this.props.dataSource,
      });
    }

    if (
      prevState.valueInput !== this.state.valueInput &&
      this.state.valueInput.trim().length > 0
    ) {
      const arrayReturn = [];
      this.state.originalDataSource.find((el) => {
        if (
          el.label.toUpperCase().includes(this.state.valueInput.toUpperCase())
        ) {
          arrayReturn.push(el);
        }
      });

      this.setState({ dataSource: arrayReturn });
    } else if (
      prevState.valueInput !== this.state.valueInput &&
      this.state.valueInput.trim().length === 0
    ) {
      this.setState((state) => ({
        dataSource: state.originalDataSource,
      }));
    }
  }

  onHandleSelectChange(item) {
    if (this.props.onChange) {
      this.props.onChange(item);

      if (this.props.singleSelection && this.props.executeAction) {
        this.props.onClick();
        this.onHandleClose();
        this.setState({ valueInput: "" });
      } else if (this.props.singleSelection && !this.props.executeAction) {
        this.onHandleClose();
        this.setState({ valueInput: "" });
      }
    }
  }

  tryClose(ev) {
    if (
      this.container_select &&
      this.container_select.current &&
      !this.container_select.current.contains(ev.srcElement)
    ) {
      this.setState({ isOpenOptions: false });
    }
  }

  render() {
    return (
      <>
        < div
          ref={this.container_select}
          id={this.props.id}
          className={
            this.state.isOpenOptions
              ? "container-select-options-base container-select-options-active"
              : "container-select-options-base container-select-options-disabled "
          }
        >

          <div
            className="container-select"
            onClick={() => {
              this.onHandleClose();
            }}
          >
            {this.props.loading && <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
            <span className="select-placeholder">{this.props.placeholder} </span>
          </div>

          {
            this.state.isOpenOptions && (
              <div className="container-options">
                <span className="division" />
                {this.state.originalDataSource &&
                  this.state.originalDataSource.length > 0 && (
                    <div className="containerInputSearch">
                      <input
                        placeholder="Pesquisar ..."
                        className="inputSearchSelect"
                        type="search"
                        value={this.state.valueInput}
                        onChange={this.onHandleChange}
                      />
                    </div>
                  )}

                {this.state.dataSource &&
                  this.state.dataSource.length > 0 &&
                  this.state.dataSource.map((item, idx) => {
                    return (
                      <Checkbox
                        key={idx}
                        item={item}
                        onChange={this.onHandleSelectChange}
                      />
                    );
                  })}

                {!this.props.singleSelection && (
                  <div className="footerSelectOptions">
                    <ButtonAction
                      onClick={() => {
                        if (this.props.onClick) {
                          this.props.onClick();
                          this.onHandleClose();
                        }
                      }}
                    >
                      Exibir
                    </ButtonAction>

                    <span
                      className="remove-filters"
                      onClick={() => {
                        if (this.props.removeAll) {
                          this.props.removeAll();
                        }
                        this.onHandleClose();
                      }}
                    >
                      Remover filtros
                    </span>
                  </div>
                )}
              </div>
            )
          }
        </div >
      </>
    );
  }
}
