import React from 'react';
import Input from 'reactstrap/lib/Input';
import InputMask from 'react-input-mask';
import { Button } from 'reactstrap';


export default class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: props.getStore().tasks
    };
  }


  saveInput(value, type) {
    const tasks = this.state.tasks;
    tasks[type].urgency = value;
    this.setState({ tasks: tasks });
    this.props.updateStore({ tasks: tasks });
  }

  validation(value) {
    if (value && value > 10) {
      return 10;
    } else if (!value) {
      return 0;
    } else {
      return Number(value);
    }
  }


  render() {
    return (
      <div id="step4" className="step-container">
        <h2 className="text-center">Agora categorize conforme o grau de importancia utilizando a seguinte referencia:</h2>
        <h4 className="text-center">GI (Grande impacto e geram coisas positivas na sua vida, MI (Medio Impacto, ou seja, se nao forem realizadas nao irao impactar no seu objetivo), BI (Baixo impacto, ou seja, tenho que fazer mas nao é fundamental) , D (Delegáveis, ou seja, outra pessoa poderia fazer), E (Elimináveis, ou seja, podem ser deixadas de lado)</h4>
        <form>
          <div className="form-group group-input text-center no-margin">
            {
              this.state.tasks[0].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[0].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask disabled={true} defaultValue={this.state.tasks[0].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} />
                  <Input type="select" className="form-smallsize" name="select" onChange={(value) => { this.saveInput(value.target.value, 0) }}>
                    <option value="">Selecione uma categoria</option>
                    <option value="GI">GI</option>
                    <option value="MI">MI</option>
                    <option value="BI">BI</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </Input>
                </div>
                : ''
            }
            {
              this.state.tasks[1].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[1].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask disabled={true} defaultValue={this.state.tasks[1].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} />
                  <Input type="select" className="form-smallsize" name="select" onChange={(value) => { this.saveInput(value.target.value, 1) }}>
                    <option value="">Selecione uma categoria</option>
                    <option value="GI">GI</option>
                    <option value="MI">MI</option>
                    <option value="BI">BI</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </Input>
                </div>
                : ''
            }
            {
              this.state.tasks[2].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[2].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask disabled={true} defaultValue={this.state.tasks[2].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} />
                  <Input type="select" className="form-smallsize" name="select" onChange={(value) => { this.saveInput(value.target.value, 2) }}>
                    <option value="">Selecione uma categoria</option>
                    <option value="GI">GI</option>
                    <option value="MI">MI</option>
                    <option value="BI">BI</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </Input>
                </div>
                : ''
            }
            {
              this.state.tasks[3].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[3].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask disabled={true} defaultValue={this.state.tasks[3].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} />
                  <Input type="select" className="form-smallsize" name="select" onChange={(value) => { this.saveInput(value.target.value, 3) }}>
                    <option value="">Selecione uma categoria</option>
                    <option value="GI">GI</option>
                    <option value="MI">MI</option>
                    <option value="BI">BI</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </Input>
                </div>
                : ''
            }
            {
              this.state.tasks[4].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[4].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask disabled={true} defaultValue={this.state.tasks[4].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} />
                  <Input type="select" className="form-smallsize" name="select" onChange={(value) => { this.saveInput(value.target.value, 4) }}>
                    <option value="">Selecione uma categoria</option>
                    <option value="GI">GI</option>
                    <option value="MI">MI</option>
                    <option value="BI">BI</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </Input>
                </div>
                : ''
            }
          </div>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(2)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button onClick={() => this.props.jumpToStep(4)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}