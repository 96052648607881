import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fabStar } from "@fortawesome/free-solid-svg-icons";

import ImageAvatar from "./../../components/imageAvatar";
import DiamondIcon from "../../images/icons-images/svg/diamond.svg";

import { TEXT_LABELS } from "../../containers/engage/constants";
import ButtonAction from "../buttonAction/button_action";
import FooterEngage from "./components/footerEngage/footer-engage";
import TypeSessions from "./components/typeSessions/type-sessions";

import "./card_engage.css";
import ButtonMedias from "../buttons/button_medias";
import HeadsetV2 from "../../images/icons-images/HeadsetV2.svg";
import PeopleV2 from "../../images/icons-images/PeopleV2.svg";
import VerifyClimby from "../../images/icons-images/verifyClimby.svg";

import ReadMoreReact from "read-more-react";

//services
import CoacheService from "../../services/coacheService";

export default class CardEngage extends Component {
  constructor(props, context) {
    super(props, context);

    this.refExperiences = React.createRef();
    this.refMethodologies = React.createRef();
    this.refContact = React.createRef();

    this.state = {
      isExperience: false,
      isMethodologies: false,
      isContact: false,
    };

    this.onHandleClickExperiences = this.onHandleClickExperiences.bind(this);
    this.onHandleClickMethodologies = this.onHandleClickMethodologies.bind(
      this
    );
    this.onHandleClickContacts = this.onHandleClickContacts.bind(this);
    this.service = new CoacheService();
  }

  componentImageCoach(image, engage_name, curation) {
    return (
      <div className="card-engage-presentation-image">
        {curation && curation.status === "Aprovado" && (
          <div className="tooltipCard">
            <img className="card-verify-climby" src={VerifyClimby} />
            <span class="tooltiptext">
              Certificações verificadas pela curadoria da climby.
            </span>
          </div>
        )}
        <figure>
          <ImageAvatar coach={{ image, engage_name }} />
        </figure>
      </div>
    );
  }

  componentValuesSessionsCoach(preco, sessions, badgesICF, _id) {
    return (
      <>
        {badgesICF && badgesICF.length > 0 && (
          <>
            <div className="card-engage-personal-seal-badges">
              {badgesICF.map((badge, idx) => {
                if (badge.badgesSrc !== "") {
                  return (
                    <img
                      key={idx}
                      src={badge.badgesSrc}
                      height={"80px"}
                      width={"80px"}
                    />
                  );
                }
              })}
            </div>
          </>
        )}
        <div className="card-engage-personal-seal">
          <span
            className="myCertifications"
            onClick={() =>
              this.onHandleClickExperiences(_id, this.state.isExperience)
            }
          >
            Minhas certificações
          </span>
        </div>

        <div className="card-engage-values">
          <strong>Valores:</strong> <span>{preco}</span>
        </div>
        <div className="card-engage-sections">
          <span>Sessões:</span>

          <div className="card-engage-sections-types">
            <TypeSessions
              isActive={sessions.presential}
              text="Presencial"
              image={PeopleV2}
            />

            <TypeSessions
              isActive={sessions.online}
              text="Online"
              image={HeadsetV2}
            />
          </div>
        </div>
      </>
    );
  }

  onButtonMediaActive(video, apresentation) {
    return (
      (apresentation &&
        apresentation !== "" &&
        apresentation.doc_format !== "") ||
      (video && video !== "")
    );
  }

  onHandleClickExperiences(idCoach, isActive) {
    this.setState((state) => ({
      isExperience: !state.isExperience,
      isMethodologies: false,
      isContact: false,
    }));
    if (!isActive) {
      this.handleClickBlock("experiences", idCoach);
    }
  }

  onHandleClickMethodologies(idCoach, isActive) {
    this.setState((state) => ({
      isMethodologies: !state.isMethodologies,
      isExperience: false,
      isContact: false,
    }));
    if (!isActive) {
      this.handleClickBlock("methodologies", idCoach);
    }
  }

  onHandleClickContacts(idCoach, isActive) {
    this.setState((state) => ({
      isContact: !state.isContact,
      isMethodologies: false,
      isExperience: false,
    }));
    if (!isActive) {
      this.handleClickBlock("contact", idCoach);
    }
  }

  handleClickBlock(index, idcoach) {
    const data = {
      click_at: index === "contact" ? "contact" : "profile",
      coach_id: idcoach,
    };
    this.service
      .postTrack(data)
      .then((res) => {
        if (index === this.state.typeButton) {
          this.setState({ typeButton: "" });
        } else {
          this.setState({ typeButton: index });
        }
      })
      .catch((err) => {
        console.error("error ", err);
      });
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "//cdn.credly.com/assets/utilities/embed.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    const {
      _id,
      image,
      engage_name,
      country_flag,
      country,
      preco,
      sessions,
      city,
      state,
      areas,
      description,
      experience,
      methodology,
      video,
      slides,
      premium,
      certified,
      curation,
    } = this.props.coach;
    const apresentation = JSON.parse(slides);

    return (
      <div className="container-card-engage">
        <div className="card-engage-presentation-specifications ">
          <div className="card-engage-presentation">
            <div className="card-image-coach-desktop">
              {this.componentImageCoach(image, engage_name, curation)}
            </div>
            <div className="card-engage-presentation-personal-data">
              <div className="card-engage-header-info-coach">
                <span className="card-engage-presentation-name-country">
                  <div className="card-engage-presentation-name">
                    {engage_name}
                  </div>
                  &nbsp;
                  {experience.showRate ? (
                    <div>
                      {[1, 2, 3, 4, 5].map((res, index) => (
                        <FontAwesomeIcon
                          key={index}
                          color={"#A6CE39"}
                          size="1x"
                          icon={experience.rate < res ? faStar : fabStar}
                        />
                      ))}
                    </div>
                  ) : null}
                </span>
                <div className="card-engage-presentation-country">
                  <span>
                    {city} - {state}
                  </span>
                  <img width="30" src={country_flag} alt={country} />
                </div>
              </div>

              <span className="card-engage-presentation-occupation-area">
                {areas &&
                  areas.map((item, idx) => (
                    <span
                      key={idx}
                      className="card-engage-presentation-occupation-area-item"
                    >
                      {item}
                    </span>
                  ))}
              </span>
              <span className="card-engage-presentation-biography">
                <ReadMoreReact
                  key={description}
                  text={
                    description ? description : "Sem Descrição" // className="text display-text-group"
                  }
                  min={210}
                  ideal={240}
                  max={300}
                  readMoreText="...leia mais"
                />
              </span>
              <div className="card-values-sessions-coach-tablet-mobile">
                {this.componentValuesSessionsCoach(
                  preco,
                  sessions,
                  experience.badgesICF,
                  _id
                )}
              </div>
            </div>
          </div>
          <div className="card-engage-specifications">
            {premium && (
              <div className="card-engage-coach-seal">
                <img src={DiamondIcon} height={15} />
                <span className="card-engage-coach-seal-text">
                  {TEXT_LABELS.coach_premium}
                </span>
              </div>
            )}
            <div className="card-image-coach-tablet-mobile">
              {this.componentImageCoach(image, engage_name, curation)}
            </div>
            <div
              style={{ borderRadius: !premium ? "0px 25px 0px 0px" : "0px" }}
              className={
                this.onButtonMediaActive(video, apresentation) && premium
                  ? "card-engage-media"
                  : "card-engage-media card-engage-media-disabled"
              }
            >
              <ButtonMedias
                params={{
                  video: video,
                  apresentacao: apresentation,
                  premium: premium,
                }}
              />
            </div>
            <div className="card-values-sessions-coach-desktop">
              {this.componentValuesSessionsCoach(
                preco,
                sessions,
                experience.badgesICF,
                _id
              )}
            </div>
          </div>
        </div>
        <div className="card-engage-selection">
          <ButtonAction
            isActive={this.state.isExperience}
            onClick={() => {
              this.onHandleClickExperiences(_id, this.state.isExperience);
              const element = document.getElementById("footerExperiences");
            }}
          >
            Experiências
          </ButtonAction>

          <ButtonAction
            isActive={this.state.isMethodologies}
            onClick={() =>
              this.onHandleClickMethodologies(_id, this.state.isMethodologies)
            }
          >
            Metodologias
          </ButtonAction>

          <ButtonAction
            isActive={this.state.isContact}
            onClick={() =>
              this.onHandleClickContacts(_id, this.state.isContact)
            }
          >
            Contato
          </ButtonAction>
        </div>
        <div>
          <FooterEngage
            isActive={this.state.isExperience}
            description={experience.description}
            hasFormation={
              experience.formation && experience.formation.length > 0
            }
            formation={experience.formation}
          />
          <FooterEngage
            ref={this.refMethodologies}
            isActive={this.state.isMethodologies}
            description={methodology}
          />
          <FooterEngage
            ref={this.refContact}
            isActive={this.state.isContact}
            isContact={true}
            idEngage={_id}
          />
        </div>
      </div>
    );
  }
}
