import React from 'react';
import Input from 'reactstrap/lib/Input';
import { Alert, Button } from 'reactstrap';


export default class Step1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: props.getStore().name
    };
  }


  saveInput(value) {
    this.setState({ name: value });
    this.props.updateStore({
      name: value
    });  // Up
  }

  render() {
    let msgm_error;
    return (
      <div id="step1" className="step-container">
        <h2 className="text-center margin">Vamos iniciar sua avaliação de autoconhecimento!</h2>

        {this.state.name.length < 3 && this.state.name.length > 0 && <Alert color="danger">Digite um nome válido</Alert>}


        <form>
          <div className="form-group text-center">
            <label className="big-label">Como você gostaria de ser chamado?</label>
            <Input defaultValue={this.state.name} ref="name" required className="form-bigsize" placeholder="Digite seu nome ou apelido" onChange={(value) => { this.saveInput(value.target.value) }} />
          </div>
        </form>

        <div className="footer-buttons text-center">
          <Button disabled={this.state.name.length < 3 ? true : false} onClick={() => this.props.jumpToStep(1)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}