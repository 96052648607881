export const actionType = {
  FETCH_DATA_ASYNC: 'home/FETCH_DATA_ASYNC',
  FETCH_DATA_SUCCESS: 'home/FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'home/FETCH_DATA_FAILURE'
};

const initialState = {
  states: {},
  areas: {},
  institutes: {}
};

const searchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_DATA_SUCCESS: {
      const { states } = action.payload.results.data;
      return {
        ...state,
        states
      };
    }

    default:
      return state;
  }
};

export default searchesReducer;
