class SearchService {
  async service(method, url, data) {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body:
        method === "POST"
          ? data != null
            ? this.parsePayload(data)
            : []
          : null,
    });
  }

  async statesForeign(countryName) {
    const result = await this.service(
      "GET",
      `${process.env.REACT_APP_URL_BASE}countries/${countryName}/states`
    );
    return await result.json();
  }
}

export default SearchService;