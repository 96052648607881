import React from 'react';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import { Link } from 'react-router-dom';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';

export default class Step5 extends React.Component {

  valid = false;
  constructor(props) {
    super(props);
    this.state = {
      email: props.getStore().email,
      newsletter: props.getStore().newsletter
    };
  }


  saveInput(value) {
    this.setState({ email: value });
    this.props.updateStore({
      email: value
    });  // Up
  }

  saveInputCheckbox(value) {
    console.log(value);
    this.setState({ newsletter: value });
    this.props.updateStore({
      newsletter: value
    });  // Up
  }


  validation(e) {
    if (e.validity.valid) {
      this.saveInput(e.value);
    }
    this.valid = e.validity.valid;
  }

  render() {
    return (
      <div id="step5" className="step-container">

        <form>
          <FormGroup>
            <Label className="big-label" for="email">Pronto, {this.props.getStore().name}. Estamos quase terminando.</Label>
            <Input className="form-bigsize" type="email" defaultChecked={this.state.email} id="email" placeholder="Digite seu email" onChange={(value) => { this.validation(value.target) }} />
          </FormGroup>
          <FormGroup className="checkbox-small checkbox-default">
            <Input id="coach" type="checkbox" required onChange={(value) => this.saveInputCheckbox(value.target.checked)} defaultChecked={this.state.newsletter} />
            <Label for="coach">Quero me manter atualizado sobre o mundo do Coaching</Label>
            <span className="checkmark"></span>
          </FormGroup>
          <p className="text-center restrict">Ao clicar em avançar, declaro que estou de acordo com a <a
            href={`${process.env.REACT_APP_URL_WORDPRESS}politica-de-privacidade/`}
            target="_blank">Política de privacidade</a></p>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(3)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.valid} onClick={() => this.props.jumpToStep(5)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}