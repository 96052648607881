export const actionType = {
  FETCH_DATA_ASYNC: "home/FETCH_DATA_ASYNC",
  FETCH_DATA_SUCCESS: "home/FETCH_DATA_SUCCESS",
  FETCH_DATA_FAILURE: "home/FETCH_DATA_FAILURE",
  FETCH_DATA_AREAS_SUCCESS: "home/FETCH_DATA_AREAS_SUCCESS",
  FETCH_DATA_AREAS_FAILURE: "home/FETCH_DATA_AREAS_FAILURE",
  FETCH_DATA_INSTITUTES_SUCCESS: "home/FETCH_DATA_INSTITUTES_SUCCESS",
  FETCH_DATA_INSTITUTES_FAILURE: "home/FETCH_DATA_INSTITUTES_FAILURE",
  FETCH_DATA_COUNTRIES_SUCCESS: "home/FETCH_DATA_COUNTRIES_SUCCESS",
  FETCH_DATA_COUNTRIES_FAILURE: "home/FETCH_DATA_COUNTRIES_FAILURE",
};

const initialState = {
  states: {},
  areas: {},
  institutes: {},
};

const searchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_DATA_SUCCESS: {
      const { states } = action.payload.results.data;
      return {
        ...state,
        states,
      };
    }

    case actionType.FETCH_DATA_AREAS_SUCCESS: {
      const { areas } = action.payload.results.data;
      return {
        ...state,
        areas,
      };
    }

    case actionType.FETCH_DATA_INSTITUTES_SUCCESS: {
      const { institutes } = action.payload.results.data;
      return {
        ...state,
        institutes,
      };
    }

    case actionType.FETCH_DATA_COUNTRIES_SUCCESS: {
      const { countries } = action.payload.results.data;
      return {
        ...state,
        countries,
      };
    }

    default:
      return state;
  }
};

export default searchesReducer;
