import React, { Component } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import "./buttons_styles.css"
//import "./button_search.css";
import { BUTTONS_LABELS } from "../../containers/engage/constants";

library.add(faPlay, faFileLines);
let timer = 0;

export default class ButtonMedias extends Component {
  constructor(props) {
    super(props);

    this.state = { video_source: "", player: null, modal_video: false };

    this.openApresentation = this.openApresentation.bind(this);
    this.professionalPresent = this.professionalPresent.bind(this);
    this.resolveURL = this.resolveURL.bind(this);
    this.openVideo = this.openVideo.bind(this);
  }

  async openApresentation(apresentation) {
    try {
      if (apresentation) {
        const doc = apresentation.doc_source;
        if (doc !== "") {
          try {
            const result = await this.professionalPresent(doc);
            if (result.status === 200) {
              window.open(this.resolveURL(doc, process.env.REACT_APP_URL_BASE));
            } else {
              alert("Apresentação indisponível no momento.");
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    } catch (eel) {
      console.error(eel);
    }
  }

  async professionalPresent(doc) {
    try {
      //ao tentar obter uma resposta da URL de um arquivo no S3, ele estava dando erro de CORS, por isso fiz desta maneira, por enquanto.
      const isS3File = doc.includes("s3.amazonaws");
      if (isS3File) {
        return { status: 200 };
      }
      const presentation = this.resolveURL(doc, process.env.REACT_APP_URL_BASE);
      let response = await fetch(presentation, {
        method: "GET",
      });
      return response;
    } catch (error) {
      console.error("professionalPresent", error);
    }
  }

  resolveURL(path, basePath) {
    return new URL(path, basePath).href;
  }

  onActiveButtonApresentation(apresentation) {
    return (
      apresentation && apresentation !== "" && apresentation.doc_format !== ""
    );
  }

  onActiveButtonVideo(video) {
    return video && video !== "";
  }

  openVideo(video) {
    this.openModalVideo(this.parseYoutubeID(video));
  }

  async openModalVideo(video_source) {
    this.setState({ video_source });
    this.toggle("modal_video");
    let YT = window["YT"];
    let player = new YT.Player("ytplayer", {
      events: {
        onStateChange: this.ShowMe.bind(this),
      },
    });
    this.setState({ player });
  }

  parseYoutubeID(value) {
    if (value.indexOf("?v=") !== -1) {
      let _begin = value.indexOf("?v=") + 3;
      let _end = value.indexOf("?v=") + 3 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf("youtu.be/") !== -1) {
      let _begin = value.indexOf("youtu.be/") + 9;
      let _end = value.indexOf("youtu.be/") + 9 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf("embed/") !== -1) {
      let _begin = value.indexOf("embed/") + 6;
      let _end = value.indexOf("embed/") + 6 + 11;
      return value.substring(_begin, _end);
    }
    return false;
  }

  toggle(item) {
    this.setState({ [item]: !this.state[item] });
  }

  ShowMe() {
    let sStatus = this.state.player.getPlayerState();
    if (sStatus == -1) {
      timer = setInterval(() => {
        this.setState({ player: null });
        this.toggle("modal_video");
        clearInterval(timer);
      }, 500);
    } else if (sStatus == 0) {
    } else if (sStatus == 1) {
      clearInterval(timer);
    } //Video is playing
    else if (sStatus == 2) {
    } //Video is paused
    else if (sStatus == 3) {
      clearInterval(timer);
    } //video is buffering
    else if (sStatus == 5) {
    } //Video is cued.
  }

  tryClose(ev) {
    if (
      this.refs["modal-content"] &&
      !this.refs["modal-content"].contains(ev.target)
    ) {
      this.setState({ player: null });
      this.toggle("modal_video");
    }
  }

  render() {
    const { video, apresentacao, premium } = this.props.params;

    return (
      <div className="engage-container-button-media">
        <ButtonGroup>
          <Button
            title={
              this.onActiveButtonVideo(video)
                ? "Veja o vídeo"
                : "Vídeo não disponível"
            }
            className={
              this.onActiveButtonVideo(video) && premium
                ? "engage-button-media"
                : "engage-button-media engage-button-media-disabled"
            }
            onClick={() => { this.openVideo(video) }}
            disabled={!this.onActiveButtonVideo(video) || !premium}
          >
            <FontAwesomeIcon
              icon={faPlay}
              color="#FFFFFF"
              size="1x"
              style={{ marginRight: "7px" }}
            />
            {BUTTONS_LABELS.video}
          </Button>

          <Button
            title={
              this.onActiveButtonApresentation(apresentacao)
                ? "Veja a apresentação"
                : "Apresentação não disponível"
            }
            className={
              this.onActiveButtonApresentation(apresentacao) && premium
                ? "engage-button-media"
                : "engage-button-media engage-button-media-disabled"
            }
            onClick={() => this.openApresentation(apresentacao)}
            disabled={!this.onActiveButtonApresentation(apresentacao) || !premium}
          >
            <FontAwesomeIcon
              icon={faFileLines}
              color="#FFFFFF"
              size="1x"
              style={{ marginRight: "7px" }}
            />
            {BUTTONS_LABELS.presentation}
          </Button>
        </ButtonGroup>

        {!this.state.modal_video ? null : (
          <div
            className="popup-container"
            onClick={(ev) => this.tryClose(ev)}
          >
            <div ref="modal-content" className="popup-content">
              <div className="video-container">
                <iframe
                  title={this.state.video_source}
                  id="ytplayer"
                  width="720"
                  height="480"
                  src={`https://www.youtube.com/embed/${this.state.video_source
                    }?ref=0&showinfo=0&enablejsapi=1`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
