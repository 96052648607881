import React, { Component } from "react";
import PropTypes from "prop-types";
import { Doughnut, Radar } from "react-chartjs-2";
import { Link } from "react-router-dom";

import { Container, Button, Row, Col } from "reactstrap";
import StepZilla from "react-stepzilla";

// Layouts
import Menu from "../../templates/menu/menu";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";

// Image
import GraphicImage from "../../images/graphic.jpg";
import Heart from "../../images/icons-images/svg/heart.svg";
import Handbag from "../../images/icons-images/svg/handbag.svg";
import Temp from "../../images/image-temp.svg";

class AdministrationTemp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finish: false,
      graphic: false,
      tasks: {
        GI: [],
        MI: [],
        BI: [],
        D: [],
        E: [],
      },
    };

    this.formWheelOfLife = {
      name: "",
      email: "",
      process_coaching: false,
      newsletter: false,
      tasks: [
        {
          item: "",
          timer: "",
          urgency: "",
        },
        {
          item: "",
          timer: "",
          urgency: "",
        },
        {
          item: "",
          timer: "",
          urgency: "",
        },
        {
          item: "",
          timer: "",
          urgency: "",
        },
        {
          item: "",
          timer: "",
          urgency: "",
        },
      ],
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;
    window.scrollTo(0, 0);
    // fetchData();
  }

  getStore() {
    return this.formWheelOfLife;
  }

  updateStore(update) {
    this.formWheelOfLife = {
      ...this.formWheelOfLife,
      ...update,
    };
    // console.log(this.formWheelOfLife);
  }

  async finish(e) {
    if (e === 6) {
      this.setState({ finish: true });
      await this.setState({
        tasks: {
          GI: this.verifyItem(this.getStore().tasks, "GI"),
          MI: this.verifyItem(this.getStore().tasks, "MI"),
          BI: this.verifyItem(this.getStore().tasks, "BI"),
          D: this.verifyItem(this.getStore().tasks, "D"),
          E: this.verifyItem(this.getStore().tasks, "E"),
        },
      });
      this.saveResponse();
    }
  }

  translate(number) {
    switch (number) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      case 5:
        return "five";
      case 6:
        return "six";
      case 7:
        return "serven";
      case 8:
        return "eight";
      case 9:
        return "nine";
      case 10:
        return "ten";
      default:
        return "";
    }
  }

  verifyItem(array, type) {
    return array.filter((res) => res.urgency === type);
  }

  sumeTimer(array) {
    let timer = 0;
    for (let i = 0; i < array.length; i++) {
      timer += Number(array[i].timer.split(":").join("")) / 100;
    }
    return timer.toFixed(2);
  }

  createList = (array) => {
    let items = [];
    for (let i = 0; i < array.length; i++) {
      items.push(
        <li>
          {array[i].item} | tempo: {array[i].timer} | tipo: {array[i].urgency}
        </li>
      );
    }
    return items;
  };

  createListMail = (array) => {
    let items = "";
    for (let i = 0; i < array.length; i++) {
      items += `<li>${array[i].item} | tempo: ${array[i].timer} | tipo: ${
        array[i].urgency
      }</li>`;
    }
    return items;
  };

  async saveResponse() {
    let _payload = {
      name: this.getStore().name,
      email: this.getStore().email,
      tasks: JSON.stringify(this.state.tasks),
      text: this.text2Save(),
    };

    console.log(_payload);

    this.service(
      "POST",
      `${process.env.REACT_APP_URL_BASE}time-administration`,
      _payload
    );
  }

  text2Save() {
    return `<div>
              <h2 className="text-uppercase">Resultado</h2>
              <p> ${
                this.getStore().name
              } este Self Assessment é apenas uma das possibilidades para colaborar com a administração e organização so seu tempo. A importancia de cada tarefa e sua respectiva categorizacao deve ser realizada por ${
      this.getStore().name
    } pois o que se busca é o aprendizado constante de sua propria organizacao diária e com isso encontre as possíveis soluções em qualquer situação futura. Este é o grande objetivo do processo de Coaching. Em nenhum momento este teste sofreu qualquer avaliação profissional</p>
              ${
                this.state.tasks.D.length > 0
                  ? `<div>
                    <p>De acordo com o apresentado avalie a possibilidade de delegar as tarefas abaixo para alguém do seu ciclo para que voce consiga economizar <strong>${this.sumeTimer(
                      this.state.tasks.D
                    )} minutos</strong></p>
                    <ol className="list-task">
                      ${this.createListMail(this.state.tasks.D)}
                    </ol>
                  </div>`
                  : ""
              } 
              ${
                this.state.tasks.E.length > 0
                  ? `<div>
                    <p>De acordo com o apresentado avalie a possibilidade de eliminar (isso mesmo deixar de fazer) as tarefas abaixo, uma vez que elas apenas consomem seu tempo para que voce consiga economizar <strong>${this.sumeTimer(
                      this.state.tasks.E
                    )} minutos</strong></p>
                    <ol className="list-task">
                      ${this.createListMail(this.state.tasks.E)}
                    </ol>
                  </div>`
                  : ""
              }
              <p>Para as atividades baixo categorizadas como Medio Impacto e Baixo Impacto, avalie a possibilidade de reclassificá-la como Delegável, Eliminável ou até mesmo realizá-las alguns dias da semana ou horários do dia com o objetivo de economizar alguns minutos destas atividades. Seja justo com voce mesmo nesta avaliacao!</p>
              ${
                this.state.tasks.MI.length > 0 || this.state.tasks.BI.length > 0
                  ? `<div>
                    <ol className="list-task">
                      ${this.createListMail(this.state.tasks.MI)}
                      ${this.createListMail(this.state.tasks.BI)}
                    </ol>
                  </div>`
                  : ""
              }
            </div>`;
  }

  service(method, url, data) {
    fetch(url, {
      method: method,
      headers: {
        // 'Accept': 'application/json',
        "Content-Type": "application/json; charset=UTF-8",
      },
      body:
        method === "POST" ? (data != null ? JSON.stringify(data) : []) : null,
    });
  }

  parsePayload(_payload) {
    return Object.keys(_payload)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          _payload[key]
        )}`;
      })
      .join("&");
  }

  render() {
    const steps = [
      {
        name: "",
        component: (
          <Step1
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step2
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step3
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step4
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step5
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step6
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
    ];

    return (
      <>
        <div
          id="home"
          className="home-container boxShadowSearche"
          data-test-id="home-container"
        >
          <header id="header" className="headerSearche">
            <Menu objetivos={true} />
          </header>
        </div>
        <div id="Assessment" className="assessment-container">
          <div id="body-main">
            <h2 className="title">Administração do Tempo</h2>
            <Container>
              {!this.state.finish ? (
                <div className="step-progress">
                  <StepZilla
                    steps={steps}
                    showNavigation={false}
                    preventEnterSubmission={false}
                    onStepChange={(step) => this.finish(step)}
                  />
                </div>
              ) : !this.state.graphic ? (
                <div id="preResponse" className="text-center">
                  <p>
                    Obrigado por participar! O resultado da Administração do
                    Tempo foi enviado para seu e-mail. Aproveite a oportunidade
                    e encontre um Coach que o apoiará com ferramentas e
                    metodologias durante a sua jornada de desenvolvimento.
                  </p>
                  <img src={GraphicImage} />
                  <p>
                    Muito bem! <br /> Você tem grandes chances de atingir o seu
                    objetivo.
                  </p>
                  <Button
                    color="default"
                    onClick={() => this.setState({ graphic: true })}
                  >
                    VER O RESULTADO DE MINHA AVALIAÇÃO
                  </Button>
                </div>
              ) : (
                <div id="response">
                  <Row>
                    <Col lg={{ size: 5 }} md={{ size: 12 }} xs={{ size: 12 }}>
                      <h3>Administração do Tempo</h3>
                      <img src={Temp} className="image-temp" />
                      <h3>Pontos de Impacto</h3>
                      <div className="buttons-groups">
                        <span>
                          <img src={Handbag} /> Profissional
                        </span>
                        <a
                          href={`${
                            process.env.REACT_APP_URL_ENGAGE
                          }?area=profissional`}
                          className="btn btn-default text-uppercase"
                        >
                          encontre um coach
                        </a>
                      </div>
                      <div className="buttons-groups">
                        <span>
                          <img src={Heart} /> Vida
                        </span>
                        <a
                          href={`${process.env.REACT_APP_URL_ENGAGE}?area=vida`}
                          className="btn btn-default text-uppercase"
                        >
                          encontre um coach
                        </a>
                      </div>
                    </Col>
                    <Col lg={{ size: 7 }} md={{ size: 6 }} xs={{ size: 12 }}>
                      <h2 className="text-uppercase">Resultado</h2>
                      <p>
                        {" "}
                        {this.getStore().name} este Self Assessment é apenas uma
                        das possibilidades para colaborar com a administração e
                        organizacao so seu tempo. A importancia de cada tarefa e
                        sua respectiva categorizacao deve ser realizada por{" "}
                        {this.getStore().name} pois o que se busca é o
                        aprendizado constante de sua propria organizacao diária
                        e com isso encontre as possíveis soluções em qualquer
                        situação futura. Este é o grande objetivo do processo de
                        Coaching. Em nenhum momento este teste sofreu qualquer
                        avaliação profissional
                      </p>
                      {this.state.tasks.D.length > 0 ? (
                        <div>
                          <p>
                            De acordo com o apresentado avalie a possibilidade
                            de delegar as tarefas abaixo para alguém do seu
                            ciclo para que voce consiga economizar{" "}
                            <strong>
                              {this.sumeTimer(this.state.tasks.D)} minutos
                            </strong>
                          </p>
                          <ol className="list-task">
                            {this.createList(this.state.tasks.D)}
                          </ol>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.tasks.E.length > 0 ? (
                        <div>
                          <p>
                            De acordo com o apresentado avalie a possibilidade
                            de eliminar (isso mesmo deixar de fazer) as tarefas
                            abaixo, uma vez que elas apenas consomem seu tempo
                            para que voce consiga economizar{" "}
                            <strong>
                              {this.sumeTimer(this.state.tasks.E)} minutos
                            </strong>
                          </p>
                          <ol className="list-task">
                            {this.createList(this.state.tasks.E)}
                          </ol>
                        </div>
                      ) : (
                        ""
                      )}
                      <p>
                        Para as atividades baixo categorizadas como Medio
                        Impacto e Baixo Impacto, avalie a possibilidade de
                        reclassificá-la como Delegável, Eliminável ou até mesmo
                        realizá-las alguns dias da semana ou horários do dia com
                        o objetivo de economizar alguns minutos destas
                        atividades. Seja justo com voce mesmo nesta avaliacao!
                      </p>
                      {this.state.tasks.MI.length > 0 ||
                      this.state.tasks.BI.length > 0 ? (
                        <div>
                          <ol className="list-task">
                            {this.createList(this.state.tasks.MI)}
                            {this.createList(this.state.tasks.BI)}
                          </ol>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </div>
        </div>
      </>
    );
  }
}

AdministrationTemp.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func,
};

export default AdministrationTemp;
