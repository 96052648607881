import React from "react";
import { Provider } from "react-redux";

import Navigator from "./Navigator";
import { getStore } from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFileAlt,
  faEnvelope,
  faPlay,
  faQuestionCircle,
  faChevronUp,
  faSearch,
  faMale,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faQuestionCircle,
  faFileAlt,
  faEnvelope,
  faPlay,
  faChevronUp,
  faSearch,
  faMale,
  faAngleDoubleRight,
  faAngleDoubleLeft
);
const App = () => (
  <Provider store={getStore()}>
    <Navigator />
  </Provider>
);

export default App;
