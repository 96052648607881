import React, { Component } from "react";
import "./type-sessions.css";
export default class TypeSessions extends Component {
  render() {
    const {
      isActive,
      text,
      image,
      widthImage = 25,
      heightImage = 31,
    } = this.props;
    return (
      <figure className="type-session-engage">
        <img
          className={!isActive ? "img-session-disabled" : ""}
          style={{
            width: `${widthImage}px`,
            height: `${heightImage}px`,
          }}
          alt=""
          src={image}
        />
        <figcaption
          className={
            isActive
              ? `type-session-caption-figure`
              : `type-session-caption-figure-disabled`
          }
        >
          {text}
        </figcaption>
      </figure>
    );
  }
}
