import React, { Component } from "react";
import imageExists from "image-exists";
import Avatar from "react-avatar";
import { Media } from "reactstrap";
import AvatarSearch from "./../../images/avatar-search.png";

class ImageAvatar extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      exists: false,
    };
  }

  resolveURL(path, basePath) {
    return new URL(path, basePath).href;
  }

  componentDidMount() {
    const image = this.resolveURL(
      this.props.coach.image,
      process.env.REACT_APP_URL_BASE
    );
    imageExists(image, (exists) => {
      this.setState({ exists: exists });
    });
  }

  render() {
    const { image, engage_name } = this.props.coach;

    return (
      <>
        {this.state.exists ? (
          <Media
            object
            src={this.resolveURL(image, process.env.REACT_APP_URL_BASE)}
            onLoad={() => this.setState({ loading: false })}
            onError={(e) => (e.target.src = AvatarSearch)}
            alt={image}
          />
        ) : (
          <div className="container-avatar">
            <Avatar
              name={`${engage_name}`}
              unstyled={false}
              size="160"
              round={true}
              color={Avatar.getRandomColor(`${engage_name}`, [
                "#a6ce39",
                "#2c8440",
                "#303440",
                "#a4cc3c",
                "#043448",
              ])}
              fgColor="#FFFFFF"
              maxInitials={2}
            />
          </div>
        )}
      </>
    );
  }
}

export default ImageAvatar;
