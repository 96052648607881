import React from "react";

import "./style.css";
import "./header.css";
import "../../../src/templates/menu/styles.css";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    let logoClimbySearch =
      this.props.searchMenu != undefined && this.props.searchMenu != null
        ? "logoClimbySearch"
        : "logoClimby";
    let homeMneu =
      this.props.objetivos != undefined && this.props.objetivos != null
        ? "homeObjetivo"
        : this.props.searchMenu == undefined || this.props.searchMenu == null
        ? "homeMneu"
        : "";
    let menuDefault =
      this.props.searchMenu != undefined || this.props.searchMenu != null
        ? "menu-default-searche"
        : "";

    return (
      <div
        className={`menu-default-searche2 ${menuDefault} header-climb-section1 menuClimby`}
      >
        <h1 className="h1-disappear">Encontre aqui seu coach</h1>
        <Navbar light expand="md">
          <div className="header-logo-search">
            <img
              src={require("./../../images/header-logo-open.png")}
              className="header-web-container-search"
              alt="logo"
            />
            <span className="label-header-search">engage</span>

            {/*<div className="header-content-search">
            <div className="header-right-search">*/}
          </div>
          <NavbarToggler onClick={this.toggle} />
          <Collapse
            isOpen={this.state.isOpen}
            className="telaPrincipalMenu"
            navbar
          >
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  href={process.env.REACT_APP_URL_WORDPRESS}
                  className={homeMneu}
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={`${process.env.REACT_APP_URL_WORDPRESS}sou-coach/`}
                >
                  Sou Coach
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${process.env.REACT_APP_URL_WORDPRESS}blog/`}>
                  Blog
                </NavLink>
              </NavItem>
              <NavItem>
                {/*  <div className="menuText-search">
                    <a href={process.env.REACT_APP_URL_WORDPRESS}>Home</a>
                  </div>
                <div className="menuText-search">
                  <a href={`${process.env.REACT_APP_URL_WORDPRESS}sou-coach/`}>
                    Sou Coach
                  </a>
                </div>
                <div className="menuText-search">
                  <a href={`${process.env.REACT_APP_URL_WORDPRESS}blog/`}>
                    Blog
                  </a>
                </div>*/}
                <div className="login-btn-search">
                  <a
                    className="btn btn-default text-uppercase"
                    href={process.env.REACT_APP_URL_PLATFORM}
                  >
                    <span className="LocalLoginSearch">Login</span>
                  </a>
                </div>
              </NavItem>
            </Nav>
          </Collapse>
          {/* </div>
          </div>*/}
        </Navbar>
      </div>
    );
  }
}
