import React from 'react';

import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle
} from 'reactstrap';

import BlogImage from '../../images/blog-default.jpg';

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="section" id="blog">
        <Container>
          <h2 className="title text-center">Destaques do nosso Blog SuperAção</h2>
          <Row>
            <Col md="4" sm="6" xs="12">
              <Card>
                <figure>
                  <CardImg top width="100%" src={BlogImage} alt="Card image cap" />
                </figure>
                <CardBody>
                  <CardTitle>O Futuro dos negócios pra um Coach de sucesso</CardTitle>
                  <CardText>Muito se fala do sobre o futuro de negócios, porém pouco se fala a melhor forma de…</CardText>
                  <time>15/11/2018</time>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" sm="6" xs="12">
              <Card>
                <figure>
                  <CardImg top width="100%" src={BlogImage} alt="Card image cap" />
                </figure>
                <CardBody>
                  <CardTitle>O Futuro dos negócios pra um Coach de sucesso</CardTitle>
                  <CardText>Muito se fala do sobre o futuro de negócios, porém pouco se fala a melhor forma de…</CardText>
                  <time>15/11/2018</time>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" sm="6" xs="12">
              <Card>
                <figure>
                  <CardImg top width="100%" src={BlogImage} alt="Card image cap" />
                </figure>
                <CardBody>
                  <CardTitle>O Futuro dos negócios pra um Coach de sucesso</CardTitle>
                  <CardText>Muito se fala do sobre o futuro de negócios, porém pouco se fala a melhor forma de…</CardText>
                  <time>15/11/2018</time>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <div className="text-center">
          <a href="/" className="btn btn-link text-uppercase">Leia mais em nosso blog</a>
        </div>
      </div>
    );
  }
}