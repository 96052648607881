class ApiService {
    async execute(method, url, data) {
        return fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body:
                method === "POST"
                    ? data != null
                        ? this.parsePayload(data)
                        : []
                    : null,
        });
    }

    parsePayload(_payload) {
        return Object.keys(_payload)
            .map((key) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(
                    _payload[key]
                )}`;
            })
            .join("&");
    }
}

export default ApiService;