import React from 'react';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import { Link } from 'react-router-dom';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';

export default class Step6 extends React.Component {
  valid = false;

  constructor(props) {
    super(props);
    this.state = {
      historic_problem: props.getStore().historic_problem
    };
  }

  saveInput(value) {
    this.valid = true;
    this.setState({ historic_problem: value });
    this.props.updateStore({
      historic_problem: value
    });  // Up
  }


  render() {
    return (
      <div className="step-container">
        <h2 className="text-center">Você conhece em detalhes todo o processo ou procedimento antes de acontecer o problema ou situação? Possui todo o histórico?</h2>
        <h4 className="text-center">Caso não saiba ou não esteja certo, sua resposta deve ser "não"</h4>
        <form className="text-center">
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="sim" defaultChecked={this.state.historic_problem === 'sim'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Sim</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="não" defaultChecked={this.state.historic_problem === 'não'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Não</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(this.props.getStore().origin_problem === 'sim' ? 4 : 3)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.state.historic_problem} onClick={() => this.props.jumpToStep(6)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}