import React from 'react';
import InputGroup from 'reactstrap/lib/InputGroup';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';
export default class Step4 extends React.Component {

  array = [];
  hight_point;

  constructor(props) {
    super(props);

    this.state = {
      msgm_hight_point: props.getStore().msgm_hight_point,
      value: ''
    };

    this.hight_point = this.props.getStore().high_point;

    for (var [key, val] of Object.entries(this.hight_point)) {

      this.array = [...this.array, { text: key, value: val }]
    }

    this.array = this.array.filter( item => item.value ) 
    console.log(this.array)
  }

  saveInput(value) {
    this.setState({ msgm_hight_point: value, value: value });
    this.props.updateStore({
      msgm_hight_point: value
    });
  }


  translate(text) {
    switch (text) {
      case 'professional':
        return 'realização profissional';
      case 'emocional':
        return 'equilíbrio emocional';
      case 'cheers':
        return 'saúde';
      case 'relationships':
        return 'relacionamentos';
      case 'family':
        return 'família';
      case 'happiness':
        return 'felicidade';
      case 'financial':
        return 'financeiro';
    }
  }



  render() {

    

    return (
      <div id="step4" className="step-container">
        <h2 className="text-center">Porque você escolheu a(s) área(s) {this.translate(this.array[0].text)}, {this.translate(this.array[1].text)} e {this.translate(this.array[2].text)} como mais relevantes?</h2>
        <h4 className="text-center">Não existe certo ou errado. A sua resposta representa o seu sentimento atual.</h4>
        <form>
          <FormGroup className="textarea-content">
            <Input
              placeholder="Digite sua resposta"
              maxLength={'1024'}
              rows="8"
              type="textarea"
              value={this.state.value}
              onChange={(value) => this.saveInput(value.target.value)}
              defaultValue={this.state.hight_point}
            />
            <p className="text-right">{this.state.value.length}/1024</p>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(2)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button onClick={() => this.props.jumpToStep(4)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}