import React from "react";

import { ConnectedRouter } from "react-router-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { getHistory } from "./store";
import ImCoach from "./containers/imcoach";
import Searches from "./containers/searches";
import Assessment from "./containers/assessment/Assessment";
import ResolutionProblem from "./containers/resolutionproblem/ResolutionProblem";
import AdministrationTemp from "./containers/administrationTemp/AdministrationTemp";
import moreplans from "./containers/moreplans";
import { getSitePath, isEngage, isLocalhost, isIPHost } from "./libs/utils";
import EngageView from "./containers/engage";


const EngageRoutes = () => (
  <Switch>
    <Route path="/" exact component={EngageView} />
    {/* <Route path="/engage" exact component={EngageView} /> */}
  </Switch>
);

const OtherRoutes = () => (
  <Switch>
    <Route path="/sou-coach" component={ImCoach} />
    <Route path="/roda-da-vida" component={Assessment} />
    <Route path="/saiba-mais" component={moreplans} />
    <Route
      path="/tecnica-para-resolucao-de-problemas"
      component={ResolutionProblem}
    />
    <Route path="/administracao-do-tempo" component={AdministrationTemp} />
  </Switch>
);

const Navigator = () => (
  <ConnectedRouter history={getHistory()}>
    <Router basename={getSitePath() || "/"}>
      {/* {(isLocalhost() || !isEngage()) && <OtherRoutes />} */}
      <OtherRoutes />
      {(isLocalhost() || isIPHost() || isEngage()) && <EngageRoutes />}
    </Router>
  </ConnectedRouter>
);

export default Navigator;
