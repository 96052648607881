function removeTrailingSlashes(url) {
  return url.replace(/\/+$/, ""); //Removes one or more trailing slashes from URL
}

export function getSitePath() {
  return removeTrailingSlashes(
    new URL(
      isEngage()
        ? process.env.REACT_APP_URL_ENGAGE
        : process.env.REACT_APP_URL_SEARCHER
    ).pathname
  );
}

export function isEngage() {
  return (
    window.location.hostname ===
    new URL(process.env.REACT_APP_URL_ENGAGE).hostname
  );
}

export function isLocalhost() {
  return window.location.hostname === "localhost";
}

export function isIPHost() {
  return process.env.REACT_APP_IP_HOST_DEV ? window.location.hostname === process.env.REACT_APP_IP_HOST_DEV : false;
}

export function objectIsEmpty(value) {
  if (value === null) return true;
  if (value === undefined) return true;
  if (Object.keys(value).length === 0) return true;
  return false
}
