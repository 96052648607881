import React from 'react';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';
import Label from 'reactstrap/lib/Label';




export default class Step5 extends React.Component {
  valid = false;

  constructor(props) {
    super(props);
    this.state = {
      processess_problem: props.getStore().processess_problem
    };
  }

  saveInput(value) {
    this.valid = true;
    this.setState({ processess_problem: value });
    this.props.updateStore({
      processess_problem: value
    });  // Up
  }


  render() {
    return (
      <div id="step5" className="step-container">
        <h2 className="text-center">Ele se originou por uma falha de processo?</h2>
        <h4 className="text-center">Caso não saiba ou não esteja certo, sua resposta deve ser "não"</h4>
        <form className="text-center">
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="sim" defaultChecked={this.state.processess_problem === 'sim'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Sim</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="não" defaultChecked={this.state.processess_problem === 'não'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Não</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(3)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.state.processess_problem} onClick={() => this.props.jumpToStep(5)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}