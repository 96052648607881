import React from 'react';
import Input from 'reactstrap/lib/Input';
import InputMask from 'react-input-mask';
import { Button } from 'reactstrap';


export default class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: props.getStore().tasks
    };
  }


  saveInput(value, type) {
    const tasks = this.state.tasks;
    tasks[type].timer = value;
    this.setState({ tasks: tasks });
    this.props.updateStore({ tasks: tasks });
  }

  validation(value) {
    if (value && value > 10) {
      return 10;
    } else if (!value) {
      return 0;
    } else {
      return Number(value);
    }
  }


  render() {
    return (
      <div id="step3" className="step-container">
        <h2 className="text-center">Descreva abaixo as 5 tarefas que mais consomem tempo no seu dia a dia</h2>
        <h4 className="text-center">Descreva cada tarefa em uma linha específica</h4>
        <form>
          <div className="form-group group-input text-center no-margin">
            {
              this.state.tasks[0].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[0].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask defaultValue={this.state.tasks[0].timer} className="form-control form-smallsize form-timer" 
                    mask="99:99" maskChar={null} defaultChecked={this.state.tasks[0].timer} onChange={(value) => { this.saveInput(value.target.value, 0) }} required placeholder="HH:mm" />
                </div>
                : ''
            }
            {
              this.state.tasks[1].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[1].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask defaultValue={this.state.tasks[1].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} defaultChecked={this.state.tasks[1].timer} onChange={(value) => { this.saveInput(value.target.value, 1) }} required placeholder="HH:mm" />
                </div>
                : ''
            }
            {
              this.state.tasks[2].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[2].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask defaultValue={this.state.tasks[2].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} defaultChecked={this.state.tasks[2].timer} onChange={(value) => { this.saveInput(value.target.value, 2) }} required placeholder="HH:mm" />
                </div>
                : ''
            }
            {
              this.state.tasks[3].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[3].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask defaultValue={this.state.tasks[3].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} defaultChecked={this.state.tasks[3].timer} onChange={(value) => { this.saveInput(value.target.value, 3) }} required placeholder="HH:mm" />
                </div>
                : ''
            }

            {
              this.state.tasks[4].item ?
                <div className="double">
                  <Input disabled={true} defaultValue={this.state.tasks[4].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" />
                  <InputMask defaultValue={this.state.tasks[4].timer} className="form-control form-smallsize form-timer" mask="99:99" maskChar={null} defaultChecked={this.state.tasks[4].timer} onChange={(value) => { this.saveInput(value.target.value, 4) }} required placeholder="HH:mm" />
                </div> :
                ''
            }
          </div>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(1)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button onClick={() => this.props.jumpToStep(3)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}