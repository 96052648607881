import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actionType } from './reducer';
import * as selector from './selectors';
import Moreplans from './Moreplans';

export const mapStateToProps = createStructuredSelector({
  data: selector.makeSelectData(),
});
export const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch({
    type: actionType.FETCH_DATA_ASYNC,
  }),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Moreplans);
