import React, { Component } from "react";
import ModalContainer from "../modalContainer/modalContainer";
import Checkbox from "../checkBox/checkBox";
import SelectOptions from "../selectOptions/selectOptions";
import ButtonAction from "../buttonAction/button_action";
//import ToggleSwitch from "../toggleSwitch/toggleSwitch";
import SearchService from "../../containers/searches/service/SearchService";

import "./moreFilters.css";

export default class MoreFilters extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.tryClose = this.tryClose.bind(this);
    this.refMoreFilterBackdrop = React.createRef();

    this.state = {
      occupationArea: [],
      countries: [],
      sessions: [],
      states: [],
      statesCities: [],
      cities: [],
      city: "",
      institutes: [],
      certificate: false,
      premium: false,
    };

    this.searchService = new SearchService();
  }

  static getDerivedStateFromProps(props) {
    return {
      occupationArea: props.occupationArea,
      countries: props.countries,
      sessions: props.sessions,
      institutes: props.institutes,
    };
  }

  handleClickDisplay() {
    if (this.props.setState) {
      this.props.setState((state) => ({
        ...state,
        executeClickMoreFilters: true,
        optionsSelected: {
          certificate: this.props.state.certificate,
          premium: this.props.state.premium,
          occupationArea: this.props.state.occupationArea.filter(
            (el) => el.checked
          ),
          sessions: this.props.state.sessions.filter((el) => el.checked),
          countries: this.props.state.countries.filter((el) => el.checked),
          states: this.props.state.states.filter((el) => el.checked),
          cities: this.props.state.cities.filter((el) => el.checked),
          institutes: this.props.state.institutes.filter((el) => el.checked),
          lastState: this.props.state.states.filter((el) => el.checked),
          lastCity: this.props.state.cities.filter((el) => el.checked),
        },
      }));
    }
  }

  handleSwitch(key, newState) {
    this.setState((state) => ({
      ...state,
      [key]: newState,
    }));
  }

  handleClose() {
    if (this.props.setState) {
      this.props.setState((state) => ({
        ...state,
        isOpenMoreFilters: false,
      }));
    }
  }

  async componentDidMount() {
    document.getElementById("rootPortal").style.minHeight = `${
      document.getElementById("root").clientHeight
    }px`;

    document.getElementById("backdropMoreFilters").style.minHeight = `${
      document.getElementById("root").clientHeight
    }px`;

    document.body.addEventListener("click", this.tryClose);

    const checkedCountry = this.props.state.countries.filter(
      (el) => el.checked
    );

    if (checkedCountry.length > 0) {
      await this.changeCountry(checkedCountry[0]);
    }

    if (
      this.props.state.optionsSelected.states &&
      this.props.state.optionsSelected.states.length > 0
    ) {
      this.setState({
        ...this.state,
      });
      this.loadCities(this.props.state.optionsSelected.states[0]);
    }
  }

  componentWillUnmount() {
    document.getElementById("rootPortal").style.height = "0px";
    document.getElementById("rootPortal").style.minHeight = "0px";

    document.getElementById("backdropMoreFilters").style.height = "0px";
    document.getElementById("backdropMoreFilters").style.minHeight = "0px";

    document.body.removeEventListener("click", this.tryClose);
  }

  // handleSwitch(key, newState) {
  // this.props.setState((state) => ({
  //   ...state,
  //   [key]: newState,
  // }));
  // }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  tryClose(ev) {
    if (
      this.refMoreFilterBackdrop &&
      this.refMoreFilterBackdrop.current &&
      this.refMoreFilterBackdrop.current === ev.srcElement
    ) {
      this.handleClose();
    }
  }

  async changeCountry(value) {
    if (value) {
      const states = [];
      this.setState({
        states: [],
        cities: [],
        city: "",
      });

      const resultCountryStates = await this.searchService.statesForeign(
        value.label
      );

      if (resultCountryStates.success) {
        this.setState({ statesCities: resultCountryStates.data[0] });
        const statesForeing = resultCountryStates.data[0].states.map((item) => {
          return { name_uf: item.name, sigla_uf: item.initials_name };
        });

        const lastState =
          this.props.state.optionsSelected.lastState &&
          this.props.state.optionsSelected.lastState.length > 0
            ? this.props.state.optionsSelected.lastState[0].label
            : "";
        statesForeing.forEach((item) => {
          states.push({
            label: item.name_uf,
            value: value.label === "Brasil" ? item.sigla_uf : item.name_uf,
            checked: item.name_uf === lastState ? true : false,
          });
        });
        this.setState({
          states,
        });
      }
    } else {
      this.setState({
        states: [],
        cities: [],
        city: "",
      });
    }
  }

  async loadCities(state) {
    const citiesData = this.state.statesCities.states.filter(
      (item) => item.name === state.label
    );
    const lastCity =
      this.props.state.optionsSelected.lastCity &&
      this.props.state.optionsSelected.lastCity.length > 0
        ? this.props.state.optionsSelected.lastCity[0].label
        : "";
    const cities = [];
    citiesData[0].cities.forEach((res) => {
      cities.push({
        label: res.name,
        value: res.name,
        checked: res.name === lastCity ? true : false,
      });
    });
    this.setState({ cities });
  }

  render() {
    return (
      <ModalContainer>
        <div
          ref={this.refMoreFilterBackdrop}
          id="backdropMoreFilters"
          className="backdropMoreFilters"
        >
          <div className="wrapperMoreFilters">
            <div className="headerMoreFilters">
              <span>Mais Filtros</span>
              <span onClick={this.handleClose}>X</span>
            </div>
            <span className="divisionFull" />

            <div className="containerMoreFilters">
              {/* Removido momentaneamente a pedido do Lemos, não excluir */}
              {/* ------------------------------------------------------------------------- */}
              {/* <div className="containerToggleSwitch">
                <ToggleSwitch
                  id="certificate"
                  checked={this.props.state.certificate}
                  label="Certificado"
                  onChange={() =>
                    this.handleSwitch("certificate", !this.props.state.certificate)
                  }
                />
                <ToggleSwitch
                  id="premium"
                  checked={this.props.state.premium}
                  label="Selo Premium"
                  onChange={() =>
                    this.handleSwitch("premium", !this.props.state.premium)
                  }
                />
              </div> 
              <span className="divisionFull" />*/}
              {/* ------------------------------------------------------------------------- */}
              {/* Removido momentaneamente a pedido do Lemos, não excluir */}
              <span className="titleFilters">Área de Atuação</span>
              <div className="occupationAreaFilters">
                {this.props.state.occupationArea.map((item, idx) => (
                  <Checkbox
                    onChange={(item) => {
                      if (this.props.onChangeSelect) {
                        this.props.onChangeSelect(
                          item,
                          this.props.state.occupationArea,
                          "occupationArea"
                        );
                      }
                    }}
                    key={idx}
                    item={item}
                  />
                ))}
              </div>
              <span className="divisionFull" />
              <span className="titleFilters">Sessões</span>
              <div className="sessionsFilters">
                {this.props.state.sessions.map((item, idx) => (
                  <Checkbox
                    onChange={(item) => {
                      if (this.props.onChangeSelect) {
                        this.props.onChangeSelect(
                          item,
                          this.props.state.sessions,
                          "sessions"
                        );
                      }
                    }}
                    key={idx}
                    item={item}
                  />
                ))}
              </div>
              <span className="divisionFull" />
              <span className="titleFilters">Lugar</span>
              <div className="containerSessionsPlaces">
                <div className="sessionsPlaces">
                  <span className="titlePlaces">País</span>
                  <SelectOptions
                    placeholder="País"
                    singleSelection
                    executeAction={false}
                    dataSource={this.props.state.countries}
                    onChange={(item) => {
                      if (this.props.onChangeSelect) {
                        this.props.onChangeSelect(
                          item,
                          this.props.state.countries,
                          "countries",
                          true
                        );
                        this.changeCountry(item);
                      }
                    }}
                    removeAll={() => {
                      if (this.props.onRemoveAll) {
                        this.props.onRemoveAll(
                          this.props.state.countries,
                          "countries"
                        );
                      }
                    }}
                    onClick={() => {
                      if (this.props.HandleClickDisplay) {
                        this.props.HandleClickDisplay("countries");
                      }
                    }}
                  />
                </div>

                <div className="sessionsPlaces">
                  <span className="titlePlaces">Cidade</span>
                  <SelectOptions
                    placeholder="Cidade"
                    singleSelection
                    executeAction={false}
                    dataSource={this.state.cities}
                    onChange={(item) => {
                      if (this.props.onChangeSelect) {
                        this.props.onChangeSelect(
                          item,
                          this.state.cities,
                          "cities",
                          true
                        );
                      }
                    }}
                    removeAll={() => {
                      if (this.props.onRemoveAll) {
                        this.props.onRemoveAll(this.state.cities, "cities");
                      }
                    }}
                    onClick={() => {
                      if (this.props.HandleClickDisplay) {
                        this.props.HandleClickDisplay("cities");
                      }
                    }}
                  />
                </div>

                <div className="sessionsPlaces">
                  <span className="titlePlaces">Estado</span>
                  <SelectOptions
                    placeholder="Estado"
                    singleSelection
                    executeAction={false}
                    dataSource={this.state.states}
                    onChange={(item) => {
                      if (this.props.onChangeSelect) {
                        this.props.onChangeSelect(
                          item,
                          this.state.states,
                          "states",
                          true
                        );
                      }
                      this.loadCities(item);
                    }}
                    removeAll={() => {
                      if (this.props.onRemoveAll) {
                        this.props.onRemoveAll(this.state.states, "states");
                      }
                    }}
                    onClick={() => {
                      if (this.props.HandleClickDisplay) {
                        this.props.HandleClickDisplay("states");
                      }
                    }}
                  />
                </div>
              </div>
              <span className="divisionFull" />
              <span className="titleFilters">Instituição de formação</span>
              <SelectOptions
                id="institutes"
                placeholder="Instituição de formação"
                dataSource={this.props.state.institutes}
                singleSelection={true}
                executeAction={false}
                onChange={(item) => {
                  if (this.props.onChangeSelect) {
                    this.props.onChangeSelect(
                      item,
                      this.props.state.institutes,
                      "institutes",
                      true
                    );
                  }
                }}
                removeAll={() => {
                  if (this.props.onRemoveAll) {
                    this.props.onRemoveAll(
                      this.props.state.institutes,
                      "institutes"
                    );
                  }
                }}
                onClick={() => {
                  if (this.props.HandleClickDisplay) {
                    this.props.HandleClickDisplay("institutes");
                  }
                }}
              />

              <div className="containerButtonsFooter">
                <span
                  className="remove-filters"
                  onClick={() => {
                    if (this.props.onRemoveAll) {
                      this.props.onRemoveAll(undefined, undefined, true);
                    }

                    this.handleClose();
                  }}
                >
                  Remover filtros
                </span>

                <ButtonAction
                  onClick={() => {
                    if (this.handleClickDisplay) {
                      this.handleClickDisplay();
                    }

                    this.handleClose();
                  }}
                >
                  Exibir
                </ButtonAction>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
