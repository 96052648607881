import React from 'react';
import Scrollchor from 'react-scrollchor';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import logo from '../../images/logo.svg';
import { faFacebook, faInstagram, faYoutube, faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faFacebookF, faInstagram, faTwitter, faYoutube);

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    // console.log(event);
    const scrollTop = event.target.body.parentElement.scrollTop;
    // const headerHeight = document.querySelector("#header").clientHeight;
    const el = document.querySelector(".btn-anchor");
    // if (el) {
    //   if (scrollTop > headerHeight) {
    //     el.classList.add('active');
    //   } else {
    //     el.classList.remove('active');
    //   }
    // }
  }

  render() {
    return (
      <div>
        <div id="footer">
          <Container>
            <Row>
              <Col md="4" sm="12">
                <img src={logo} className="logo" />
              </Col>
              <Col md="4" sm="12">
                <ul>
                  <li>
                    <a href="mailto:contato@climby.com.br">contato@climby.com</a>
                  </li>
                  <li>
                    <a href="#">Quem somos</a>
                  </li>
                  <li>
                    <a href="#">Trabalhe conosco</a>
                  </li>
                </ul>
              </Col>
              <Col md="4" sm="12">
                <ul className="socials">
                  <li>
                    <a href="/" rel="no-follow">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a href="/" rel="no-follow">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="/" rel="no-follow">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="/" rel="no-follow">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="company text-center">
          {/* climby 2018 - Todos os direitos reservados */}
        </div>
        <Scrollchor to="#header"
          className="btn btn-default btn-anchor"><FontAwesomeIcon icon="chevron-up" /></Scrollchor>
      </div>
    );
  }
}