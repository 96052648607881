import { put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { actionType } from "./reducer";

export function* fetchDataAsync() {
  try {
    const url = `${process.env.REACT_APP_URL_API}/states`;
    const results = yield call(axios.get, url);
    yield put({ type: actionType.FETCH_DATA_SUCCESS, payload: { results } });
  } catch (error) {
    yield put({ type: actionType.FETCH_DATA_FAILURE, payload: error });
  }
}

export function* fetchAreasAsync() {
  try {
    const url = `${process.env.REACT_APP_URL_API}/areas`;
    const results = yield call(axios.get, url);
    yield put({
      type: actionType.FETCH_DATA_AREAS_SUCCESS,
      payload: { results },
    });
  } catch (error) {
    yield put({ type: actionType.FETCH_DATA_AREAS_FAILURE, payload: error });
  }
}

export function* fetchInstitutesAsync() {
  try {
    const url = `${process.env.REACT_APP_URL_API}/institutes`;
    const results = yield call(axios.get, url);
    yield put({
      type: actionType.FETCH_DATA_INSTITUTES_SUCCESS,
      payload: { results },
    });
  } catch (error) {
    yield put({
      type: actionType.FETCH_DATA_INSTITUTES_FAILURE,
      payload: error,
    });
  }
}

export function* fetchCountriesAsync() {
  try {
    const url = `${process.env.REACT_APP_URL_API}/countries`;
    const results = yield call(axios.get, url);
    yield put({
      type: actionType.FETCH_DATA_COUNTRIES_SUCCESS,
      payload: { results },
    });
  } catch (error) {
    yield put({
      type: actionType.FETCH_DATA_COUNTRIES_FAILURE,
      payload: error,
    });
  }
}

export function* watchSearchesSagas() {
  yield takeEvery(actionType.FETCH_DATA_ASYNC, fetchDataAsync);
  yield takeEvery(actionType.FETCH_DATA_ASYNC, fetchAreasAsync);
  yield takeEvery(actionType.FETCH_DATA_ASYNC, fetchInstitutesAsync);
  yield takeEvery(actionType.FETCH_DATA_ASYNC, fetchCountriesAsync);
}
