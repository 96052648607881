import React from 'react';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';

export default class Step11 extends React.Component {

  valid = false;

  constructor(props) {
    super(props);
    this.state = {
      process_coaching: props.getStore().process_coaching
    };
  }

  saveInput(value) {
    this.valid = true;
    this.setState({ process_coaching: value });
    this.props.updateStore({
      process_coaching: value
    });  // Up
  }


  render() {
    return (
      <div id="step11" className="step-container">
        <h2 className="text-center">Você já participou de um processo de Coaching?</h2>

        <form className="text-center">
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="sim" defaultChecked={this.state.application_resolution === 'sim'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Sim</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="não" defaultChecked={this.state.application_resolution === 'nao'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Não</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(9)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.state.process_coaching} onClick={() => this.props.jumpToStep(11)} className="text-uppercase btn-prev" color="primary">Finalizar</Button>
        </div>
      </div>
    );
  }
}