import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actionType } from '../searches/reducer';
import * as selector from '../searches/selectors';
import EngageView from './EngageView';

export const mapStateToProps = createStructuredSelector({
    data: selector.makeSelectData(),
});
export const mapDispatchToProps = dispatch => ({
    fetchData: () => dispatch({
        type: actionType.FETCH_DATA_ASYNC,
    }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EngageView);

//export default EngageView;