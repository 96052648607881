import React from 'react';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Button } from 'reactstrap';

export default class Step9 extends React.Component {

  valid = false;

  constructor(props) {
    super(props);
    this.state = {
      urgency_problem: props.getStore().urgency_problem
    };
  }

  saveInput(value) {
    this.valid = true;
    this.setState({ urgency_problem: value });
    this.props.updateStore({
      urgency_problem: value
    });  // Up
  }


  render() {
    return (
      <div id="step9" className="step-container">
        <h2 className="text-center">Esta situação precisa ser resolvida de forma urgente ou você possui um tempo para buscar alternativas?</h2>
        <form className="text-center">
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="Urgente" defaultChecked={this.state.application_resolution === 'Urgente'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Urgente</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <FormGroup className="radio-default">
            <Label>
              <Input type="radio" value="Tenho tempo para uma análise" defaultChecked={this.state.application_resolution === 'Tenho tempo para uma análise'} onChange={(value) => this.saveInput(value.target.value)} className="form-radio-input" name="radio1" />{' '}
              <span className="text">Tenho tempo para uma análise</span>
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(this.props.getStore().cicle_problem === 'sim' ? 7 : 6)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.state.urgency_problem} onClick={() => this.props.jumpToStep(9)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}