import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import Scrollchor from "react-scrollchor";

// Images
import Slide1 from "../../images/slide-1-new.jpg";
import Slide2 from "../../images/slide-2-new.jpg";
import Slide3 from "../../images/slide-3-new.jpg";

import DevicesImage from "../../images/devices.jpg";

import NetWorkingImage from "../../images/icons-images/svg/networking.svg";
import ControlImage from "../../images/icons-images/svg/control.svg";
import GestorImage from "../../images/icons-images/svg/gestor.svg";
import SearchPerson from "../../images/icons-images/svg/search-person.svg";
import StarsImage from "../../images/icons-images/svg/starts.svg";
import RecebimentsImage from "../../images/icons-images/svg/recebiments.svg";
import MegafoneImage from "../../images/icons-images/svg/megafone-color.svg";
import EngajamentImage from "../../images/icons-images/svg/plus-engajament.svg";
import TimerImage from "../../images/icons-images/svg/time.svg";

// Layouts
import Menu from "../../templates/menu/menu";
import Footer from "../../templates/footer/footer";
import Blog from "../../templates/blog/Blog";
import Newsletter from "../../templates/newletter/Newsletter";
import Testimonials from "../../templates/testimonials/Testimonials";

// Carousel Items
const items = [
  {
    id: 1,
    src: Slide1,
    caption: `Uma plataforma online em nuvem segura que garante os melhores processos na gestão de clientes e nas práticas de Coaching.`,
    align: "left",
  },
  {
    id: 2,
    src: Slide2,
    caption:
      "Oferte seus serviços de Coaching e seja localizado por milhares de clientes interessados em alcançar seus objetivos.",
    align: "center",
  },
  {
    id: 3,
    src: Slide3,
    caption:
      "Aumente sua produtividade. Todas as informações em uma só plataforma acusadas de qualquer dispositivo. Sem stress.",
    align: "right",
  },
];

const coachings = [
  {
    src: NetWorkingImage,
    title: "Gerencie seus Coachees",
    text:
      "Gerencie seus clientes de forma única. Controle os contatos de contatos de novas oportunidades, agende sessões e emita propostas ganhadoras.",
  },
  {
    src: ControlImage,
    title: "Controle o Clico de vida das sessões",
    text:
      "Organize seus registos de forma prática e online. Tinha à mão toda a comunicação, conteúdos e relatórios compartilhados, planos de ação e sessões por seus Coachees.",
  },
  {
    src: GestorImage,
    title: "Gestão à vista",
    text:
      "A qualquer momento, de qualquer dispositivo, acesse o painel de controle geral e, com uma visão ampla, saiba tudo o que está acontecendo com seus Coachees.",
  },
  {
    src: SearchPerson,
    title: "Oferte seus serviços",
    text:
      "Torne-se um membro da maior comunidades de Coaches do Brasil. Seja encontrado por milhares de potenciais clientes.",
  },
  {
    src: StarsImage,
    title: "Melhores práticas",
    text: "Acesse diversos modelos de conteúdo para uma gestão de Coaching.",
  },
  {
    src: RecebimentsImage,
    title: "Gerencie seus recebimentos",
    text:
      "Controle a rentabilidade por cliente e fature diretamente pela plataforma.",
  },
  {
    src: MegafoneImage,
    title: "Crie o Marketing",
    text:
      "Crie sua página de oferta de serviços e amplie sua presença on-line com gerenciamento das campanhas em redes sociais.",
  },
  {
    src: EngajamentImage,
    title: "Aumente o engajamento",
    text:
      "Estabeleça um canal de comunicação direta com seu Coachee, aumentando o comprometimento com a as sessões e planos de ação rumo ao objetivo.",
  },
  {
    src: TimerImage,
    title: "Ganhe tempo",
    text:
      "Garanta uma gestão de Coaching sem complexidade e sempre disponível para consultas ou alterações. Planeje as sessões de seus Coachees e emita relatórios rapidamente.",
  },
];

const plans = [
  {
    title: "STARTER",
    active: false,
    prices: {
      value: "19,80/mês",
      oldValue: "22/mês",
    },
    freedays: true,
    text:
      "Mais indicado para Coaches que estao buscando as melhores práticas ao iniciar sua jornada no Coaching",
    features: [
      "Até 2 coachees ativos",
      "Cadastro no Buscador de Coaches",
      "Criação de website para oferta dos serviços",
      "Armazenamento de Conteúdo de 250 MB",
      "Gestão das oportunidades em funil de Vendas",
      "Gestão das sessões de Coaching",
      "Portal do Coachee",
      "Relatórios profissionais",
      "Gestão financeira",
      "Meio de pagamento para Coachees",
    ],
  },
  {
    title: "Expert",
    active: true,
    prices: {
      value: "70/mês",
      oldValue: "82/mês",
    },
    freedays: true,
    text:
      "Nosso plano mais contratado por Coaches em crescimento que desejam expandir suas atividades com uma gestão profissional",
    features: [
      "Até 5 coachees ativos",
      "Tudo do Starter +",
      "Destaque no Buscador de Coaches",
      "Armazenamento de Conteúdo de 500 MB",
      "Integração de agenda",
      "Customização de ferramentas e formulários",
      "Relatórios avançados",
    ],
  },
  {
    title: "Prime",
    active: false,
    prices: {
      value: "180/mês",
      oldValue: "205/mês",
    },
    freedays: true,
    text: "Para Coaches que buscam automatizar e potencializar suas atividades",
    features: [
      "Até 15 coachees ativos",
      "Tudo do Expert +",
      "Armazenamento de Conteúdo de 1 GB",
      "Tempo de resposta no atendimento de até 24 horas",
    ],
  },
  {
    title: "MASTER",
    active: false,
    prices: {
      value: "250/mês",
      oldValue: "280/mês",
    },
    freedays: true,
    text:
      "Para Coaches estabelecidos no mercado que atendem a um grande numero de Coachees",
    features: [
      "Ilimitado",
      "Tudo do Prime +",
      "Armazenamento de Conteúdo de 5 GB",
      "Tempo de resposta no atendimento de até 4 horas",
    ],
  },
];

class Imcoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      value_plan: "year",
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.service = this.service.bind(this);
    this.getPlans = this.getPlans.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  createList = (array) => {
    let value = [];
    for (let i = 0; i < array.length; i++) {
      const offset = i === 0 ? 1 : 0;

      value.push(
        <Col key={i} md="4" sm="6" xs="12">
          <Card className="card-item-default">
            <figure>
              <CardImg
                top
                width="100%"
                src={array[i].src}
                alt="Card image cap"
              />
            </figure>
            <CardBody>
              <CardTitle>{array[i].title}</CardTitle>
              <CardText>{array[i].text}</CardText>
            </CardBody>
          </Card>
        </Col>
      );
    }
    return value;
  };

  createListPlans = (array) => {
    let value = [];
    for (let i = 0; i < array.length; i++) {
      let list = [];
      const offset = i === 0 ? 1 : 0;

      if (array[i].details && array[i].details.length > 0) {
        for (let x = 0; x < array[i].details.length; x++) {
          list.push(<li key={x}>{array[i].details[x].title}</li>);
        }
      }

      value.push(
        <div key={i} className="columns">
          <Card
            className={
              (array[i].most_used ? "default" : "") + " card-item-default"
            }
          >
            <h4 className="text-uppercase">{array[i].title}</h4>
            <div className="box-plans">
              <div className="price line-through">
                R$ <span>{array[i].value_full}</span>
              </div>
              <div className="price">
                R$ <span>{array[i].value}</span>
              </div>
            </div>

            {array[i].test_time ? (
              <p>Gratuito por {array[i].test_time} dias!</p>
            ) : (
              ""
            )}
            <Button className="text-uppercase" color="primary">
              Assinar agora
            </Button>
            <CardBody>
              <CardTitle>
                Mais indicado para Coaches que estão Iniciando sua jornada
                Coaching
              </CardTitle>
              <ul>{list}</ul>
            </CardBody>
          </Card>
        </div>
      );
    }
    return value;
  };

  componentDidMount() {
    const { fetchData } = this.props;
    window.scrollTo(0, 0);
    fetchData();
    this.getPlans();
  }

  changePlan(type) {
    this.setState({
      value_plan: type,
    });
    // if (this.state.value_plan === 'year') {

    //   plans[0].prices.value = '24,80/mês';
    //   plans[0].prices.oldValue = '26,00/mês';

    //   plans[1].prices.value = '87,50/mês';
    //   plans[1].prices.oldValue = '102,00/mês';

    //   plans[2].prices.value = '225,00/mês';
    //   plans[2].prices.oldValue = '253/mês';

    //   plans[3].prices.value = '312,00/mês';
    //   plans[3].prices.oldValue = '355,00/mês';

    // } else {
    //   plans[0].prices.value = '19,80/mês';
    //   plans[0].prices.oldValue = '22,00/mês';

    //   plans[1].prices.value = '70,00/mês';
    //   plans[1].prices.oldValue = '82,00/mês';

    //   plans[2].prices.value = '180,00/mês';
    //   plans[2].prices.oldValue = '205,00/mês';

    //   plans[3].prices.value = '250,00/mês';
    //   plans[3].prices.oldValue = '280,00/mês';
    // }
  }

  getPlans() {
    this.service("GET", `${process.env.REACT_APP_URL_BASE}open/plans`)
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({ _plans: res.plans });
        }
      });
  }

  service(method, url, data) {
    return fetch(url, {
      method: method,
      headers: {
        // 'Accept': 'application/json',
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body:
        method === "POST"
          ? data != null
            ? this.parsePayload(data)
            : []
          : null,
    });
  }

  render() {
    const { data } = this.props;
    const { activeIndex, _plans } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.id}
        >
          <img src={item.src} />
          <div className="align">
            <div className={`middle ${item.align}`}>
              <div className="carousel-content">
                <CarouselCaption captionHeader={item.caption} captionText="" />
                <Scrollchor to="#coach">Saiba mais</Scrollchor> <br />
                <Button
                  onClick={(e) =>
                    (window.location.href = `${
                      process.env.REACT_APP_URL_PLATFORM
                    }register`)
                  }
                  className="full text-uppercase"
                  color="success"
                >
                  Cadastre-se
                </Button>
              </div>
            </div>
          </div>
        </CarouselItem>
      );
    });

    return (
      <div
        id="imcoach"
        className="imcoach-container"
        data-test-id="imcoach-container"
      >
        <header id="header" className="header-default no-bg">
          <Menu />
          <div className="header-title">
            <h1>A plataforma em cloud de gestão de Coaching</h1>
            <h3>Planeje. Gerencie. Reporte. Fature.</h3>
          </div>
          <Carousel
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={this.goToIndex}
            />
            {slides}
          </Carousel>
        </header>

        <div id="body-main">
          <div className="section" id="coach">
            <Container>
              <h2 className="title text-center blue">
                Transforme suas sessões Coaching
              </h2>
              <Row>{this.createList(coachings)}</Row>
              <div className="content-button text-center">
                <Button className="text-uppercase" color="default">
                  Experimente por 30 dias
                </Button>
              </div>
            </Container>
          </div>

          <Testimonials />

          <div className="section" id="plans">
            <Container>
              <h2 className="title text-center">
                Compare, explore as funcionalidade e assine já os planos mais
                adequados para gerir seus Coachees
              </h2>

              <div className="button-groups">
                <Button
                  onClick={(event) => this.changePlan("month")}
                  className={
                    "text-uppercase " +
                    (this.state.value_plan == "month" ? "active" : "")
                  }
                >
                  Mensal
                </Button>
                <Button
                  onClick={(event) => this.changePlan("year")}
                  className={
                    "text-uppercase " +
                    (this.state.value_plan == "year" ? "active" : "")
                  }
                >
                  Anual
                </Button>
              </div>
              <div className="descount text-right">
                {this.state.value_plan === "year" ? "20% de desconto" : ""}
              </div>

              <div className="row-custom">
                {this.createListPlans(
                  _plans
                    ? _plans.filter((item) => {
                        return (
                          item.type == "platform" &&
                          item.periodice ==
                            (this.state.value_plan == "year" ? "12m" : "1m")
                        );
                      })
                    : []
                )}
              </div>

              <div className="text-center button-content">
                <Link
                  to="/saiba-mais"
                  className="text-uppercase btn btn-default"
                >
                  Saiba mais
                </Link>
              </div>
            </Container>
          </div>
          <div id="devices" className="section">
            <Container>
              <h2 className="title text-center blue">
                Disponível em diversas plataformas
              </h2>
              <div className="text-center wow fadeIn">
                <img src={DevicesImage} />
              </div>
            </Container>
          </div>
          <Blog />
          <Newsletter />
        </div>

        <Footer />

        {/* <Scrollchor to="#header"
          className="btn btn-default btn-anchor"><FontAwesomeIcon icon="chevron-up" /></Scrollchor> */}
      </div>
    );
  }
}

Imcoach.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func,
};

export default Imcoach;
