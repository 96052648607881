import { put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { actionType } from "./reducer";

export function* fetchDataAsync() {
  try {
    const url = `${process.env.REACT_APP_URL_API}/areas`;
    const results = yield call(axios.get, url);
    // console.log(results);
    yield put({ type: actionType.FETCH_DATA_SUCCESS, payload: { results } });
  } catch (error) {
    yield put({ type: actionType.FETCH_DATA_FAILURE, payload: error });
  }
}

export function* fetchStatesAsync() {
  try {
    const url = `${process.env.REACT_APP_URL_API}/states`;
    const results = yield call(axios.get, url);
    yield put({ type: actionType.FETCH_STATES_SUCCESS, payload: { results } });
  } catch (error) {
    yield put({ type: actionType.FETCH_STATES_FAILURE, payload: error });
  }
}

export function* watchHomeSagas() {
  yield takeEvery(actionType.FETCH_DATA_ASYNC, fetchDataAsync);
  yield takeEvery(actionType.FETCH_DATA_ASYNC, fetchStatesAsync);
}
