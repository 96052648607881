import React from 'react';

import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselCaption,
} from 'reactstrap';

import TestemonialImage from '../../images/testemonial.jpg';

const testimonials = [
  {
    id: 1,
    src: TestemonialImage,
    caption: '"Me permite trabalhar melhor e me concentrar no que realmente eu gosto, fazer coaching e ajudar meus clientes. Recomendo sem dúvida."',
    info: 'Lara Moreira, CEO da TP Design',
    office: 'COACH DE CARREIRA',
    site: 'WWW.LARAMOREIRA.COM.BR'
  },
  {
    id: 2,
    src: TestemonialImage,
    caption: '"Me permite trabalhar melhor e me concentrar no que realmente eu gosto, fazer coaching e ajudar meus clientes. Recomendo sem dúvida."',
    info: 'Lara Moreira, CEO da TP Design',
    office: 'COACH DE CARREIRA',
    site: 'WWW.LARAMOREIRA.COM.BR'
  }
];

export default class Testimonials extends React.Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }


  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === testimonials.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? testimonials.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;



    const slide = testimonials.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExitingTestemonials}
          onExited={this.onExitedTestemonials}
          key={item.id}
        >
          <figure>
            <img src={item.src} alt={item.altText} />
          </figure>
          <CarouselCaption captionHeader={item.caption} captionText='' />
          <div className="infs">
            <p>{item.info} <br /> {item.office} <br /> <span>{item.site}</span></p>
          </div>
        </CarouselItem>
      );
    });

    return (
      <div className="section shadow full-height" id="benefits">
        <div className="align">
          <div className="middle">
            <Container>
              <h2 className="title text-center blue">Veja a opinião de quem está se beneficiando com a climby</h2>
              <Row>
                <Col lg={{ offset: 1, size: 10 }} xs="12">
                  <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                  >
                    {slide}

                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}