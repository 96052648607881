//objects 
export const optionsSortBy = [
    { value: 1, label: "Menor preço" },
    { value: 2, label: "Maior Preço" },
    { value: 3, label: "Melhor avaliação" },
    { value: 4, label: "Ordem alfabética" },
];

//text labels
export const TEXT_LABELS = {
    order_by: "Classificar por",
    loading_coaches: "Carregando coaches",
    coach_premium: "COACH PREMIUM",
}

//buttons labels
export const BUTTONS_LABELS = {
    video: "Vídeo",
    presentation: "Apresentação",
    experience: "Experiência",
    methodology: "Metodologia",
    contact: "Contato"
}

//magic numbers 
export const NUMBERS = {
    MAX_ITENS_PER_PAGE: 10,
}
