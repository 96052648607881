import React from 'react';
import InputGroup from 'reactstrap/lib/InputGroup';
import Input from 'reactstrap/lib/Input';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';

// Images
import Brain from '../../../images/icons-images/svg/brain.svg';
import Cross from '../../../images/icons-images/svg/cross.svg';
import Currency from '../../../images/icons-images/svg/currency-wizard.svg';
import Handbag from '../../../images/icons-images/svg/handbag.svg';
import Heart from '../../../images/icons-images/svg/heart.svg';
import Home from '../../../images/icons-images/svg/home-wizard.svg';
import UserGroup from '../../../images/icons-images/svg/user-group-wizard.svg';
import { Button } from 'reactstrap';


export default class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: props.getStore().tasks
    };
  }


  saveInput(value, type) {
    const tasks = this.state.tasks;
    tasks[type].item = value;
    this.setState({ tasks: tasks });
    this.props.updateStore({ tasks: tasks });
  }

  validation(value) {
    if (value && value > 10) {
      return 10;
    } else if (!value) {
      return 0;
    } else {
      return Number(value);
    }
  }


  render() {
    return (
      <div id="step2" className="step-container">
        <h2 className="text-center">Descreva abaixo as 5 tarefas que mais consomem tempo no seu dia a dia</h2>
        <h4 className="text-center">Descreva cada tarefa em uma linha específica</h4>
        <form>
          <div className="form-group group-input text-center no-margin">
            <Input defaultValue={this.state.tasks[0].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" onChange={(value) => { this.saveInput(value.target.value, 0) }} />
            <Input defaultValue={this.state.tasks[1].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" onChange={(value) => { this.saveInput(value.target.value, 1) }} />
            <Input defaultValue={this.state.tasks[2].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" onChange={(value) => { this.saveInput(value.target.value, 2) }} />
            <Input defaultValue={this.state.tasks[3].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" onChange={(value) => { this.saveInput(value.target.value, 3) }} />
            <Input defaultValue={this.state.tasks[4].item} className="form-smallsize" required placeholder="Digite a tarefa aqui" onChange={(value) => { this.saveInput(value.target.value, 4) }} />
          </div>
        </form>
        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(0)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button onClick={() => this.props.jumpToStep(2)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}