import React from "react";
import PropTypes from "prop-types";
import "./header.css";
import "./spinner.css";
import LOGO from "../../images/logo.svg";
import ButtonSearch from "../../components/buttons/button_search";
import SelectOptions from "../../components/selectOptions/selectOptions";
import { optionsSortBy, TEXT_LABELS } from "../../containers/engage/constants";
import MoreFilters from "../moreFilters/moreFilters";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSliders } from "@fortawesome/free-solid-svg-icons";

export default class EngageHeader extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.sortObjectOccupationArea = this.sortObjectOccupationArea.bind(this);
    this.HandleChangeSelect = this.HandleChangeSelect.bind(this);
    this.RemoveAllSelect = this.RemoveAllSelect.bind(this);
    this.HandleClickDisplay = this.HandleClickDisplay.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.handleCloseMoreFilters = this.handleCloseMoreFilters.bind(this);

    this.state = {
      isOpen: false,
      isOpenFloatMenu: false,
      isOpenMoreFilters: false,
      executeActionSelect: false,
      executeClickMoreFilters: false,
      occupationArea: [],
      countries: [],
      sessions: [],
      institutes: [],
      states: [],
      cities: [],
      institutes: [],
      lastState: "",
      lastCity: "",
      optionsSelected: {
        certificate: false,
        premium: false,
        occupationArea: [],
        sessions: [],
        countries: [],
        states: [],
        cities: [],
        institutes: [],
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let paramOcuppationAreas;
    let paramSession;

    if (
      this.props.state.professionals &&
      this.props.state.professionals.length > 0 &&
      prevProps.state.professionals &&
      prevProps.state.professionals.length === 0
    ) {
      this.sortObjectOccupationArea(
        this.props.state.professionals,
        this.props.state.lifeCoach
      );
    }

    if (
      this.props.state.sessions &&
      Object.entries(this.props.state.sessions).length > 0 &&
      prevProps.state.sessions &&
      Object.entries(prevProps.state.sessions).length === 0
    ) {
      this.setState((state) => ({
        ...state,
        sessions: this.props.state.sessions,
      }));
    }

    if (
      this.props.state.countries &&
      this.props.state.countries.length > 0 &&
      prevProps.state.countries &&
      prevProps.state.countries.length === 0
    ) {
      this.setState((state) => ({
        ...state,
        countries: this.props.state.countries,
      }));
    }

    if (
      this.props.state.institutes &&
      this.props.state.institutes.length > 0 &&
      prevProps.state.institutes &&
      prevProps.state.institutes.length === 0
    ) {
      this.setState((state) => ({
        ...state,
        institutes: this.props.state.institutes,
      }));
    }

    const isCountriesChecked = this.state.countries.filter((el) => el.checked);
    if (
      isCountriesChecked[0] &&
      isCountriesChecked.length > 0 &&
      this.state.executeActionSelect
    ) {
      this.updateFilter("country", isCountriesChecked[0].value);
      this.setState({ executeActionSelect: false });
    } else if (
      isCountriesChecked &&
      isCountriesChecked.length === 0 &&
      this.state.executeActionSelect
    ) {
      this.updateFilter("country", "");
      this.setState({ executeActionSelect: false });
    }

    if (this.state.executeClickMoreFilters) {
      if (
        this.state.optionsSelected.occupationArea &&
        this.state.optionsSelected.occupationArea.length > 0
      ) {
        const professional1 = this.state.optionsSelected.occupationArea
          .filter((el) => {
            if (el.checked && el.type === "professional") {
              return el;
            } else {
              return null;
            }
          })
          .map((p) => p.value);

        const life1 = this.state.optionsSelected.occupationArea
          .filter((el) => {
            if (el.checked && el.type === "life") {
              return el;
            } else {
              return null;
            }
          })
          .map((p) => p.value);

        paramOcuppationAreas = { professional: professional1, life: life1 };

        //to-do está ficando um elemento como undefined.
        Object.keys(paramOcuppationAreas).forEach(
          (key) =>
            paramOcuppationAreas[key] === undefined &&
            delete paramOcuppationAreas[key]
        );
      }

      if (
        this.state.optionsSelected.countries &&
        this.state.optionsSelected.countries.length > 0
      ) {
        this.updateFilter(
          "country",
          this.state.optionsSelected.countries[0].value
        );
      } else {
        this.updateFilter("country", "");
      }

      if (
        this.state.optionsSelected.sessions &&
        this.state.optionsSelected.sessions.length > 0
      ) {
        const arraySession = this.state.optionsSelected.sessions.map((p) =>
          p.value.toLowerCase()
        );
        let sessions = {};
        arraySession.forEach((session) => {
          sessions = { ...sessions, [session]: true };
        });
        paramSession = { sessions };
        // const filterParam = { sessions };
        // paramSession = JSON.stringify(filterParam);
      }

      if (paramOcuppationAreas && paramSession) {
        const newObject = Object.assign({}, paramOcuppationAreas, paramSession);
        this.updateFilter("areas", newObject);
      } else if (paramOcuppationAreas) {
        this.updateFilter("areas", paramOcuppationAreas);
      } else if (paramSession) {
        this.updateFilter("areas", paramSession);
      } else {
        this.updateFilter("areas", "");
      }

      if (
        this.state.optionsSelected.institutes &&
        this.state.optionsSelected.institutes.length > 0
      ) {
        this.updateFilter(
          "institute",
          this.state.optionsSelected.institutes[0].value
        );
      } else {
        this.updateFilter("institute", "");
      }

      if (
        this.state.optionsSelected.countries &&
        this.state.optionsSelected.countries.length > 0
      ) {
        this.updateFilter(
          "country",
          this.state.optionsSelected.countries[0].value
        );
      } else {
        this.updateFilter("country", "");
      }

      if (
        this.state.optionsSelected.states &&
        this.state.optionsSelected.states.length > 0
      ) {
        this.updateFilter("state", this.state.optionsSelected.states[0].value);
      } else {
        this.updateFilter("state", "");
      }

      if (
        this.state.optionsSelected.cities &&
        this.state.optionsSelected.cities.length > 0
      ) {
        this.updateFilter("city", this.state.optionsSelected.cities[0].value);
      } else {
        this.updateFilter("city", "");
      }

      this.setState({ executeClickMoreFilters: false });
    }
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentMenuHeader(classComponentItems, classButtonLogin) {
    return (
      <>
        <div className={classComponentItems}>
          <a href={process.env.REACT_APP_URL_WORDPRESS}>Home</a>
        </div>
        <div className={classComponentItems}>
          <a href={`${process.env.REACT_APP_URL_WORDPRESS}sou-coach/`}>
            Sou Coach
          </a>
        </div>
        <div className={classComponentItems}>
          <a href={`${process.env.REACT_APP_URL_WORDPRESS}planos/`}>Planos</a>
        </div>
        <div className={classComponentItems}>
          <a href={`${process.env.REACT_APP_URL_WORDPRESS}blog/`}>Blog</a>
        </div>
        <div className={classButtonLogin}>
          {/* <div className="headerEngageButtonLogin"> */}
          <a href={process.env.REACT_APP_URL_PLATFORM}>
            <span>Login</span>
          </a>
        </div>
      </>
    );
  }

  handleCloseMoreFilters() {
    this.setState((state) => ({
      ...state,
      isOpenMoreFilters: false,
    }));
  }

  HandleClickDisplay(keyArray) {
    this.setState({ executeActionSelect: true });

    let paramOcuppationAreas;
    let paramSession;

    const existOccupationArea = this.state.occupationArea.filter(
      (el) => el.checked
    );

    if (keyArray === "occupationArea" || existOccupationArea.length > 0) {
      const professional1 = this.state.occupationArea
        .filter((el) => {
          if (el.checked && el.type === "professional") {
            return el;
          } else {
            return null;
          }
        })
        .map((p) => p.value);

      const life1 = this.state.occupationArea
        .filter((el) => {
          if (el.checked && el.type === "life") {
            return el;
          } else {
            return null;
          }
        })
        .map((p) => p.value);

      paramOcuppationAreas = { professional: professional1, life: life1 };

      //to-do está ficando um elemento como undefined.
      Object.keys(paramOcuppationAreas).forEach(
        (key) =>
          paramOcuppationAreas[key] === undefined &&
          delete paramOcuppationAreas[key]
      );
    }

    const existSessions = this.state.sessions.filter((el) => el.checked);

    if (keyArray === "sessions" || existSessions.length > 0) {
      const arraySession = this.state.sessions
        .filter((el) => {
          if (el.checked) {
            return el;
          } else {
            return null;
          }
        })
        .map((p) => p.value.toLowerCase());
      let sessions = {};
      arraySession.forEach((session) => {
        sessions = { ...sessions, [session]: true };
      });
      paramSession = { sessions };
    }

    if (
      paramOcuppationAreas &&
      paramSession &&
      (keyArray === "occupationArea" || keyArray === "sessions")
    ) {
      const newObject = Object.assign({}, paramOcuppationAreas, paramSession);
      this.updateFilter("areas", newObject);
    } else if (
      paramOcuppationAreas &&
      (keyArray === "occupationArea" || keyArray === "sessions")
    ) {
      this.updateFilter("areas", paramOcuppationAreas);
    } else if (
      paramSession &&
      (keyArray === "occupationArea" || keyArray === "sessions")
    ) {
      this.updateFilter("areas", paramSession);
    } else if (keyArray === "occupationArea" || keyArray === "sessions") {
      this.updateFilter("areas", "");
    }
  }

  updateFilter(key, value) {
    this.props.setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        [key]: value,
      },
    }));
  }

  RemoveAllSelect(arrayRemove, keyArray, removeAll) {
    if (arrayRemove && arrayRemove.length > 0 && keyArray && keyArray !== "") {
      const newObj = arrayRemove.map((el) => ({
        ...el,
        checked: false,
      }));

      this.setState((state) => ({
        ...state,
        [keyArray]: newObj,
        optionsSelected: {
          ...state.optionsSelected,
          [keyArray]: [],
        },
        executeClickMoreFilters: true,
      }));
    } else if (removeAll) {
      this.setState((state) => ({
        ...state,
        occupationArea: state.occupationArea.map((el) => ({
          ...el,
          checked: false,
        })),
        countries: state.countries.map((el) => ({
          ...el,
          checked: false,
        })),
        sessions: state.sessions.map((el) => ({
          ...el,
          checked: false,
        })),
        states: state.states.map((el) => ({
          ...el,
          checked: false,
        })),
        cities: state.cities.map((el) => ({
          ...el,
          checked: false,
        })),
        institutes: state.institutes.map((el) => ({
          ...el,
          checked: false,
        })),
        optionsSelected: {
          certificate: false,
          premium: false,
          occupationArea: [],
          sessions: [],
          countries: [],
          states: [],
          cities: [],
          institutes: [],
        },
        lastState: "",
        lastCity: "",
        executeClickMoreFilters: true,
      }));
    }
    window.scrollTo(0, 0);
  }

  HandleChangeSelect(item, arrayAlter, keyArray, singleSelection) {
    if (arrayAlter && arrayAlter.length > 0 && keyArray && keyArray !== "") {
      let newObj = [];

      if (singleSelection) {
        newObj = arrayAlter.map((el) => ({
          ...el,
          checked: el.label === item.label ? !item.checked : false,
        }));
      } else {
        newObj = arrayAlter;
        const indexOccupationArea = newObj.findIndex(
          (el) => el.label === item.label
        );

        newObj[indexOccupationArea].checked = !item.checked;
      }

      this.setState((state) => ({
        ...state,
        [keyArray]: [...newObj],
      }));
    }
  }

  sortObjectOccupationArea(arrayProfessionals, arrayLifeCoach) {
    let objectReturn = [];

    for (let aProfessionals of arrayProfessionals) {
      objectReturn.push({
        ...aProfessionals,
        type: "professional",
        checked: false,
      });
    }

    for (let aLifeCoach of arrayLifeCoach) {
      objectReturn.push({
        ...aLifeCoach,
        type: "life",
        checked: false,
      });
    }

    objectReturn.sort();

    this.setState((state) => ({
      ...state,
      occupationArea: objectReturn,
    }));
  }

  render() {
    const homeMenu =
      this.props.objetivos != undefined && this.props.objetivos != null
        ? "homeObjetivo"
        : this.props.searchMenu == undefined || this.props.searchMenu == null
          ? "homeMenu"
          : "";
    const menuDefault =
      this.props.searchMenu != undefined || this.props.searchMenu != null
        ? "menu-default-searche"
        : "";

    return (
      <div className="headerEngage">
        <div className="headerEngageLogoMenus">
          <div className="headerEngageLogoMenusFirst">
            <div className="headerEngageLogo">
              <img src={LOGO} alt="climby" />
            </div>

            <div className="headerEngageMenus">
              {this.componentMenuHeader(
                "headerEngageMenusItems",
                "headerEngageButtonLogin"
              )}
              <FontAwesomeIcon
                className="menu-header-engage-icon"
                icon={faBars}
                color="#a6ce39"
                size="2x"
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    isOpenFloatMenu: !state.isOpenFloatMenu,
                  }));
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          {this.state.isOpenFloatMenu && (
            <div className="headerFloatMenu">
              {this.componentMenuHeader(
                "headerEngageFloatMenusItems",
                "headerEngageButtonLoginFloatMenu"
              )}
            </div>
          )}
        </div>
        <div className="headerSearch">
          <ButtonSearch searchName={this.props.searchName} />

          <span className="division">|</span>

          <div
            className={
              this.props.state.loadingFilterData
                ? "select-option-filters disable"
                : "select-option-filters"
            }
          >
            <SelectOptions
              id="select-occupation-area"
              placeholder="Área de Atuação"
              dataSource={this.state.occupationArea}
              onChange={(item) => {
                this.HandleChangeSelect(
                  item,
                  this.state.occupationArea,
                  "occupationArea"
                );
              }}
              removeAll={() => {
                this.RemoveAllSelect(
                  this.state.occupationArea,
                  "occupationArea"
                );
              }}
              onClick={() => {
                this.HandleClickDisplay("occupationArea");
              }}
              loading={this.props.state.loadingFilterData}
            />
            <SelectOptions
              id="select-countries"
              placeholder="País"
              singleSelection
              executeAction
              dataSource={this.state.countries}
              onChange={(item) => {
                this.HandleChangeSelect(
                  item,
                  this.state.countries,
                  "countries",
                  true
                );
              }}
              removeAll={() => {
                this.RemoveAllSelect(this.state.countries, "countries");
              }}
              onClick={() => {
                this.HandleClickDisplay("countries");
              }}
              loading={this.props.state.loadingFilterData}
            />
            <SelectOptions
              id="select-sessions"
              placeholder="Sessões"
              dataSource={this.state.sessions}
              onChange={(item) => {
                this.HandleChangeSelect(item, this.state.sessions, "sessions");
              }}
              removeAll={() => {
                this.RemoveAllSelect(this.state.sessions, "sessions");
              }}
              onClick={() => {
                this.HandleClickDisplay("sessions");
              }}
              loading={this.props.state.loadingFilterData}
            />
            <button
              onClick={() => {
                this.setState((state) => ({
                  ...state,
                  isOpenMoreFilters: !state.isOpenMoreFilters,
                }));
              }}
            >
              {this.props.state.loadingFilterData ? "Carregando..." : "Mais Filtros"}
            </button>
            <FontAwesomeIcon
              className="menu-header-more-filters-icon"
              icon={faSliders}
              color="#a6ce39"
              size="2x"
              onClick={() => {
                this.setState((state) => ({
                  ...state,
                  isOpenMoreFilters: !state.isOpenMoreFilters,
                }));
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="wrapperSortBy">
          {TEXT_LABELS.order_by}
          <select
            onChange={(event) => {
              this.props.ChangeOrderBy(event.target.value);
            }}
          >
            {optionsSortBy.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        {this.state.isOpenMoreFilters && (
          <MoreFilters
            state={this.state}
            setState={this.setState.bind(this)}
            onChangeSelect={this.HandleChangeSelect}
            onRemoveAll={this.RemoveAllSelect}
            onClose={this.handleCloseMoreFilters}
          />
        )}
      </div>
    );
  }
}

EngageHeader.propTypes = {
  data: PropTypes.any,
  professional: PropTypes.any,
};
