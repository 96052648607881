import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';


// Layouts
import Menu from '../../templates/menu/menu';
import Button from 'reactstrap/lib/Button';

library.add(faCheck);

const items = [
  {
    title: 'Starter',
    active: false,
    prices: {
      oldValue: '22/mês',
      value: '19/mês'
    },
    new_clientes: [
      {
        label: 'check'
      },
      {
        label: 'Opcional - R$ 30,00',
        color: 'blue'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: '2 Modelos',
        color: 'blue'
      },
      {
        label: 'check'
      }
    ],
    manager_coaching: [
      {
        label: 'até 2 Coachees ativos',
        color: 'blue'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'times',
        color: 'red'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    settings_content: [
      {
        label: 'times',
        color: 'red'
      },
      {
        label: '250 MB',
        color: 'blue'
      }
    ],
    portal_coache: [
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    reports: [
      {
        label: 'check'
      },
      {
        label: 'times',
        color: 'red'
      },
      {
        label: 'times',
        color: 'red'
      }
    ],
    manager_financial: [
      {
        label: 'check'
      },
      {
        label: 'times',
        color: 'red'
      },
      {
        label: '5%',
        color: 'blue'
      }
    ],
    support: [
      {
        label: 'check'
      },
      {
        label: 'Resposta em até 72 horas',
        color: 'blue'
      },
    ]
  },
  {
    title: 'Expert',
    active: true,
    prices: {
      oldValue: '82/mês',
      value: '70/mês'
    },
    new_clientes: [
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    manager_coaching: [
      {
        label: 'até 5 Coachees ativos',
        color: 'active'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    settings_content: [
      {
        label: 'check'
      },
      {
        label: '500 MB',
        color: 'active'
      }
    ],
    portal_coache: [
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    reports: [
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'times',
        color: 'red'
      }
    ],
    manager_financial: [
      {
        label: 'check'
      },
      {
        label: 'times',
        color: 'red'
      },
      {
        label: '3%',
        color: 'active'
      }
    ],
    support: [
      {
        label: 'check'
      },
      {
        label: 'Resposta em até 72 horas',
        color: 'active'
      },
    ]
  },
  {
    title: 'Prime',
    active: false,
    prices: {
      oldValue: '205/mês',
      value: '180/mês'
    },
    new_clientes: [
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    manager_coaching: [
      {
        label: 'até 15 Coachees ativos',
        color: 'blue'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    settings_content: [
      {
        label: 'check'
      },
      {
        label: '1 GB',
        color: 'blue'
      }
    ],
    portal_coache: [
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    reports: [
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    manager_financial: [
      {
        label: 'check'
      },
      {
        label: 'times',
        color: 'red'
      },
      {
        label: '2%',
        color: 'blue'
      }
    ],
    support: [
      {
        label: 'check'
      },
      {
        label: 'Resposta em até 24 horas',
        color: 'blue'
      },
    ]
  },
  {
    title: 'Master',
    active: false,
    prices: {
      oldValue: '280/mês',
      value: '250/mês'
    },
    new_clientes: [
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    manager_coaching: [
      {
        label: 'ILIMITADO',
        color: 'blue'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    settings_content: [
      {
        label: 'check'
      },
      {
        label: '5 GB',
        color: 'blue'
      }
    ],
    portal_coache: [
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    reports: [
      {
        label: 'check'
      },
      {
        label: 'check'
      },
      {
        label: 'check'
      }
    ],
    manager_financial: [
      {
        label: 'check'
      },
      {
        label: 'times',
        color: 'red'
      },
      {
        label: '1%',
        color: 'blue'
      }
    ],
    support: [
      {
        label: 'check'
      },
      {
        label: 'Resposta em até 4 horas',
        color: 'blue'
      },
    ]
  }
];

class Moreplans extends Component {

  constructor(props) {
    super(props);

    // console.dir(props)

    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    const { fetchData } = this.props;
    fetchData();

  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }


  componentDidMount() {
    window.scrollTo(0, 0);
  }

  createdList(array, type) {
    let val = [];
    for (let i = 0; i < array.length; i++) {
      val.push(
        <li key={type + i} className={array[i].color ? array[i].color : 'green'}>
          {
            array[i].label === 'check' ? <FontAwesomeIcon icon={faCheck} /> : (array[i].label === 'times' ? <FontAwesomeIcon icon={faTimes} /> : array[i].label)
          }
        </li>
      )
    }
    return val;
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          <header className={item.title.toLowerCase()}>
            <div className="text-center title-plan text-uppercase">Planos</div>
            <h2>{item.title}</h2>
            <div className="oldValue">{item.prices.oldValue}</div>
          </header>
          <section className={item.title.toLowerCase()}>
            <div className="newValue">
              {item.prices.value}
              <span className="text-uppercase">Assinar agora</span>
            </div>
            <div className="table-right-custom">
              <ul className="section-content section-1">
                {this.createdList(item.new_clientes, index)}
              </ul>
              <ul className="section-content section-2">
                {this.createdList(item.manager_coaching, index)}
              </ul>
              <ul className="section-content section-3">
                {this.createdList(item.settings_content, index)}
              </ul>
              <ul className="section-content section-4">
                {this.createdList(item.portal_coache, index)}
              </ul>
              <ul className="section-content section-5">
                {this.createdList(item.reports, index)}
              </ul>
              <ul className="section-content section-7">
                {this.createdList(item.manager_financial, index)}
              </ul>
              <ul className="section-content section-8">
                {this.createdList(item.support, index)}
              </ul>
            </div>
            <div className="text-center">
              <Button color="default" className="text-uppercase">Assinar agora</Button>
            </div>
          </section>
        </CarouselItem>
      );
    });

    return (
      <div id="more-plans" className="searches-container" data-test-id="searches-container">
        <header id="header">
          <Container fluid={true}>
            <Menu />
          </Container>
        </header>

        <div id="body-main">
          <Container className="hidden-xs" fluid={true}>
            <table className="table table-plan no-border">
              <tr>
                <td width="18%"></td>
                <td width="18%" className="text-center header-plan">
                  <h4 className="text-uppercase">STARTER</h4>
                  <div className="box-plans">
                    <div className="price line-through">R$ 22/mês</div>
                    <div className="price">R$ <span>19,80/mês</span></div>
                  </div>
                  <Button className="text-uppercase" color="primary">Assinar agora</Button>
                </td>
                <td width="18%" className="text-center header-plan active">
                  <h4 className="text-uppercase">Expert</h4>
                  <div className="box-plans">
                    <div className="price line-through">R$ 82/mês</div>
                    <div className="price">R$ <span>70/mês</span></div>
                  </div>
                  <Button className="text-uppercase" color="default">Assinar agora</Button>
                </td>
                <td width="18%" className="text-center header-plan">
                  <h4 className="text-uppercase">Prime</h4>
                  <div className="box-plans">
                    <div className="price line-through">R$ 205/mês</div>
                    <div className="price">R$ <span>180/mês</span></div>
                  </div>
                  <Button className="text-uppercase" color="primary">Assinar agora</Button>
                </td>
                <td width="18%" className="text-center header-plan">
                  <h4 className="text-uppercase">Master</h4>
                  <div className="box-plans">
                    <div className="price line-through">R$ 280/mês</div>
                    <div className="price">R$ <span>250/mês</span></div>
                  </div>
                  <Button className="text-uppercase" color="primary">Assinar agora</Button>
                </td>
              </tr>
            </table>
            <table className="table table-plan">
              <thead>
                <tr>
                  <th>CONQUISTE NOVOS CLIENTES</th>
                  <th></th>
                  <th className="active"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="375" className="blue">Participação no Buscador e contato</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Destaque no buscador</td>
                  <td width="18%" className="blue text-center">Opcional - R$ 30,00</td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Criação de website personalizado</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue"> Gerenciamento de oportunidades</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Emissão de propostas</td>
                  <td width="18%" className="blue text-center">2 modelos</td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Registre as sessões Gratuitas (Sessões Master)</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
              </tbody>
            </table>
            <table className="table table-plan">
              <thead>
                <tr>
                  <th>GERENCIE SUAS SESSÕES DE COACHING</th>
                  <th></th>
                  <th className="active"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="375" className="blue">Cadastre e gerencie seus Coachees</td>
                  <td width="18%" className="text-center blue">até 2 Coachees ativos</td>
                  <td width="18%" className="text-center blue active">até 5 Coachees ativos</td>
                  <td width="18%" className="text-center blue">até 15 Coachees ativos</td>
                  <td width="18%" className="text-center blue">ILIMITADO</td>
                </tr>
                <tr>
                  <td width="375" className="blue">Planejamento de programa</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Compartilhamento de agenda</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Integração de agenda</td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Registro de sessões</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Emissão de relatórios</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Interação direta com o Coachee</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
              </tbody>
            </table>
            <table className="table table-plan">
              <thead>
                <tr>
                  <th>FERRAMENTAS & CONTEÚDOS</th>
                  <th></th>
                  <th className="active"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="375" className="blue">Customização de ferramentas e formulários</td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Compartilhamento de conteúdos</td>
                  <td width="18%" className="text-center blue">250 MB</td>
                  <td width="18%" className="text-center blue active">500 MB</td>
                  <td width="18%" className="text-center blue">1 GB</td>
                  <td width="18%" className="text-center blue">5 GB</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-plan">
              <thead>
                <tr>
                  <th>PORTAL DO COACHEE</th>
                  <th></th>
                  <th className="active"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="375" className="blue">Gerenciamento de mensagens</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Acompanhamento de status</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
              </tbody>
            </table>
            <table className="table table-plan">
              <thead>
                <tr>
                  <th>RELATÓRIOS</th>
                  <th></th>
                  <th className="active"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="375" className="blue">Relatório do processo de Coaching</td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Relatório de desempenho</td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Relatórios gerenciais</td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center red active"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
              </tbody>
            </table>
            <table className="table table-plan">
              <thead>
                <tr>
                  <th>GESTÃO FINANCEIRA</th>
                  <th></th>
                  <th className="active"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="375" className="blue">Controle de fluxo de caixa</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Pagamento por boleto ou cartão de crédito</td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center red active"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                  <td width="18%" className="text-center red"><FontAwesomeIcon icon={faTimes} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">% sobre transação de pagamentos (após processamento)</td>
                  <td width="18%" className="text-center blue">5%</td>
                  <td width="18%" className="text-center blue active">3%</td>
                  <td width="18%" className="text-center blue">2%</td>
                  <td width="18%" className="text-center blue">1%</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-plan">
              <thead>
                <tr>
                  <th>SUPORTE SEMPRE DISPONÍVEL</th>
                  <th></th>
                  <th className="active"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="375" className="blue">Controle de fluxo de caixa</td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green active"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                  <td width="18%" className="text-center green"><FontAwesomeIcon icon={faCheck} /></td>
                </tr>
                <tr>
                  <td width="375" className="blue">Nível de serviço para atendimento</td>
                  <td width="18%" className="text-center blue">Resposta em até 72 horas</td>
                  <td width="18%" className="text-center blue active last">Resposta em até 72 horas</td>
                  <td width="18%" className="text-center blue">Resposta em até 24 horas</td>
                  <td width="18%" className="text-center blue">Resposta em até 4 horas</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-plan no-border">
              <tr>
                <td width="18%"></td>
                <td width="18%" className="text-center">
                  <Button className="text-uppercase" color="primary">Assinar agora</Button>
                </td>
                <td width="18%" className="text-center active">
                  <Button className="text-uppercase" color="default">Assinar agora</Button>
                </td>
                <td width="18%" className="text-center">
                  <Button className="text-uppercase" color="primary">Assinar agora</Button>
                </td>
                <td width="18%" className="text-center">
                  <Button className="text-uppercase" color="primary">Assinar agora</Button>
                </td>
              </tr>
            </table>
          </Container>
          <div className="show-xs">
            <div className="absolute-tables">
              <table className="table table-plan">
                <thead>
                  <tr>
                    <th><span>CONQUISTE NOVOS CLIENTES</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Participação no Buscador e contato</td>
                  </tr>
                  <tr>
                    <td>Destaque no buscador</td>
                  </tr>
                  <tr>
                    <td>Criação de website personalizado</td>
                  </tr>
                  <tr>
                    <td>Gerenciamento de oportunidades</td>
                  </tr>
                  <tr>
                    <td>Emissão de propostas</td>
                  </tr>
                  <tr>
                    <td>Registre as sessões Gratuitas (Sessões Master)</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-plan">
                <thead>
                  <tr>
                    <th><span>GERENCIE SUAS SESSÕES DE COACHING</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cadastre e gerencie seus Coachees</td>
                  </tr>
                  <tr>
                    <td>Planejamento de programa</td>
                  </tr>
                  <tr>
                    <td>Compartilhamento de agenda</td>
                  </tr>
                  <tr>
                    <td>Integração de agenda</td>
                  </tr>
                  <tr>
                    <td>Registro de sessões</td>
                  </tr>
                  <tr>
                    <td>Emissão de relatórios</td>
                  </tr>
                  <tr>
                    <td>Interação direta com o Coachee</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-plan">
                <thead>
                  <tr>
                    <th><span>FERRAMENTAS & CONTEÚDOS</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Customização de ferramentas e formulários</td>
                  </tr>
                  <tr>
                    <td>Compartilhamento de conteúdos</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-plan">
                <thead>
                  <tr>
                    <th><span>PORTAL DO COACHEE</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Gerenciamento de mensagens</td>
                  </tr>
                  <tr>
                    <td>Acompanhamento de status</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-plan">
                <thead>
                  <tr>
                    <th><span>RELATÓRIOS</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Relatório do processo de Coaching</td>
                  </tr>
                  <tr>
                    <td>Relatório de desempenho</td>
                  </tr>
                  <tr>
                    <td>Relatórios gerenciais</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-plan">
                <thead>
                  <tr>
                    <th><span>GESTÃO FINANCEIRA</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Controle de fluxo de caixa</td>
                  </tr>
                  <tr>
                    <td>Pagamento por boleto ou cartão de crédito</td>
                  </tr>
                  <tr>
                    <td>% sobre transação de pagamentos <span>(após processamento)</span></td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-plan">
                <thead>
                  <tr>
                    <th><span>SUPORTE SEMPRE DISPONÍVEL</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Suporte especialista</td>
                  </tr>
                  <tr>
                    <td>Nível de serviço para atendimento</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              interval={false}
            >
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

Moreplans.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func,
};

export default Moreplans;
