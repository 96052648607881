import ApiService from './apiService';

class CoacheService {
    constructor() {
        this.service = new ApiService();
    }

    async getCoaches(body) {
        const result = await this.service.execute(
            "POST",
            `${process.env.REACT_APP_URL_API}/search`,
            body
        );
        return await result.json();
    }

    async postTrack(body) {
        const result = await this.service.execute(
            "POST",
            `${process.env.REACT_APP_URL_BASE}track`,
            body
        );
        return await result.json();
    }

    async trackFilter(type, id) {
        let _payload = { type, id };
        const result = await this.service.execute(
            "POST",
            `${process.env.REACT_APP_URL_BASE}engine/track-filter`,
            _payload
        );
        return await result.json();
    }

}

export default CoacheService;