import React from 'react';
import Input from 'reactstrap/lib/Input';
import { Button } from 'reactstrap';
import FormGroup from 'reactstrap/lib/FormGroup';


export default class Step3 extends React.Component {
  array = [];
  problem;

  constructor(props) {
    super(props);

    this.state = {
      problem: props.getStore().problem,
      value: ''
    };

    this.problem = this.props.getStore().problem;

    for (var [key, val] of Object.entries(this.problem)) {

      this.array = [...this.array, { text: key, value: val }]
    }
  }

  saveInput(value) {
    this.setState({ problem: value, value: value });
    this.props.updateStore({
      problem: value
    });
  }

  validation(value) {
    if (value && value > 10) {
      return 10;
    } else if (!value) {
      return 0;
    } else {
      return Number(value);
    }
  }


  render() {
    return (
      <div id="step3" className="step-container">
        <h2 className="text-center">A partir da descrição feita na pergunta anterior, descreva abaixo qual realmente é o problema? Se você puder resumir o seu problema em uma única pergunta ou afirmação, qual seria?</h2>
        <h4 className="text-center">Nesta área a recomendação é ser o mais objetivo possível. Por exemplo, como sua frase com: O meu problema é... Evite utilizar as conjunções “mas” ou “e” pois pode significar que você possue mais do que uma situação a resolver.</h4>
        <form>
          <FormGroup className="textarea-content">
            <Input
              placeholder="Digite sua resposta"
              maxLength={'1024'}
              rows="8"
              type="textarea"
              onChange={(value) => this.saveInput(value.target.value)}
              defaultValue={this.state.problem}
            />
            <p className="text-right">{this.state.problem.length}/1024</p>
          </FormGroup>
        </form>

        <div className="footer-buttons text-center">
          <Button onClick={() => this.props.jumpToStep(1)} className="text-uppercase btn-next" color="primary">Voltar</Button>
          <Button disabled={!this.state.problem} onClick={() => this.props.jumpToStep(3)} className="text-uppercase btn-prev" color="primary">Avançar</Button>
        </div>
      </div>
    );
  }
}