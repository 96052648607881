import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import * as qs from "query-string";
import ReactPaginate from "react-paginate";

//components
import EngageHeader from "../../components/engageHeader/engage_header";
import CardEngage from "../../components/cardEngage/cardEngage";
import { objectIsEmpty } from "../../libs/utils";

//constants
import { TEXT_LABELS, NUMBERS } from "./constants";

//services
import CoacheService from "../../services/coacheService";

//css
import "./engage.css";

class EngageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      sessions: [],
      lifeCoach: [],
      professionals: [],
      institutes: [],
      filter: {},
      visible: true,
      coaches: [],
      total_item: 0,
      item_page: NUMBERS.MAX_ITENS_PER_PAGE,
      activePage: 1,
      page_count: 0,
      typeClass: 0,
      coachesId: [],
      loadingCoaches: true,
      coach: {},
      pages: [
        { value: 1, label: "1 por página" },
        { value: 5, label: "5 por página" },
        { value: 10, label: "10 por página" },
      ],
      selectedOptionIndex: 2,
      loadingFilterData: true,
    };

    const { fetchData } = this.props;
    fetchData();

    this.service = new CoacheService();
    this.searchName = this.searchName.bind(this);
  }

  renderCardEngage() {
    const coaches = this.state.coaches;
    const listCoaches = [];
    for (let coach of coaches) {
      listCoaches.push(<CardEngage key={coach._id} coach={coach} />);
    }
    return listCoaches;
  }

  getCoaches = async (body, page = 1) => {
    this.setState({ coaches: [], loadingCoaches: true });
    if (body === "" || body === undefined) {
      body = this.state.filter;
    }

    body["page"] = page;
    body["page_size"] = this.state.item_page;
    body["typeClass"] = this.state.typeClass;
    body["coachesId"] = JSON.stringify(this.state.coachesId);

    if (body && body.areas && typeof body.areas !== "string") {
      body.areas = JSON.stringify(body.areas);
    }

    //this.getTotalCoachesWithoutFilter();
    setTimeout(async () => {
      await this.service.getCoaches(body).then((res) => {
        if (res.success) {
          this.setState({
            coaches: res.coachs,
            number_page: res.pages,
            total_item: res.resultGeral.length,
            coachesReal: res.resultGeral,
            loadingCoaches: false,
            coach: res.coachs[0],
            page_count: res.pages,
          });
          window.scrollTo(0, 0);
        }
      });
    }, 1);
  };

  async searchName(value) {
    let coachesData = this.state.coachesReal;
    if (value && value.trim() !== "") {
      coachesData = coachesData.filter((item) => {
        const name = item.engage_name;
        return name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });

      this.setState({ coachesId: [] });
      await coachesData.map((data) => {
        // eslint-disable-next-line no-unused-expressions
        !this.state.coachesId.includes(data._id)
          ? this.setState({ coachesId: [data._id, ...this.state.coachesId] })
          : "";
      });
      this.setState({
        coaches: coachesData,
        total_item: coachesData.length,
      });
    } else {
      this.setState({ coachesId: [] }, this.getCoaches);
      this.updateFilter(NUMBERS.MAX_ITENS_PER_PAGE, "page_size");
    }
  }

  async updateFilter(value, type) {
    let filter = this.state.filter;
    filter[type] = value;

    if (value !== undefined && value !== "") {
      let pageSize = parseInt(value);
      if (type === "page_size") {
        this.state.pages.map((data, indice) => {
          if (data.value === pageSize) {
            this.setState({ selectedOptionIndex: indice });
          }
        });
      }
      this.setState({
        filter: filter,
        item_page: Number.isInteger(pageSize) ? pageSize : this.state.item_page,
        activePage: 1,
      });

      await this.getCoaches(this.state.filter);
    } else {
      await this.getCoaches("");
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.filter !== prevState.filter) {
      this.updateFilterCheckBox();
    }

    if (
      prevProps.data.countries &&
      prevProps.data.countries.length > 0 &&
      this.props.data.countries.length > 0 &&
      this.state.loadingFilterData
    ) {
      await this.loadFiltersData();
      this.createFilters()
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCoaches();
  }

  async createFilters() {
    const filter = qs.parse(this.props.location.search);
    if (!objectIsEmpty(filter) && (filter.area || filter.areas)) {
      if (filter.area) {
        let infos = this.getInfos(filter.area);
        if (infos) {
          this.updateFilterCheckBox(true, infos.id, infos.type);
        }
      } else {
        if (filter.areas) {
          let _areas = filter.areas.split(",");
          for (let _a of _areas) {
            let infos = this.getInfos(_a);
            if (infos) {
              await this.updateFilterCheckBox(true, infos.id, infos.type);
            }
          }
        }
      }
    } else if (!objectIsEmpty(filter)) {
      this.updateFilterCheckBox(true, filter.id, filter.type);
      this.updateFilter(filter.state, "state");
    }
    this.getCoaches();
  }

  async updateFilterCheckBox(checked, value, type) {
    let filter = this.state.filter;
    let area = filter.areas ? { areas: filter.areas } : { areas: {} };

    area.areas =
      typeof area.areas == "string" ? JSON.parse(area.areas) : area.areas;

    if (typeof area.areas === "string") {
      area.areas = JSON.parse(area.areas);
    }
    if (type != "sessions") {
      if (!area.areas[type]) {
        area.areas[type] = [];
      }
    } else {
      if (!area.areas[type]) {
        area.areas[type] = {};
      }
    }

    if (checked) {
      if (type == "sessions") {
        area.areas[type][value == 1 ? "online" : "presential"] = true;
      } else {
        area.areas[type].push(Number(value));
        this.service.trackFilter(type, value);
      }
    } else {
      if (type == "sessions") {
        delete area.areas[type][value == 1 ? "online" : "presential"];
        if (!area.areas[type].online && !area.areas[type].presential) {
          delete area.areas[type];
        }
      } else if (type) {
        area.areas[type] = area.areas[type].filter((res) => res != value);
      }
    }

    filter["areas"] = area.areas;

    this.setState({ filter: filter });

    this.getCoaches(filter);
  }

  getInfos(area_label) {
    if (this.state.lifeCoach) {
      for (let _i of this.state.lifeCoach) {
        if (`${_i.label}`.toLowerCase() == `${area_label}`.toLowerCase()) {
          return { id: _i.value, type: "life" };
        }
      }
    }
    if (this.state.professionals) {
      for (let _i of this.state.professionals) {
        if (`${_i.label}`.toLowerCase() == `${area_label}`.toLowerCase()) {
          return { id: _i.value, type: "professional" };
        }
      }
    }
    return false;
  }

  ChangeOrderBy = async (value) => {
    this.setState({ typeClass: value });
    await this.updateFilter(NUMBERS.MAX_ITENS_PER_PAGE, "page_size");
    this.getCoaches("", 1);
  };

  handlePageChange = (event) => {
    const newPage = event.selected + 1;
    this.getCoaches("", newPage);
    this.setState({ activePage: newPage });
  };

  async loadFiltersData() {
    return new Promise((resolve, reject) => {
      const { data } = this.props;
      const countries = [];
      const lifeCoach = [];
      const professionals = [];
      const sessions = [];
      const institutes = [];

      if (data.areas.life && data.areas.life.length > 0) {
        data.areas.life.map((res) => {
          lifeCoach.push({
            views: res.views ? res.views : 0,
            label: res.option,
            value: res.id,
          });
        });
        this.setState({ lifeCoach: lifeCoach });
      }

      if (data.areas.professional && data.areas.professional.length > 0) {
        data.areas.professional.map((res) => {
          professionals.push({
            views: res.views ? res.views : 0,
            label: res.option,
            value: res.id,
          });
        });
        this.setState({ professionals: professionals });
      }

      if (data.areas.sessions) {
        Object.entries(data.areas.sessions).forEach(([key, value]) => {
          const keyNew = this.sessionReturn(key);
          sessions.push({
            label: keyNew,
            value: key,
          });
        });
        this.setState({ sessions: sessions });
      }

      if (data.institutes && data.institutes.length > 0) {
        data.institutes.map((res) => {
          if (res.partner) {
            institutes.push({
              label: res.name,
              value: res.name,
              partner: res.partner,
            });
          }
        });
        this.setState({ institutes: institutes });
      }

      if (data.countries && data.countries.length > 0) {
        data.countries.forEach((res) => {
          countries.push({
            label: res.name,
            value: res.name,
            checked: false,
          });
        });

        this.setState({ countries: countries });
      }

      this.setState({ loadingFilterData: false })
      resolve(true)
    })

  }

  sessionReturn(key) {
    var keyNew = key;
    switch (key) {
      case "presential": {
        return (keyNew = "Presencial");
      }
      case "online": {
        return (keyNew = "Online");
      }

      default:
        return (keyNew = key);
    }
  }

  render() {
    return (
      <div className="wrapperEngage">
        <EngageHeader
          searchName={this.searchName}
          state={this.state}
          setState={this.setState.bind(this)}
          ChangeOrderBy={this.ChangeOrderBy}
          professionals={this.state.professionals}
          data={{
            occupationAreaProfessionals: this.state.professionals,
            occupationAreaLifeCoach: this.state.lifeCoach,
          }}
        />
        <div className="body">
          <div>
            {this.state.loadingCoaches ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <h3 className="text-display-center  large-text">
                  {TEXT_LABELS.loading_coaches}
                </h3>
                <ReactLoading type="bubbles" color="#B1CD49" />
              </div>
            ) : (
              this.renderCardEngage()
            )}
          </div>

          {this.state.total_item > 1 ? (
            <div className="text-display-center">
              <ReactPaginate
                className="react-paginate"
                activeClassName="react-paginate-active"
                previousClassName="react-paginate-prev-next"
                nextClassName="react-paginate-prev-next"
                pageClassName="react-paginate-number-pages"
                breakClassName="react-paginate-number-pages"
                nextLinkClassName="react-paginate-link"
                pageLinkClassName="react-paginate-link"
                breakLinkClassName="react-paginate-link"
                activeLinkClassName="react-paginate-link"
                disabledLinkClassName="react-paginate-link"
                previousLinkClassName="react-paginate-link"
                breakLabel="..."
                nextLabel=">"
                onPageChange={this.handlePageChange}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                pageCount={this.state.page_count}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

EngageView.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func,
};

export default EngageView;
