import React, { Component } from "react";
import PropTypes from "prop-types";
import { Doughnut, Radar } from "react-chartjs-2";
import { Link } from "react-router-dom";

import { Container, Button, Row, Col } from "reactstrap";
import StepZilla from "react-stepzilla";
import axios from "axios";
import html2canvas from "html2canvas";

// Layouts
import Menu from "../../templates/menu/menu";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";
import Step7 from "./steps/Step7";

// Image
import GraphicImage from "../../images/graphic.jpg";
import RadarImage from "../../images/roda-da-vida.jpg";
import Brain from "../../images/icons-images/svg/brain.svg";
import Cross from "../../images/icons-images/svg/cross.svg";
import Currency from "../../images/icons-images/svg/currency-wizard.svg";
import Handbag from "../../images/icons-images/svg/handbag.svg";
import Heart from "../../images/icons-images/svg/heart.svg";
import Home from "../../images/icons-images/svg/home-wizard.svg";
import UserGroup from "../../images/icons-images/svg/user-group-wizard.svg";

import ScreenCapture from "./ScreenCapture";

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finish: false,
      graphic: false,
      wheel_of_life: [],
      high_point: [],
      screenCapture: "",
      _payload: [],
      statuscreen: false,
      cropPositionTop: 0,
      cropPositionLeft: 0,
      cropWidth: 0,
      cropHeigth: 0,
    };

    this.formWheelOfLife = {
      name: "",
      email: "",
      msgm_wheel_of_life: "",
      msgm_hight_point: "",
      process_coaching: false,
      newsletter: false,
      wheel_of_life: {
        professional: 0,
        emocional: 0,
        cheers: 0,
        relationships: 0,
        family: 0,
        happiness: 0,
        financial: 0,
      },
      high_point: {
        professional: false,
        emocional: false,
        cheers: false,
        relationships: false,
        family: false,
        happiness: false,
        financial: false,
      },
    };
    this.selector = React.createRef();
  }

  posicao = () => {
    //let coordenadas = el.getBoundingClientRect();
    if (this.selector.current) {
      let coordenadas = this.selector.current.getBoundingClientRect();
      // console.log(
      //   "posição x",
      //   coordenadas.left,
      //   "posição y",
      //   coordenadas.top,
      //   "width",
      //   coordenadas.width,
      //   "heigth",
      //   this.selector.current.clientHeight
      // );

      let posicaoLeft = coordenadas.left;
      let posicaoTop = coordenadas.top;
      let width = coordenadas.width;
      let height = this.selector.current.clientHeight;
      if (coordenadas.left === 51.125 && coordenadas.top === 334.9375) {
        posicaoLeft = 120;
        posicaoTop = 980;
        width = 850;
        height = 800;
      } else if (coordenadas.left === 44.75 && coordenadas.top === 245.4375) {
        posicaoLeft = 370;
        posicaoTop = 1150;
        width = 1550;
        height = 1300;
      } else if (coordenadas.left === 51.125 && coordenadas.top === 334.9375) {
        posicaoLeft = 140;
        posicaoTop = 1150;
        width = 1150;
        height = 800;
      } else if (coordenadas.left === 51.5 && coordenadas.top === 334.9375) {
        posicaoLeft = 150;
        posicaoTop = 1000;
        width = 950;
        height = 700;
      } else if (coordenadas.left === 46.625 && coordenadas.top === 335.4375) {
        posicaoLeft = 150;
        posicaoTop = 1000;
        width = 950;
        height = 700;
      }

      this.setState({
        cropPositionTop: posicaoTop,
        cropPositionLeft: posicaoLeft,
        cropWidth: width,
        cropHeigth: height,
      });
    }
  };

  componentDidMount() {
    const { fetchData } = this.props;
    window.scrollTo(0, 0);
    // fetchData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getStore() {
    return this.formWheelOfLife;
  }

  updateStore(update) {
    this.formWheelOfLife = {
      ...this.formWheelOfLife,
      ...update,
    };
  }

  selectImage(value) {
    switch (value) {
      case "professional":
        return Handbag;
      case "emocional":
        return Brain;
      case "cheers":
        return Cross;
      case "relationships":
        return UserGroup;
      case "family":
        return Home;
      case "happiness":
        return Heart;
      case "financial":
        return Currency;
    }
  }

  async finish(e) {
    if (e === 7) {
      this.setState({ finish: true });
      let sortable = [];
      for (let val in this.getStore().wheel_of_life) {
        sortable.push([val, this.getStore().wheel_of_life[val]]);
      }
      sortable = sortable.sort((a, b) => {
        return b[1] - a[1];
      });

      await this.setState({ wheel_of_life: sortable });

      let items = [];
      for (let val in this.getStore().high_point) {
        if (this.getStore().high_point[val]) {
          items.push(val);
        }
      }

      await this.setState({ high_point: items });

      this.saveResponse();
    } else if (e === 6) {
      if (this.formWheelOfLife.newsletter) {
        await this.handleEmail();
      }
    }
  }

  translate(number) {
    switch (number) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      case 5:
        return "five";
      case 6:
        return "six";
      case 7:
        return "serven";
      case 8:
        return "eight";
      case 9:
        return "nine";
      case 10:
        return "ten";
      default:
        return "";
    }
  }

  async saveResponse() {
    let _payload = {
      name: this.getStore().name,
      email: this.getStore().email,
      wheel_of_life: this.getStore().wheel_of_life,
      msgm_wheel_of_life: this.getStore().msgm_wheel_of_life,
      msgm_hight_point: this.getStore().msgm_hight_point,
      text: this.text2Save(),
      high_point: this.getStore().high_point,
      complete_link: `?area=${this.translateText(
        this.state.high_point[0],
        true
      )},${this.translateText(
        this.state.high_point[1],
        true
      )},${this.translateText(this.state.high_point[2], true)}`,
    };

    await this.setState({ graphic: true, _payload });
    this.posicao();

    this.interval = setInterval(() => {
      this.setState({ statuscreen: true });
    }, 1000);
  }

  translateText(text, link = false) {
    switch (text) {
      case "professional":
        return link ? "profissional" : "realização profissional";
      case "emocional":
        return link ? "saúde" : "equilíbrio emocional";
      case "cheers":
        return "saúde";
      case "relationships":
        return link ? "vida" : "relacionamento";
      case "family":
        return link ? "vida" : "família";
      case "happiness":
        return link ? "vida" : "felicidade";
      case "financial":
        return link ? "vida" : "financeiro";
      default:
        return "";
    }
  }

  text2Save() {
    return `<p>Esta auto avaliação, tem como objetivo representar a situação atual de ${
      this.getStore().name
    } segundo seu próprio ponto de vista nas principais áreas da vida e pode servir como ponto de partida para sessões de Coaching e com isso indicar possíveis áreas de melhoria. Vale ressaltar que em nenhum momento esta avaliação foi avaliada por um profissional</p>
        <p>Seus pontos mais fortes foram: ${this.state.wheel_of_life[0] &&
          this.translateText(
            this.state.wheel_of_life[0][0]
          )}, ${this.translateText(
      this.state.wheel_of_life[1][0]
    )}, ${this.translateText(
      this.state.wheel_of_life[2][0]
    )} e seus pontos que podem causar mais impactos em sua vida, no momento atual são: ${this.translateText(
      this.state.high_point[0]
    )}, ${this.translateText(this.state.high_point[1])}, ${this.translateText(
      this.state.high_point[2]
    )}.</p>
        <p>Comentários porque estas áreas ${this.translateText(
          this.state.high_point[0]
        )}, ${this.translateText(
      this.state.high_point[1]
    )}, ${this.translateText(this.state.high_point[2])} são prioritárias:</p>
        <p>${this.getStore().msgm_wheel_of_life}</p>
        <p>Suas áreas de Fortaleza ${this.translateText(
          this.state.wheel_of_life[0][0]
        )}, ${this.translateText(
      this.state.wheel_of_life[1][0]
    )}, ${this.translateText(
      this.state.wheel_of_life[2][0]
    )} receberam os seguintes comentários:</p>
        <p>${this.getStore().msgm_hight_point}</p>
        <p>Com base neste resultado e nesta visão geral o próximo passo é você gerar uma ação que vai te levar em direção ao seus objetivo ou área de melhoria.</p>
        <p>O Coach irá te apoiar na sua jornada e definição dos próximos passos utilizando técnicas cientificamente comprovadas. Na <strong className="text-underline">climby</strong> você encontra os melhores Coaches e que estarão a sua disposição para contribuir seja qual for o momento da sua vida e seu objetivo a alcançar. Aproveite para visitar o nosso <span className="text-underline">Blog</span> e busque o seu desenvolvimento contínuo. Desejamos muito sucesso na sua jornada!</p>`;
  }

  service(method, url, data) {
    fetch(url, {
      method: method,
      headers: {
        // 'Accept': 'application/json',
        "Content-Type": "application/json; charset=UTF-8",
      },
      body:
        method === "POST" ? (data != null ? JSON.stringify(data) : []) : null,
    });
  }

  parsePayload(_payload) {
    Object.keys(_payload)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          _payload[key]
        )}`;
      })
      .join("&");
  }

  handleScreenCapture = (screenCapture) => {
    this.posicao();
    this.setState({
      screenCapture,
    });

    let imgData = "";
    const input = document.getElementById("Assessment");
    html2canvas(input).then(async (canvas) => {
      imgData = canvas.toDataURL("image/png");

      let formData = {
        image: imgData,
      };
      await axios
        .post(
          `${process.env.REACT_APP_URL_BASE}upload/screenshots`,
          formData,
          {}
        )
        .then((res) => {
          console.log(res);
        });

      await this.service(
        "POST",
        `${process.env.REACT_APP_URL_BASE}assessments`,
        this.state._payload
      );
    });
    console.log(`APP: ${JSON.stringify(imgData)}`);
  };

  async handleEmail() {
    const _payload = {
      name: this.getStore().name,
      email: this.getStore().email,
    };
    await this.service(
      "POST",
      `${process.env.REACT_APP_URL_BASE}assessments/register`,
      _payload
    );
  }

  render() {
    const { screenCapture } = this.state;

    const steps = [
      {
        name: "",
        component: (
          <Step1
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step2
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step3
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step4
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step5
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step6
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "",
        component: (
          <Step7
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
    ];

    return (
      <>
        <div
          id="home"
          className="home-container boxShadowSearche"
          data-test-id="home-container"
        >
          <header id="header" className="headerSearche">
            <Menu objetivos={true} />
          </header>
        </div>

        <div
          id="Assessment"
          className="assessment-container"
          style={{ marginTop: -95 }}
        >
          <div id="body-main">
            <h2 className="title">Roda da vida</h2>
            <Container>
              {!this.state.finish ? (
                <div className="step-progress">
                  <StepZilla
                    steps={steps}
                    showNavigation={false}
                    preventEnterSubmission={false}
                    onStepChange={(step) => this.finish(step)}
                  />
                </div>
              ) : !this.state.graphic ? (
                <div id="preResponse" className="text-center">
                  <p>
                    Obrigado por participar! O resultado da Roda da Vida foi
                    enviado para seu e-mail. Aproveite a oportunidade e encontre
                    um Coach que o apoiará com ferramentas e metodologias
                    durante a sua jornada de desenvolvimento.
                  </p>
                  <img src={GraphicImage} />
                  <p>
                    Muito bem! <br /> Avance na sua jornada em busca do seu
                    objetivo!”
                  </p>
                  <Button
                    color="default"
                    onClick={() => this.setState({ graphic: true })}
                  >
                    VER O RESULTADO DE MINHA AVALIAÇÃO
                  </Button>
                </div>
              ) : (
                <div id="response">
                  <Row>
                    <Col lg={{ size: 5 }} md={{ size: 12 }} xs={{ size: 12 }}>
                      <h3>Roda da Vida</h3>
                      <div className="radar-chart" ref={this.selector}>
                        <img className="radar" src={RadarImage} alt="radar" />
                        <div
                          className={
                            "point top " +
                            this.translate(this.getStore().wheel_of_life.cheers)
                          }
                        >
                          {this.getStore().wheel_of_life.cheers}
                        </div>
                        <div
                          className={
                            "point top-left " +
                            this.translate(
                              this.getStore().wheel_of_life.relationships
                            )
                          }
                        >
                          {this.getStore().wheel_of_life.relationships}
                        </div>
                        <div
                          className={
                            "point top-right " +
                            this.translate(this.getStore().wheel_of_life.family)
                          }
                        >
                          {this.getStore().wheel_of_life.family}
                        </div>
                        <div
                          className={
                            "point bottom-left " +
                            this.translate(
                              this.getStore().wheel_of_life.financial
                            )
                          }
                        >
                          {this.getStore().wheel_of_life.financial}
                        </div>
                        <div
                          className={
                            "point bottom-right " +
                            this.translate(
                              this.getStore().wheel_of_life.emocional
                            )
                          }
                        >
                          {this.getStore().wheel_of_life.emocional}
                        </div>
                        <div
                          className={
                            "point left " +
                            this.translate(
                              this.getStore().wheel_of_life.happiness
                            )
                          }
                        >
                          {this.getStore().wheel_of_life.happiness}
                        </div>
                        <div
                          className={
                            "point right " +
                            this.translate(
                              this.getStore().wheel_of_life.professional
                            )
                          }
                        >
                          {this.getStore().wheel_of_life.professional}
                        </div>
                      </div>
                      <h3>Pontos de Impacto</h3>
                      <div className="hidden-xs">
                        <div className="buttons-groups">
                          <span>
                            <img src={Handbag} />{" "}
                            {this.translateText(this.state.high_point[0])}
                          </span>
                          <a
                            href={`${
                              process.env.REACT_APP_URL_ENGAGE
                            }?area=${this.translateText(
                              this.state.high_point[0],
                              true
                            )}`}
                            className="btn btn-default text-uppercase"
                          >
                            encontre um coach
                          </a>
                        </div>
                        <div className="buttons-groups">
                          <span>
                            <img src={Brain} />{" "}
                            {this.translateText(this.state.high_point[1])}
                          </span>
                          <a
                            href={`${
                              process.env.REACT_APP_URL_ENGAGE
                            }?area=${this.translateText(
                              this.state.high_point[1],
                              true
                            )}`}
                            className="btn btn-default text-uppercase"
                          >
                            encontre um coach
                          </a>
                        </div>
                        <div className="buttons-groups">
                          <span>
                            <img className="currency" src={Currency} />{" "}
                            {this.translateText(this.state.high_point[2])}
                          </span>
                          <a
                            href={`${
                              process.env.REACT_APP_URL_ENGAGE
                            }?area=${this.translateText(
                              this.state.high_point[2],
                              true
                            )}`}
                            className="btn btn-default text-uppercase"
                          >
                            encontre um coach
                          </a>
                        </div>
                      </div>
                    </Col>
                    <Col lg={{ size: 7 }} md={{ size: 6 }} xs={{ size: 12 }}>
                      <h2 className="text-uppercase">Resultado</h2>
                      <p>
                        Esta auto avaliação, tem como objetivo representar a
                        situação atual de {this.getStore().name} segundo seu
                        próprio ponto de vista nas principais áreas da vida e
                        pode servir como ponto de partida para sessões de
                        Coaching e com isso indicar possíveis áreas de melhoria.
                        Vale ressaltar que em nenhum momento esta avaliação foi
                        avaliada por um profissional
                      </p>
                      <p>
                        Seus pontos mais fortes foram:{" "}
                        {this.translateText(this.state.wheel_of_life[0][0])},{" "}
                        {this.translateText(this.state.wheel_of_life[1][0])},{" "}
                        {this.translateText(this.state.wheel_of_life[2][0])} e
                        seus pontos que podem causar mais impactos em sua vida,
                        no momento atual são:{" "}
                        {this.translateText(this.state.high_point[0])},{" "}
                        {this.translateText(this.state.high_point[1])},{" "}
                        {this.translateText(this.state.high_point[2])}.
                      </p>
                      <p>
                        Comentários porque estas áreas{" "}
                        {this.translateText(this.state.high_point[0])},{" "}
                        {this.translateText(this.state.high_point[1])},{" "}
                        {this.translateText(this.state.high_point[2])} são
                        prioritárias:
                      </p>
                      <p>{this.getStore().msgm_wheel_of_life}</p>
                      <p>
                        Suas áreas de Fortaleza{" "}
                        {this.translateText(this.state.wheel_of_life[0][0])},{" "}
                        {this.translateText(this.state.wheel_of_life[1][0])},{" "}
                        {this.translateText(this.state.wheel_of_life[2][0])}{" "}
                        receberam os seguintes comentários:
                      </p>
                      <p>{this.getStore().msgm_hight_point}</p>
                      <p>
                        Com base neste resultado e nesta visão geral o próximo
                        passo é você gerar uma ação que vai te levar em direção
                        ao seus objetivo ou área de melhoria.
                      </p>
                      <p>
                        O Coach irá te apoiar na sua jornada e definição dos
                        próximos passos utilizando técnicas cientificamente
                        comprovadas. Na{" "}
                        <strong className="text-underline">climby</strong> você
                        encontra os melhores Coaches e que estarão a sua
                        disposição para contribuir seja qual for o momento da
                        sua vida e seu objetivo a alcançar. Aproveite para
                        visitar o nosso{" "}
                        <span className="text-underline">Blog</span> e busque o
                        seu desenvolvimento contínuo. Desejamos muito sucesso na
                        sua jornada!
                      </p>
                    </Col>
                    <Col xs={{ size: 12 }} className="show-xs">
                      <div className="buttons-groups">
                        <span>
                          <img
                            src={this.selectImage(this.state.high_point[0])}
                          />{" "}
                          {this.translateText(this.state.high_point[0])}
                        </span>
                        <a
                          href={process.env.REACT_APP_URL_ENGAGE}
                          className="btn btn-default text-uppercase"
                        >
                          encontre um coach
                        </a>
                      </div>
                      <div className="buttons-groups">
                        <span>
                          <img
                            src={this.selectImage(this.state.high_point[1])}
                          />{" "}
                          {this.translateText(this.state.high_point[1])}
                        </span>
                        <a
                          href={process.env.REACT_APP_URL_ENGAGE}
                          className="btn btn-default text-uppercase"
                        >
                          encontre um coach
                        </a>
                      </div>
                      <div className="buttons-groups">
                        <span>
                          <img
                            className="currency"
                            src={this.selectImage(this.state.high_point[2])}
                          />{" "}
                          {this.translateText(this.state.high_point[2])}
                        </span>
                        <a
                          href={process.env.REACT_APP_URL_ENGAGE}
                          className="btn btn-default text-uppercase"
                        >
                          encontre um coach
                        </a>
                      </div>
                    </Col>
                  </Row>
                  {/* <>
                    <br />
                    <br />
                    <img
                      src={screenCapture}
                      width={this.state.cropWidth}
                      height={this.state.cropHeigth}
                    />
                  </> */}
                  {this.state.statuscreen && (
                    <ScreenCapture
                      onEndCapture={this.handleScreenCapture}
                      cropPositionTop={this.state.cropPositionTop}
                      cropPositionLeft={this.state.cropPositionLeft}
                      cropWidth={this.state.cropWidth}
                      cropHeigth={this.state.cropHeigth}
                    >
                      <div />
                    </ScreenCapture>
                  )}
                </div>
              )}
            </Container>
          </div>
        </div>
      </>
    );
  }
}

Assessment.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func,
};

export default Assessment;
