import React, { Component } from "react"
//import { Input, Button, InputGroup } from "reactstrap";
import PropTypes from "prop-types";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import SearchIcon from "../../images/icons-images/svg/search-icon.svg"
import "./button_search.css";

//import "./buttons_styles.css"

library.add(
    faSliders
);

export default class ButtonSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ""
        }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.props.searchName(this.state.name)
        }
    }

    render() {

        return (
            <div className="engage-button-input-search">
                <input type="search" placeholder="Buscar ..." value={this.state.name} onChange={(value) =>
                    this.setState({ name: value.target.value })
                } onKeyDown={this._handleKeyDown} required={false} />
                <button onClick={() => { this.props.searchName(this.state.name) }}>
                    <img src={SearchIcon} height={25} />
                </button>
            </div>

            // <div className="engage-container-button-search">
            //     <InputGroup>
            //         <Input
            //             className="engage-input-search"
            //             type="type"
            //             value={this.state.name}
            //             onChange={(value) =>
            //                 this.setState({ name: value.target.value })
            //             }
            //             name="search"
            //             id={`search1`}
            //             placeholder="Buscar..."
            //             required={false}
            //             onKeyDown={this._handleKeyDown}
            //         />
            //         <Button className="engage-button-search" onClick={() => { this.props.searchName(this.state.name) }}>
            //             <img src={SearchIcon} height={25} />
            //         </Button>

            //         <Button className="engage-filter-search" >
            //             <FontAwesomeIcon icon={faSliders} color="#a6ce39" size="2x" />
            //         </Button>
            //     </InputGroup>
            // </div>
        )
    }
}

ButtonSearch.propTypes = {
    searchName: PropTypes.func,
};
